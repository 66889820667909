import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-eight',
  templateUrl: './part-eight.component.html',
  styleUrls: ['./part-eight.component.css']
})
export class PartEightComponent implements OnInit {
  public test: Test;
  public vocabulary: Test;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
    private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.test = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'I’ve just arrived, I’m an exchange student from Europe, I .... anything wrong!',
          image: '',
          options: [
            {key: 'haven’t done', translation: 'haven’t done', audio: '', img: ''},
            {key: 'didn’t do', translation: 'didn’t do', audio: '', img: ''},
            {key: 'not did do', translation: 'not did do', audio: '', img: ''},
          ],
          answers: ['haven’t done'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'If only I .... your age and opportunities, I XXX to America.',
          image: '',
          options: [
            {key: 'have', translation: 'have', audio: '', img: ''},
            {key: 'had', translation: 'had', audio: '', img: ''},
            {key: 'had had', translation: 'had had', audio: '', img: ''},
          ],
          answers: ['had'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'If only I had your age and opportunities, I .... to America.',
          image: '',
          options: [
            {key: 'be going', translation: 'be going', audio: '', img: ''},
            {key: 'would go', translation: 'would go', audio: '', img: ''},
            {key: 'went', translation: 'went', audio: '', img: ''},
          ],
          answers: ['would go'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'And what a fuss there .... from everyone in my class if they XXX !',
          image: '',
          options: [
            {key: 'would be', translation: 'would be', audio: '', img: ''},
            {key: 'is being', translation: 'is being', audio: '', img: ''},
            {key: 'is', translation: 'is', audio: '', img: ''},
          ],
          answers: ['would be'],
          correctAnswer : '',
          audio: '',
        }, {
          text: 'And what a fuss there would be from everyone in my class if they .... !',
          image: '',
          options: [
            {key: 'know', translation: 'know', audio: '', img: ''},
            {key: 'knew', translation: 'knew', audio: '', img: ''},
            {key: 'known', translation: 'known', audio: '', img: ''},
          ],
          answers: ['knew'],
          correctAnswer : '',
          audio: '',
        }, {
          text: 'If my teacher .... that I was going to America, he XXX so excited!',
          image: '',
          options: [
            {key: 'had known', translation: 'had known', audio: '', img: ''},
            {key: 'knew', translation: 'knew', audio: '', img: ''},
            {key: 'was knowing', translation: 'was knowing', audio: '', img: ''},
          ],
          answers: ['had known'],
          correctAnswer : '',
          audio: '',
        }, {
          text: 'If my teacher had known that I was going to America, he .... so excited!',
          image: '',
          options: [
            {key: 'will be', translation: 'will be', audio: '', img: ''},
            {key: 'would be', translation: 'would be', audio: '', img: ''},
            {key: 'would have been', translation: 'would have been', audio: '', img: ''},
          ],
          answers: ['would have been'],
          correctAnswer : '',
          audio: '',
        }, {
          text: 'If I .... him, no-one XXX.',
          image: '',
          options: [
            {key: 'not visited', translation: 'not visited', audio: '', img: ''},
            {key: 'didn’t visit', translation: 'didn’t visit', audio: '', img: ''},
            {key: 'wouldn’t visit', translation: 'wouldn’t visit', audio: '', img: ''},
          ],
          answers: ['didn’t visit'],
          correctAnswer : '',
          audio: '',
        }, {
          text: 'If I didn’t visit him, no-one .... .',
          image: '',
          options: [
            {key: 'did', translation: 'did', audio: '', img: ''},
            {key: 'would', translation: 'would', audio: '', img: ''},
            {key: 'will', translation: 'will', audio: '', img: ''},
          ],
          answers: ['would'],
          correctAnswer : '',
          audio: '',
        },
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_SS/zadanie_8.mp3');
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

}
