<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="TEXT" [disabled]="false">
      <div class="player-wrapper">
        <ngx-circular-player radius="80" source="/assets/audio/november-89/N89_4.mp3"></ngx-circular-player>
      </div>
      <div class="with-background with-background--paper with-background--shelves">
        <div class="text-center">
          <p>
            Na konferencii v Helsinkách v roku 1975 podpísali predstavitelia Československa dokument o bezpečnosti a spolupráci v Európe. V ňom sa zaviazali dodržiavať základné ľudské práva. V atmosfére neslobody nebolo v Československu možné šíriť a čítať niektoré knihy, zhromažďovať sa na verejnosti, organizovať protestné akcie. Mnohí ľudia boli kvôli svojim textom, piesňam či názorom sledovaní Štátnou bezpečnosťou a zatýkaní. Na protest proti pokračujúcemu nedodržiavaniu ľudských práv vzniklo hnutie Charta 77.
            <br>
            Text Charty 77 napísali Václav Havel, Ludvík Vaculík a Pavel Kohout.
          </p>
          <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
