import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  @Input() offset = 0;
  @Input() step: number;
  @Input() length: number;
  constructor() { }

  ngOnInit(): void {
  }

}
