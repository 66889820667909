import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { TranslateService } from '@ngx-translate/core';
import { AudioService } from '../../../services/audio.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;
  public allowToStartAgain = null;
  public finishScrId: string = null;

  constructor(
    public storyService: StoryService,
    public audioService: AudioService,
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: 'titulky.mp4', id: '612394848'};
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.onPlayAttention(0);
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.allowToStartAgain = true;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  public onLastVideoPlayed($event){
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.allowToStartAgain = true;
  }

  public startAgain(){
    this.storyService.setDefaultState();
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [0, 1];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.onPlayAttention($event.index); // toto bude citat zadanie
    }
  }

  onPlayAttention(tabKey: number): void{
    const attentionList = {
      0 : '/assets/audio/hrdinovia/cvicenia_zaver.mp3',
      1 : '/assets/audio/hrdinovia/cvicenia_zaver2.mp3',
    };
    this.audioService.playAudio(attentionList[tabKey]);
  }

}
