import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss']
})
export class ExtraComponent implements OnInit {
  public downloadList;
  public vocabulary;
  constructor(
    public audioService: AudioService,
    public storyService: StoryService,
  ){
    this.downloadList = [
      {src: '/assets/downloads/AD ZS Graffiti - CZ.pdf', lang: 'cz', translation: 'Bonus exercise_Graffiti text file', img: '/assets/img/american-dream/graffiti.jpg'},
      {src: '/assets/downloads/AD ZS Graffiti - SK.pdf', lang: 'sk', translation: 'Bonus exercise_Graffiti text file', img: '/assets/img/american-dream/graffiti.jpg'},
      {src: '/assets/downloads/AD ZS Graffiti - PL.pdf', lang: 'pl', translation: 'Bonus exercise_Graffiti text file', img: '/assets/img/american-dream/graffiti.jpg'},
      {src: '/assets/downloads/AD ZS - CZ.pdf', lang: 'cz', translation: 'Download English exercises in PDF (identical to online exercises)', img: '/assets/img/american-dream/print.jpg'},
      {src: '/assets/downloads/AD ZS - SK.pdf', lang: 'sk', translation: 'Download English exercises in PDF (identical to online exercises)', img: '/assets/img/american-dream/print.jpg'},
      {src: '/assets/downloads/AD ZS - PL.pdf', lang: 'pl', translation: 'Download English exercises in PDF (identical to online exercises)', img: '/assets/img/american-dream/print.jpg'},
      {src: '/assets/downloads/AD Glossary - CZ.pdf', lang: 'cz', translation: 'Download vocabulary', img: '/assets/img/american-dream/glossary.jpg'},
      {src: '/assets/downloads/AD Glossary - SK.pdf', lang: 'sk', translation: 'Download vocabulary', img: '/assets/img/american-dream/glossary.jpg'},
      {src: '/assets/downloads/AD Glossary - PL.pdf', lang: 'pl', translation: 'Download vocabulary', img: '/assets/img/american-dream/glossary.jpg'},
    ];
    this.vocabulary = this.storyService.vocabulary['american-dream-zs'];
  }

  ngOnInit(): void {
  }

  backToContent(){
    this.storyService.goBackToTest();
  }
}
