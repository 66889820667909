import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Test } from '../../models/test.model';
import { AudioService } from '../../services/audio.service';
import { StoryService } from '../../services/story.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit{
    @Input() test: Test;
    @Input() title: string = null;
    @Input() showOptionAs: string;
    @Input() showAnswerAs: string;
    @Input() customClass: string;
    @Input() makeItCount = false;
    @Input() allowRepeat = false;
    @Input() readBefore = false;
    @Input() readRightOptionBefore = false;
    @Input() playSoundAfterDone = true;
    @Input() isDone;
    @Output() public onDone: EventEmitter<any> = new EventEmitter();

    public selectedOption;
    public selectedAnswer;
    public itemToRepeat = null;
    public passedWords = [];
    public passedOrder = [];
    public anagramResult = [];
    public optionList = [];
    public disableToAddNewPoints = false;
    public disableToClickAgain = false;
    public rightAnswer = null;
    public rightImage = null;
    public step;
    public positivePoints = 3;
    public negativePoints = -1;

    constructor(
        public audioService: AudioService,
        public storyService: StoryService,
        private translateService: TranslateService,
    ){}

    ngOnInit(): void{
        this.step = 0;
        this.isDone = false;

        if (this.test.type === 'pairs'){
            this.optionList = this.shufflingArray(this.splitArrays(this.test.pairs.options, this.test.pairs.answers));
        }
        if (this.test.type === 'rightOrder'){
            this.optionList = this.shufflingArray(this.test.rightOrder.options);
        }
        if (this.test.type === 'questions'){
            this.optionList = this.shufflingArray(this.test.questions[this.step].options);
            if (this.readBefore) {
              this.audioService.playAudio(this.test.questions[this.step].audio);
            } else if (this.readRightOptionBefore) {
              const correctOption = this.getRightOptionByKey(this.optionList, this.test.questions[0].answers[0]);
              this.audioService.playAudio(correctOption.audio);
            }
        }
    }

    // ANAGAMS

    public selectAnagram(option){
        if (this.selectedOption === option.key){
            this.selectedOption = null;
        }else{
            this.selectedOption = option.key;
        }
        if (!this.passedOrder.includes(this.selectedOption)){
            if (this.test.anagrams[this.step].order[this.passedOrder.length] === this.selectedOption){
                this.passedOrder.push(this.selectedOption);
                this.anagramResult.push(option.translation);
                this.addPoints(this.positivePoints);
                if (this.passedOrder.length === this.test.anagrams[this.step].order.length){
                    this.audioService.playAudio(this.test.anagrams[this.step].audio);
                    setTimeout(() => {
                        this.step++;
                        this.passedOrder = [];
                        this.anagramResult = [];
                        if (this.test.anagrams.length === this.step){
                            this.setAsDone();
                        }
                    }, 2000);
                }else{
                    const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.correct;
                    this.audioService.playRandomAudio(audio);
                }
            }else{
                const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.wrong;
                this.audioService.playRandomAudio(audio);
                this.addPoints(this.negativePoints);
            }
        }else{
            const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.wrong;
            this.audioService.playRandomAudio(audio);
        }
    }

    // RIGHT-ORDER

    public selectOrder(option){
        if (this.selectedOption.key === option.key){
            this.selectedOption = null;
        }else{
            this.selectedOption = option;
        }
        if (!this.passedOrder.includes(this.selectedOption.key)){
            if (this.test.rightOrder.order[this.passedOrder.length] === this.selectedOption){ // TOTO sa lahko prerobit na array moznosti iba cez [this.step] ako v anagrame
                this.passedOrder.push(this.selectedOption);
                const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.correct;
                this.audioService.playRandomAudio(audio);
                this.addPoints(this.positivePoints);
            }
        }else{
            const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.wrong;
            this.audioService.playRandomAudio(audio);
            this.addPoints(this.negativePoints);
        }
    }

    isOrdered(key){
        return this.passedOrder.includes(key);
    }

    // QUESTIONS

    public onAnswer(option){
        const key = option.key;
        if (this.test.questions[this.step].answers.includes(key)){
            this.onRightAnswer(option);
        }else{
            this.onWrongAnswer();
        }
    }

    public onRightAnswer(option){
        let timeToWait = 2000;
        if (this.readBefore || this.readRightOptionBefore) {
          const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.correct;
          this.audioService.playRandomAudio(audio);
        }else{
            if (option.audio && option.audio !== '') {
                this.audioService.playAudio(option.audio);
            } else {
                const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.correct;
                this.audioService.playRandomAudio(audio);
            }
            timeToWait = 4000;
        }

        this.addPoints(this.positivePoints);
        this.disableToClickAgain = true;
        if (this.test.questions[this.step].correctAnswer !== ''){
            this.rightAnswer = this.test.questions[this.step].correctAnswer;
        }
        if (this.step < (this.test.questions.length - 1)){
            setTimeout(() => {
                this.step = this.step + 1;
                this.optionList = this.shufflingArray(this.test.questions[this.step].options);
                this.rightAnswer = null;
                this.disableToClickAgain = false;
                if (this.readBefore) {
                  this.audioService.playAudio(this.test.questions[this.step].audio);
                } else if (this.readRightOptionBefore) {
                  const correctOption = this.getRightOptionByKey(this.optionList, this.test.questions[this.step].answers[0]);
                  this.audioService.playAudio(correctOption.audio);
                }
            }, timeToWait);
        }else{
            this.setAsDone();
        }
    }

    public onWrongAnswer(){
        this.addPoints(this.negativePoints);
        const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.wrong;
        this.audioService.playRandomAudio(audio);
    }

    public setAsDone(){
        setTimeout(() => {
            if (this.playSoundAfterDone) {
              const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.correct;
              this.audioService.playRandomAudio(audio);
            }
            this.onDone.emit(this.isDone = true);
            this.disableToClickAgain = false;
        }, 3000);
    }

    public selectToRepeat(item){
        this.audioService.playAudio(item.audio);
        this.disableToClickAgain = true;
        this.itemToRepeat = item;
        this.test.questions.forEach(question => {
            if (question.answers.includes(this.itemToRepeat.key)){
                this.rightAnswer = question.text;
                this.rightImage = question.image;
                setTimeout(() => {
                    this.rightAnswer = null;
                    this.rightImage = null;
                    this.disableToClickAgain = false;
                }, 4000);
            }
        });
    }

    // PAIRS

    checkIfPairsIsDone(){
        if (this.passedWords.length === this.test.pairs.options.length){
            this.setAsDone();
            return true;
        }
        return false;
    }

    isPassed(key){
        return this.passedWords.includes(key);
    }

    selectWord(option: any = null, type: string){
        if (type === 'option'){
            if (this.selectedOption && this.selectedOption.key === option.key){
                this.selectedOption = null;
            }else{
                this.selectedOption = option;
            }
        }else if (type === 'answer'){
            if (this.selectedAnswer && this.selectedAnswer.key === option.key){
                this.selectedAnswer = null;
            }else{
                this.selectedAnswer = option;
            }
        }
        this.checkMatch();
    }

    checkMatch(){
        if ((this.selectedAnswer && this.selectedOption)){
            if (!this.passedWords.includes(this.selectedAnswer.key)){
                if (this.selectedAnswer.key === this.selectedOption.key){
                    this.passedWords.push(this.selectedAnswer.key);
                    if (this.selectedOption.audio !== '') {
                      this.audioService.playAudio(this.selectedOption.audio);
                    }else{
                      const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.correct;
                      this.audioService.playRandomAudio(audio);
                    }
                    this.addPoints(this.positivePoints);
                }else{
                    const audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.wrong;
                    this.audioService.playRandomAudio(audio);
                    this.addPoints(this.negativePoints);
                }
                this.selectedAnswer = null;
                this.selectedOption = null;
            }
        }
        this.checkIfPairsIsDone();
    }

    // helpers

    shufflingArray(value: any[]){

        const items: any[] = [];

        for (let i = 0; i < value.length; i++){
            items.push(value[i]);
        }

        // reversing items array
        for (let i = items.length - 1; i > 0; i--){
            const j = Math.floor(Math.random() * (i + 1));
            [items[i], items[j]] = [items[j], items[i]];
        }
        return items;
    }

    splitArrays(options, answers){
        options.forEach((value) => {
            value['type'] = 'option';
        });
        answers.forEach((value) => {
            value['type'] = 'answer';
        });
        return options.concat(answers);
    }

    addPoints(points){
        if (this.makeItCount){
            this.disableToAddNewPoints = true;
            this.storyService.addPoints(points);
            setTimeout(() => {
                this.disableToAddNewPoints = false;
            }, 4000);
        }
    }

    getRightOptionByKey(optionList: any[], key: string){
      let result = {
        audio: '',
      };
      optionList.forEach((value) => {
         if (value['key'] === key) {
           result = value;
         };
      });
      return result;
    }

}
