import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-six',
  templateUrl: './part-six.component.html',
  styleUrls: ['./part-six.component.css']
})
export class PartSixComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public test: Test;
  public vocabulary: Test;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.info = [
      {translation: 'AMERICAN_DREAM.TRANSLATED.INFO_6_1', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka2.mp4', id: '462662166'};
    this.test = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'expressionism', translation: 'Jackson Pollock<br> (expressionism)', audio: '', img: '/assets/img/american-dream/6_Jackson-Pollock.jpg'},
          {key: 'cubism', translation: 'Pablo Picasso<br> (cubism)', audio: '', img: '/assets/img/american-dream/6_picasso.jpg'},
          {key: 'surrealism', translation: 'Salvador Dali<br> (surrealism)', audio: '', img: '/assets/img/american-dream/6_salvador-dali.jpg'},
          {key: 'pop', translation: 'Andy Warhol<br> (pop art)', audio: '', img: '/assets/img/american-dream/6_andy_warhol_soup.jpg'},
          {key: 'graffiti', translation: 'Banksy<br> (graffiti art)', audio: '', img: '/assets/img/american-dream/6_banksy.jpg'},
        ],
        answers: [
          {key: 'expressionism', translation: 'Jackson Pollock<br> (expressionism)', audio: '', img: '/assets/img/american-dream/6_Jackson-Pollock.jpg'},
          {key: 'cubism', translation: 'Pablo Picasso<br> (cubism)', audio: '', img: '/assets/img/american-dream/6_picasso.jpg'},
          {key: 'surrealism', translation: 'Salvador Dali<br> (surrealism)', audio: '', img: '/assets/img/american-dream/6_salvador-dali.jpg'},
          {key: 'pop', translation: 'Andy Warhol<br> (pop art)', audio: '', img: '/assets/img/american-dream/6_andy_warhol_soup.jpg'},
          {key: 'graffiti', translation: 'Banksy<br> (graffiti art)', audio: '', img: '/assets/img/american-dream/6_banksy.jpg'},
        ]
      }
    };
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_SS/zadanie_6.mp3');
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

}
