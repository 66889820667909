import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-part-five',
  templateUrl: './part-five.component.html',
  styleUrls: ['./part-five.component.css']
})
export class PartFiveComponent implements OnInit {
  public test: Test;
  public vocabulary: Test;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
      private storyService: StoryService,
      private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_5_1', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka1.mp4', id: '462662022'};
    this.video2 = {src: '/assets/video/Egg On Trip_V5_5cast.mp4', id: '462794309'};
    this.video3 = {src: '/assets/video/5_cvicenie_11_V1.mp4', id: '462658535'};
    this.info2 = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_5_2', audio: ''},
    ];
    this.test = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Careful! It’s hot in the ....',
          image: '',
          options: [
            {key: 'thieves', translation: 'EGG_ON_TRIP.THIEVES', audio: '/assets/audio/I am calling the police.mp3', img: ''},
            {key: 'cookery-book', translation: 'EGG_ON_TRIP.COOKERY-BOOK', audio: '/assets/audio/I dont know how to make a cake.mp3', img: ''},
            {key: 'fire-place', translation: 'EGG_ON_TRIP.FIRE-PLACE', audio: '/assets/audio/careful its hot in the fireplace.mp3', img: ''},
            {key: 'wolf', translation: 'EGG_ON_TRIP.WOLF', audio: '/assets/audio/I am hungry as a wolf.mp3', img: ''},
            {key: 'plaster', translation: 'EGG_ON_TRIP.PLASTER', audio: '/assets/audio/Oh no I hurt myself.mp3', img: ''}
          ],
          answers: ['fire-place'],
          correctAnswer: 'Careful! It’s hot in the fire-place.',
          audio: '',
        }, {
          text: 'I’m hungry as a ....',
          image: '',
          options: [
            {key: 'thieves', translation: 'EGG_ON_TRIP.THIEVES', audio: '/assets/audio/I am calling the police.mp3', img: ''},
            {key: 'cookery-book', translation: 'EGG_ON_TRIP.COOKERY-BOOK', audio: '/assets/audio/I dont know how to make a cake.mp3', img: ''},
            {key: 'fire-place', translation: 'EGG_ON_TRIP.FIRE-PLACE', audio: '/assets/audio/careful its hot in the fireplace.mp3', img: ''},
            {key: 'wolf', translation: 'EGG_ON_TRIP.WOLF', audio: '/assets/audio/I am hungry as a wolf.mp3', img: ''},
            {key: 'plaster', translation: 'EGG_ON_TRIP.PLASTER', audio: '/assets/audio/Oh no I hurt myself.mp3', img: ''}
          ],
          answers: ['wolf'],
          correctAnswer: 'I’m hungry as a wolf.',
          audio: '',
        }, {
          text: 'Oh no! I’ve hurt myself. I need a ....',
          image: '',
          options: [
            {key: 'thieves', translation: 'EGG_ON_TRIP.THIEVES', audio: '/assets/audio/I am calling the police.mp3', img: ''},
            {key: 'cookery-book', translation: 'EGG_ON_TRIP.COOKERY-BOOK', audio: '/assets/audio/I dont know how to make a cake.mp3', img: ''},
            {key: 'fire-place', translation: 'EGG_ON_TRIP.FIRE-PLACE', audio: '/assets/audio/careful its hot in the fireplace.mp3', img: ''},
            {key: 'wolf', translation: 'EGG_ON_TRIP.WOLF', audio: '/assets/audio/I am hungry as a wolf.mp3', img: ''},
            {key: 'plaster', translation: 'EGG_ON_TRIP.PLASTER', audio: '/assets/audio/Oh no I hurt myself.mp3', img: ''}
          ],
          answers: ['plaster'],
          correctAnswer: 'Oh no! I’ve hurt myself. I need a plaster.',
          audio: '',
        }, {
          text: 'I don\'t know how to make a cake. I’ll look in my ....',
          image: '',
          options: [
            {key: 'thieves', translation: 'EGG_ON_TRIP.THIEVES', audio: '/assets/audio/I am calling the police.mp3', img: ''},
            {key: 'cookery-book', translation: 'EGG_ON_TRIP.COOKERY-BOOK', audio: '/assets/audio/I dont know how to make a cake.mp3', img: ''},
            {key: 'fire-place', translation: 'EGG_ON_TRIP.FIRE-PLACE', audio: '/assets/audio/careful its hot in the fireplace.mp3', img: ''},
            {key: 'wolf', translation: 'EGG_ON_TRIP.WOLF', audio: '/assets/audio/I am hungry as a wolf.mp3', img: ''},
            {key: 'plaster', translation: 'EGG_ON_TRIP.PLASTER', audio: '/assets/audio/Oh no I hurt myself.mp3', img: ''}
          ],
          answers: ['cookery-book'],
          correctAnswer : 'I don\'t know how to make a cake. I’ll look in my cookery-book.',
          audio: '',
        }, {
          text: 'I’m calling the police! There are .... in my house!',
          image: '',
          options: [
            {key: 'thieves', translation: 'EGG_ON_TRIP.THIEVES', audio: '/assets/audio/I am calling the police.mp3', img: ''},
            {key: 'cookery-book', translation: 'EGG_ON_TRIP.COOKERY-BOOK', audio: '/assets/audio/I dont know how to make a cake.mp3', img: ''},
            {key: 'fire-place', translation: 'EGG_ON_TRIP.FIRE-PLACE', audio: '/assets/audio/careful its hot in the fireplace.mp3', img: ''},
            {key: 'wolf', translation: 'EGG_ON_TRIP.WOLF', audio: '/assets/audio/I am hungry as a wolf.mp3', img: ''},
            {key: 'plaster', translation: 'EGG_ON_TRIP.PLASTER', audio: '/assets/audio/Oh no I hurt myself.mp3', img: ''}
          ],
          answers: ['thieves'],
          correctAnswer : 'I’m calling the police! There are thieves in my house!',
          audio: '',
        },
      ]
    };
    this.vocabulary = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'To knock on the door',
          image: '',
          options: [
            {key: 'to knock on the door', translation: 'EGG_ON_TRIP.TRANSLATED.TO_KNOCK_ON_THE_DOOR', audio: '/assets/audio/to knock on the door.mp3', img: ''},
            {key: 'a bucket of grain', translation: 'EGG_ON_TRIP.TRANSLATED.A_BUCKET_OF_GRAIN', audio: '/assets/audio/a bucket of grain.mp3', img: ''},
            {key: 'to stir the milk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STIR_THE_MILK', audio: '/assets/audio/to stir the milk.mp3', img: ''},
            {key: 'dangerous thieves', translation: 'EGG_ON_TRIP.TRANSLATED.DANGEROUS_THIEVES', audio: '/assets/audio/dangerous thieves.mp3', img: ''},
            {key: 'the chef looks in the cookery book', translation: 'EGG_ON_TRIP.TRANSLATED.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', audio: '/assets/audio/the chef looks in the cookery book.mp3', img: ''},
          ],
          answers: ['to knock on the door'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'a bucket of grain',
          image: '',
          options: [
            {key: 'to knock on the door', translation: 'EGG_ON_TRIP.TRANSLATED.TO_KNOCK_ON_THE_DOOR', audio: '/assets/audio/to knock on the door.mp3', img: ''},
            {key: 'a bucket of grain', translation: 'EGG_ON_TRIP.TRANSLATED.A_BUCKET_OF_GRAIN', audio: '/assets/audio/a bucket of grain.mp3', img: ''},
            {key: 'to stir the milk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STIR_THE_MILK', audio: '/assets/audio/to stir the milk.mp3', img: ''},
            {key: 'dangerous thieves', translation: 'EGG_ON_TRIP.TRANSLATED.DANGEROUS_THIEVES', audio: '/assets/audio/dangerous thieves.mp3', img: ''},
            {key: 'the chef looks in the cookery book', translation: 'EGG_ON_TRIP.TRANSLATED.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', audio: '/assets/audio/the chef looks in the cookery book.mp3', img: ''},
          ],
          answers: ['a bucket of grain'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'To stir the milk',
          image: '',
          options: [
            {key: 'to knock on the door', translation: 'EGG_ON_TRIP.TRANSLATED.TO_KNOCK_ON_THE_DOOR', audio: '/assets/audio/to knock on the door.mp3', img: ''},
            {key: 'a bucket of grain', translation: 'EGG_ON_TRIP.TRANSLATED.A_BUCKET_OF_GRAIN', audio: '/assets/audio/a bucket of grain.mp3', img: ''},
            {key: 'to stir the milk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STIR_THE_MILK', audio: '/assets/audio/to stir the milk.mp3', img: ''},
            {key: 'dangerous thieves', translation: 'EGG_ON_TRIP.TRANSLATED.DANGEROUS_THIEVES', audio: '/assets/audio/dangerous thieves.mp3', img: ''},
            {key: 'the chef looks in the cookery book', translation: 'EGG_ON_TRIP.TRANSLATED.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', audio: '/assets/audio/the chef looks in the cookery book.mp3', img: ''},
          ],
          answers: ['to stir the milk'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'The chef looks in the cookery book',
          image: '',
          options: [
            {key: 'to knock on the door', translation: 'EGG_ON_TRIP.TRANSLATED.TO_KNOCK_ON_THE_DOOR', audio: '/assets/audio/to knock on the door.mp3', img: ''},
            {key: 'a bucket of grain', translation: 'EGG_ON_TRIP.TRANSLATED.A_BUCKET_OF_GRAIN', audio: '/assets/audio/a bucket of grain.mp3', img: ''},
            {key: 'to stir the milk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STIR_THE_MILK', audio: '/assets/audio/to stir the milk.mp3', img: ''},
            {key: 'dangerous thieves', translation: 'EGG_ON_TRIP.TRANSLATED.DANGEROUS_THIEVES', audio: '/assets/audio/dangerous thieves.mp3', img: ''},
            {key: 'the chef looks in the cookery book', translation: 'EGG_ON_TRIP.TRANSLATED.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', audio: '/assets/audio/the chef looks in the cookery book.mp3', img: ''},
          ],
          answers: ['the chef looks in the cookery book'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Dangerous thieves',
          image: '',
          options: [
            {key: 'to knock on the door', translation: 'EGG_ON_TRIP.TRANSLATED.TO_KNOCK_ON_THE_DOOR', audio: '/assets/audio/to knock on the door.mp3', img: ''},
            {key: 'a bucket of grain', translation: 'EGG_ON_TRIP.TRANSLATED.A_BUCKET_OF_GRAIN', audio: '/assets/audio/a bucket of grain.mp3', img: ''},
            {key: 'to stir the milk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STIR_THE_MILK', audio: '/assets/audio/to stir the milk.mp3', img: ''},
            {key: 'dangerous thieves', translation: 'EGG_ON_TRIP.TRANSLATED.DANGEROUS_THIEVES', audio: '/assets/audio/dangerous thieves.mp3', img: ''},
            {key: 'the chef looks in the cookery book', translation: 'EGG_ON_TRIP.TRANSLATED.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', audio: '/assets/audio/the chef looks in the cookery book.mp3', img: ''},
          ],
          answers: ['dangerous thieves'],
          correctAnswer: '',
          audio: '',
        }
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

}
