import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-expiration-modal',
  templateUrl: './expiration-modal.component.html',
  styleUrls: ['./expiration-modal.component.css']
})
export class ExpirationModalComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  public onAccept(){
    window.location.reload();
  }

}
