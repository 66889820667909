<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper with-background--shelves">
        <div class="text-center">
          <h1>Dňa 17.11.1989 sa konal spomienkový pochod študentov, po represívnom zásahu poriadkových síl sa začal štrajk študentov. Kedy bol ukončený?</h1>
          <ul>
            <li><a (click)="onWrongAnswer()">a) dňa 27.11., v deň, konania generálneho štrajku v Československu</a></li>
            <li><a (click)="onWrongAnswer()">b) dňa 4.12., v deň, keď sa otvorili hranice s Rakúskom</a></li>
            <li><a (click)="onWrongAnswer()">c) dňa 7.12., v deň demisie federálnej vlády</a></li>
            <li><a (click)="onCorrectAnswer()">d) dňa 29.12., v deň zvolenia Václava Havla prezidentom Československa</a></li>
          </ul>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
