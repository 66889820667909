import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MrWolfComponent } from './mr-wolf.component';
import { MrWolfRoutingModule } from './mr-wolf-routing.module';
import { TutorialComponent } from './tutorial/tutorial.component';
import { Part1Component } from './part1/part1.component';
import { Part2Component } from './part2/part2.component';
import { Part3Component } from './part3/part3.component';
import { Part4Component } from './part4/part4.component';
import { Part5Component } from './part5/part5.component';
import { Part6Component } from './part6/part6.component';
import { Part7Component } from './part7/part7.component';
import { Part8Component } from './part8/part8.component';
import { ExercisesComponent } from './exercises/exercises.component';
import { FinishComponent } from './finish/finish.component';
import { ExtraComponent } from './extra/extra.component';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [
    MrWolfComponent,
    TutorialComponent,
    Part1Component,
    Part2Component,
    Part3Component,
    Part4Component,
    Part5Component,
    Part6Component,
    Part7Component,
    Part8Component,
    ExercisesComponent,
    FinishComponent,
    ExtraComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MrWolfRoutingModule,
  ]
})
export class MrWolfModule { }
