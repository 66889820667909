import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-american-dream',
  templateUrl: './american-dream.component.html',
  styleUrls: ['./american-dream.component.css']
})
export class AmericanDreamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
