import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AudioService } from '../../services/audio.service';

@Component({
  selector: 'app-vocabulary-modal',
  templateUrl: './vocabulary-modal.component.html',
  styleUrls: ['./vocabulary-modal.component.scss']
})
export class VocabularyModalComponent implements OnInit {
  public vocabulary;

  constructor(
    private dialogRef: MatDialogRef<VocabularyModalComponent>,
    public audioService: AudioService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  onClose(){
    this.dialogRef.close();
  }

}
