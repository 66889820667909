import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ExtraComponent } from './extra.component';



@NgModule({
  declarations: [
    ExtraComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class ExtraModule { }
