<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper with-background--shelves">
        <div class="text-center">
          <h1>Koľko ľudí bolo podľa odhadov postihnutých represáliami totalitného režimu v Československu?</h1>
          <ul>
            <li><a (click)="onWrongAnswer()">a) 20 000</a></li>
            <li><a (click)="onWrongAnswer()">b) 200 000</a></li>
            <li><a (click)="onCorrectAnswer()">c) 2 000 000</a></li>
          </ul>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="TEXT" [disabled]="false">
      <div class="player-wrapper">
        <ngx-circular-player radius="80"  source="/assets/audio/november-89/N89_9B.mp3"></ngx-circular-player>
      </div>
      <div class="with-background with-background--paper">
        <div class="text-center">
          <p>Podľa oficiálnych záznamov si totalitný režim na Slovensku vyžiadal viac ako stotisíc obetí. Na stanovenie ich konečného počtu záznamy nestačia. Len mŕtvych vo väzniciach bolo mnohonásobne viac, pretože preukázať skutočnú príčinu smrti je veľmi ťažké.</p>
          <h1>Vybrané skupiny obetí režimu na Slovensku 1948 – 1989:</h1>
          <table>
            <tbody>
            <tr><td>Odsúdení za politické trestné činy:</td><td>71 168</td></tr>
            <tr><td>Popravení:</td><td>50</td></tr>
            <tr><td>Usmrtení na hranici (len Slovensko – Rakúsky úsek):</td><td>42</td></tr>
            <tr><td>Obete augusta 1968:</td><td>31</td></tr>
            <tr><td>Zomreli vo väzniciach:</td><td>51 (v skutočnosti x-násobné množstvo, veľmi ťažko sa preukazuje príčina smrti)</td></tr>
            <tr><td>Odvlečení do ZSSR:</td><td>6973</td></tr>
            <tr><td>Tábory nútenej práce:</td><td>8240</td></tr>
            <tr><td>Internovaní rehoľníci:</td><td>2548</td></tr>
            </tbody>
          </table>
          <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
