<div *ngIf="true" class="white-bcg allow-to-all">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Cvičenia">
      <div class="text-center">
          <div class="row">
            <ng-container *ngFor="let download of downloadList">
              <div *ngIf="storyService.state.lang === download.lang">
                  <a [href]="download.src" target="_blank" style="border-bottom: none">
                    <img [src]="download.img" style="height: 200px;">
                    <br>
                    <span [translate]="download.translation"></span>
                  </a>
              </div>
            </ng-container>
          </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>





