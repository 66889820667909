<h1 mat-dialog-title translate class="text-align-center">INTERFACE.YOUR_ACCESS_HAS_EXPIRED</h1>
<div mat-dialog-content class="text-align-center">
  <span>
    <strong translate>INTERFACE.EXPIRATION_DATE</strong>: <span >{{data.data.expiration | date :'dd.MM yyyy' }}</span>
    <br>
    <strong translate>INTERFACE.SHOW</strong><span>: {{data.data.show}}({{data.data.lang}})</span>
  </span>
</div>
<div mat-dialog-actions class="justify-center">
  <button mat-button mat-raised-button color="primary" (click)="onAccept()"><span translate>INTERFACE.CLOSE</span></button>
</div>
