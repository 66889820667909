import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NovemberComponent } from './november.component';
import { IntroComponent } from './intro/intro.component';
import { Part1Component } from './part1/part1.component';
import { Part2Component } from './part2/part2.component';
import { Part3Component } from './part3/part3.component';
import { Part4Component } from './part4/part4.component';
import { Part5Component } from './part5/part5.component';
import { Part6Component } from './part6/part6.component';
import { Part7Component } from './part7/part7.component';
import { Part8Component } from './part8/part8.component';
import { Part9Component } from './part9/part9.component';
import { Part10Component } from './part10/part10.component';
import { FinishComponent } from './finish/finish.component';
import { SharedModule } from '../../shared/shared.module';
import { NovemberRoutingModule } from './november-routing.module';
import { NovemberService } from './november.service';
import { NgxCircularPlayerModule } from 'ngx-circular-player';
import { ExtraComponent } from './extra/extra.component';
import { TutorialComponent } from './tutorial/tutorial.component';



@NgModule({
  declarations: [
    NovemberComponent,
    IntroComponent,
    Part1Component,
    Part2Component,
    Part3Component,
    Part4Component,
    Part5Component,
    Part6Component,
    Part7Component,
    Part8Component,
    Part9Component,
    Part10Component,
    FinishComponent,
    ExtraComponent,
    TutorialComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NovemberRoutingModule,
    NgxCircularPlayerModule,
  ],
  providers: [
    NovemberService,
  ]
})
export class NovemberModule { }
