<div class="white-bcg allow-to-all">
  <ng-container *ngIf="!order">
  <table class="profile" >
    <tbody>
    <tr>
      <td><strong translate>INTERFACE.NAME</strong></td>
      <td><span>{{state.ticket?.name}} {{state.ticket?.surname}}</span></td>
    </tr>
    <tr>
      <td><strong translate>INTERFACE.CLASS</strong></td>
      <td><span>{{ state.ticket?.class}}</span></td>
    </tr>
    <tr>
      <td><strong translate>INTERFACE.CODE</strong></td>
      <td><span>{{ state.token}}</span></td>
    </tr>
    <tr>
      <td><strong translate>INTERFACE.EXPIRATION_DATE</strong></td>
      <td><span>{{state.expiration | date :'dd.MM yyyy' }}</span></td>
    </tr>
    <tr><td>----</td></tr>
    <tr>
      <td><strong translate>INTERFACE.MAX_SCORE</strong></td>
      <td><span>{{ state.active_points > state.ticket?.points ? state.active_points : state.ticket?.points}}/{{ story?.pointsAvailable}}</span></td>
    </tr>
    <tr>
      <td><strong translate>INTERFACE.CREATED</strong></td>
      <td><span>{{state.ticket?.created | date :'dd.MM yyyy HH:mm' }}</span></td>
    </tr>
    <tr>
      <td><strong translate>INTERFACE.FINISHED_AT</strong></td>
      <td><span>{{state.ticket?.finished | date :'dd.MM yyyy HH:mm' }}</span></td>
    </tr>
    <tr>
      <td><strong translate>INTERFACE.NUMBER_OF_PLAYS</strong></td>
      <td><span>{{state.ticket?.finish_counter}}</span></td>
    </tr>
    </tbody>
  </table>
  <div class="text-center">
    <button mat-button mat-flat-button color="warn" (click)="backToContent()"><span translate>INTERFACE.PLAY</span></button>
  </div>
  </ng-container>
  <ng-container *ngIf="order">
    <mat-tab-group mat-align-tabs="center">
    <mat-tab [label]="'INTERFACE.ACCOUNT' | translate">
      <table class="profile">
        <tbody>
          <tr>
            <td><strong translate>INTERFACE.SHOW</strong></td>
            <td><span translate>{{ order?.show}} ({{order?.lang}})</span></td>
          </tr>
          <tr>
            <td><strong translate>INTERFACE.CODE_PUPIL</strong></td>
            <td><span translate>{{ order?.token_pupil}}</span></td>
          </tr>
          <tr>
            <td><strong translate>INTERFACE.CODE_TEACHER</strong></td>
            <td><span translate>{{ order?.token_teacher}}</span></td>
          <tr>
            <td><strong translate>INTERFACE.FIRST_CODE_GENERATED_DATE</strong></td>
            <td><span translate>{{order?.first_ticket_generated_date | date :'dd.MM yyyy' }}</span></td>
          </tr>
          <tr>
            <td><strong translate>INTERFACE.EXPIRATION_DATE</strong></td>
            <td><span translate>{{order?.expiration | date :'dd.MM yyyy' }}</span></td>
          </tr>
          <tr>
            <td><strong translate>INTERFACE.NO_CODE_GENERATED</strong></td>
<!--            <td><span translate>{{ order?.ticket_generated}} / {{ order?.ticket_ordered}}</span>-->
            <td><span translate>{{ order?.ticket_generated}}</span>
          </td>
          <tr>
            <td><strong translate>INTERFACE.CLASS_NO</strong></td>
            <td><span translate>{{ order?.class_no_generated}} / {{ order?.class_no_ordered}}</span> <button class="add_class" mat-button mat-flat-button color="primary" (click)="openAddClassDialog()"><span translate>INTERFACE.MANAGE_CLASSES</span></button></td>
          <tr>
          <tr>
            <td><strong translate>INTERFACE.SELLER</strong></td>
            <td><span translate>{{ order?.seller.name}}</span></td>
          </tr>
          <tr>
            <td><strong translate>INTERFACE.SELLER_EMAIL</strong></td>
            <td><span translate>{{ order?.seller.email}}</span></td>
          </tr>
          <tr>
            <td><strong translate>INTERFACE.SELLER_TELEPHONE</strong></td>
            <td><span translate>{{ order?.seller.telephone}}</span></td>
          </tr>
        </tbody>
      </table>
      <div class="text-center">
        <button mat-button mat-flat-button color="warn" (click)="backToContent()" *ngIf="!storyService.isPresentationExpired()"><span translate>INTERFACE.PLAY</span></button>
      </div>
    </mat-tab>
    <mat-tab [label]="'INTERFACE.PUPILS_RESULTS' | translate">
      <div class="filter">
        <mat-button-toggle-group (change)="selectClass($event)" value="{{selectedClass}}">
          <mat-button-toggle [value]="''"><span translate>INTERFACE.ALL</span></mat-button-toggle>
          <mat-button-toggle *ngFor="let class of classList" [value]="class">{{class}}</mat-button-toggle>
        </mat-button-toggle-group>
<!--        <button mat-button mat-flat-button color="primary" (click)="openAddClassDialog()"><span translate>INTERFACE.MANAGE_CLASSES</span></button>-->
      </div>
      <table class="results">
        <thead>
        <tr>
          <th translate></th>
          <th translate>INTERFACE.SURNAME</th>
          <th translate>INTERFACE.NAME</th>
          <th translate>INTERFACE.CLASS</th>
          <th translate>INTERFACE.CODE</th>
          <th translate>INTERFACE.LAST_PART</th>
<!--          <th translate>INTERFACE.UPDATED</th>-->
          <th><span translate>INTERFACE.MAX_SCORE</span> ({{ story?.pointsAvailable}})</th>
          <th translate>INTERFACE.NUMBER_OF_PLAYS</th>
          <th translate>INTERFACE.CREATED</th>
          <th><span translate>INTERFACE.DURATION_OF_FIRST_PLAY</span> ({{ story?.duration}} min.)</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ticket of order?.ticketList | sort:'asc':'surname' | class: selectedClass; let i = index">
            <td><span translate>{{i+1}}</span></td>
            <td><span translate>{{ticket.surname}}</span></td>
            <td><span translate>{{ticket.name}}</span></td>
            <td><span translate>{{ticket.class}}</span></td>
            <td><span translate>{{ticket.token}}</span></td>
            <td><span translate>{{ getActivePart(ticket)}}</span></td>
<!--            <td><span translate>{{ticket.updated | date :'dd.MM yyyy HH:mm' }}</span></td>-->
            <td><span translate>{{ticket.points}}</span></td>
            <td><span translate>{{ticket.finish_counter}}</span></td>
            <td><span translate>{{ticket.created | date :'dd.MM yyyy HH:mm' }}</span></td>
            <td><span translate>{{getTimeDiff(ticket)}}</span></td>
          </tr>
        </tbody>
      </table>
    </mat-tab>
  </mat-tab-group>
  </ng-container>
</div>




