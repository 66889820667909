import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';
import { HrdinoviaService } from '../hrdinovia.service';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.css']
})
export class ExercisesComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public timestampKey = null;
  public pexeso: Test;
  private answeredKeyArray = [];

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
    private hrdinoviaService: HrdinoviaService,
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.onPlayAttention(0);
    this.pexeso = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'poziar', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_150.png'},
          {key: 'uraz', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_155.png'},
          {key: 'autonehoda', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_112.png'},
          {key: 'kradez', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_158.png'},
        ],
        answers: [
          {key: 'poziar', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_poziar.png'},
          {key: 'uraz', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_uraz.png'},
          {key: 'autonehoda', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_autonehoda.png'},
          {key: 'kradez', translation: '', audio: '', img: '/assets/img/hrdinovia/o10_kradez.png'},
        ]
      }
    };
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }


  public onTestStateChange($event){
    this.state.isDone = $event;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    setTimeout(() => {  this.storyService.setPartAsDone(); }, 4000);
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  onPlayAttention(tabKey: number): void{
    const attentionList = {
      0 : '/assets/audio/hrdinovia/uvod_cvicenia.mp3',
      1 : '/assets/audio/hrdinovia/cvicenie_1.mp3',
      2 : '/assets/audio/hrdinovia/cvicenie_2.mp3',
      3 : '/assets/audio/hrdinovia/cvicenie_3.mp3',
      4 : '/assets/audio/hrdinovia/cvicenie_4.mp3',
      5 : '/assets/audio/hrdinovia/cvicenie_5.mp3',
      6 : '/assets/audio/hrdinovia/cvicenie_6.mp3',
      7 : '/assets/audio/hrdinovia/cvicenie_7.mp3',
      8 : '/assets/audio/hrdinovia/cvicenie_8.mp3',
      9 : '/assets/audio/hrdinovia/cvicenie_9.mp3',
      10 : '/assets/audio/hrdinovia/cvicenie_10.mp3',
    };
    this.audioService.playAudio(attentionList[tabKey]);
  }

  onWrongAnswer(){
    this.hrdinoviaService.onWrongAnswer();
  }

  onCorrectAnswer(){
    this.hrdinoviaService.onCorrectAnswer();
    setTimeout(() => { this.nextTab(); }, 3000);
  }

  onMultipleCorrectAnswer(key, length){
    this.hrdinoviaService.onCorrectAnswer();
    this.answeredKeyArray.push(key);
    if (this.answeredKeyArray.length === length){
      setTimeout(() => {
        this.nextTab();
        this.answeredKeyArray = [];
      }, 3000);
    }
  }

  public isAnswered(key){
    return this.answeredKeyArray.includes(key);
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.onPlayAttention($event.index); // toto bude citat zadanie
    }
  }

}
