import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-part-tree',
  templateUrl: './part-three.component.html',
  styleUrls: ['./part-three.component.css']
})
export class PartThreeComponent implements OnInit {
  public test: Test;
  public vocabulary: Test;
  public state = null;
  public showNextTab = null;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public timestampKey = null;

  constructor(
      private storyService: StoryService,
      private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_3_1', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka3.mp4', id: '462662533'};
    this.video2 = {src: '/assets/video/Egg On Trip_V5_3cast.mp4', id: '462792968'};
    this.video3 = {src: '/assets/video/3_cvicenie_08_V1.mp4', id: '462658110'};
    this.info2 = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_3_2', audio: ''},
    ];
    this.test = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'tired', translation: 'EGG_ON_TRIP.TIRED', audio: '/assets/audio/tired.mp3', img: '/assets/img/tired.png'},
          {key: 'funny', translation: 'EGG_ON_TRIP.FUNNY', audio: '/assets/audio/funny.mp3', img: '/assets/img/funny.png'},
          {key: 'scared', translation: 'EGG_ON_TRIP.SCARED', audio: '/assets/audio/scared.mp3', img: '/assets/img/scared.png'},
          {key: 'loud', translation: 'EGG_ON_TRIP.LOUD', audio: '/assets/audio/loud.mp3', img: '/assets/img/loud.png'},
          {key: 'beautiful', translation: 'EGG_ON_TRIP.BEAUTIFUL', audio: '/assets/audio/beautiful.mp3', img: '/assets/img/beautiful.png'}
        ],
        answers: [
          {key: 'tired', translation: 'EGG_ON_TRIP.TIRED', audio: '/assets/audio/tired.mp3', img: '/assets/img/tired.png'},
          {key: 'funny', translation: 'EGG_ON_TRIP.FUNNY', audio: '/assets/audio/funny.mp3', img: '/assets/img/funny.png'},
          {key: 'scared', translation: 'EGG_ON_TRIP.SCARED', audio: '/assets/audio/scared.mp3', img: '/assets/img/scared.png'},
          {key: 'loud', translation: 'EGG_ON_TRIP.LOUD', audio: '/assets/audio/tired.mp3', img: '/assets/img/loud.png'},
          {key: 'beautiful', translation: 'EGG_ON_TRIP.BEAUTIFUL', audio: '/assets/audio/beautiful.mp3', img: '/assets/img/beautiful.png'}
        ]
      }
    };
    this.vocabulary = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'To hurry up',
          image: '',
          options: [
            {key: 'to hurry up', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HURRY_UP', audio: '/assets/audio/to hurry up.mp3', img: ''},
            {key: 'wild animal', translation: 'EGG_ON_TRIP.TRANSLATED.WILD_ANIMAL', audio: '/assets/audio/wild animal.mp3', img: ''},
            {key: 'i am lost', translation: 'EGG_ON_TRIP.TRANSLATED.I_AM_LOST', audio: '/assets/audio/I am lost.mp3', img: ''},
          ],
          answers: ['to hurry up'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Wild animal',
          image: '',
          options: [
            {key: 'to hurry up', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HURRY_UP', audio: '/assets/audio/to hurry up.mp3', img: ''},
            {key: 'wild animal', translation: 'EGG_ON_TRIP.TRANSLATED.WILD_ANIMAL', audio: '/assets/audio/wild animal.mp3', img: ''},
            {key: 'i am lost', translation: 'EGG_ON_TRIP.TRANSLATED.I_AM_LOST', audio: '/assets/audio/I am lost.mp3', img: ''},
          ],
          answers: ['wild animal'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'I am lost',
          image: '',
          options: [
            {key: 'to hurry up', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HURRY_UP', audio: '/assets/audio/to hurry up.mp3', img: ''},
            {key: 'wild animal', translation: 'EGG_ON_TRIP.TRANSLATED.WILD_ANIMAL', audio: '/assets/audio/wild animal.mp3', img: ''},
            {key: 'i am lost', translation: 'EGG_ON_TRIP.TRANSLATED.I_AM_LOST', audio: '/assets/audio/I am lost.mp3', img: ''},
          ],
          answers: ['i am lost'],
          correctAnswer: '',
          audio: '',
        },
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public next() {
    this.storyService.setPartAsDone();
  }
}
