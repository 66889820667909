import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Info } from '../../models/info.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() content: Info;
  constructor(
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {}
}
