import { NgModule } from '@angular/core';
import { StoriesComponent } from './stories.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    StoriesComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
  ]
})
export class StoriesModule { }
