<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Záver 1/2">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1 style="font-size: 30px">
            Hrdinský kvíz máš úspešne za sebou.
            <br>
            Prajeme ti veľa odvahy a statočnosti pri konaní hrdinských činov.
            <br>
            A nezabudni, že hrdina sa skrýva v každom z nás!
          </h1>
          <button mat-raised-button color="accent" (click)="nextTab()">Ďalej</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Záver 2/2">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <img src="/assets/img/hrdinovia/o11_hrdina.png" style="max-height: 400px">
          <h1 style="font-size: 30px">
            Ak ti niektoré otázky neboli jasné, spýtaj sa pani učiteľky, alebo pána učiteľa, určite sa dozvieš viac.
            <br>
            V časti Extra nájdeš ďalšie zaujímavé pomôcky pre hrdinov.
          </h1>
          <button mat-raised-button color="accent" (click)="nextTab()">Ďalej</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Titulky">
      <app-video [id]="video.id" *ngIf="state.tabIndex === 2" (onDone)="onLastVideoPlayed($event)"></app-video>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': allowToStartAgain}">
  <h2 translate>INTERFACE.WELL_DONE</h2>
  <button mat-raised-button color="accent" (click)="startAgain()" ><span translate>INTERFACE.START_AGAIN</span></button>
</div>
