import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-seven',
  templateUrl: './part-seven.component.html',
  styleUrls: ['./part-seven.component.css']
})
export class PartSevenComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public test: Test = null;

  constructor(
    private storyService: StoryService,
    public audioService: AudioService,
    private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.test = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'ALL AMERICANS LIVE IN BIG HOUSES',
          image: '',
          options: [
            {key: 'myth', translation: 'MYTH', audio: '', img: ''},
            {key: 'fact', translation: 'FACT', audio: '', img: ''},
          ],
          answers: ['myth'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_ZS/7_1_Big houses.mp3',
        }, {
          text: 'AMERICANS THINK WORK IS THE MOST IMPORTANT THING IN THEIR LIFE',
          image: '',
          options: [
            {key: 'myth', translation: 'MYTH', audio: '', img: ''},
            {key: 'fact', translation: 'FACT', audio: '', img: ''},
          ],
          answers: ['myth'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_ZS/7_2_work.mp3',
        }, {
          text: 'AMERICANS LOVE HELPING THE REST OF THE WORLD',
          image: '',
          options: [
            {key: 'myth', translation: 'MYTH', audio: '', img: ''},
            {key: 'fact', translation: 'FACT', audio: '', img: ''},
          ],
          answers: ['myth'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_ZS/7_3_Americans love to help.mp3',
        }, {
          text: 'EVERYONE CAN REACH THEIR DREAM IN AMERICA',
          image: '',
          options: [
            {key: 'myth', translation: 'MYTH', audio: '', img: ''},
            {key: 'fact', translation: 'FACT', audio: '', img: ''},
          ],
          answers: ['myth'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_ZS/7_4_dream.mp3',
        }, {
          text: 'THERE ARE LOTS OF DIFFERENT CULTURES IN AMERICA',
          image: '',
          options: [
            {key: 'myth', translation: 'MYTH', audio: '', img: ''},
            {key: 'fact', translation: 'FACT', audio: '', img: ''},
          ],
          answers: ['fact'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_ZS/7_5_different cultures in America.mp3',
        }
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_ZS/zadanie_7_listen carefully.mp3');
  }

  onClickAfterLongText(){
    this.nextTab();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_ZS/zadanie_7_uloha.mp3');
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

}
