import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { TranslateService } from '@ngx-translate/core';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;
  public allowToStartAgain = null;
  public finishScrId: string = null;

  constructor(
    public storyService: StoryService,
    public audioService: AudioService,
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: 'WOLF_cast9titulky_final.mp4', id: '561287161'};
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    // this.audioService.playAudio('/assets/audio/mr-wolf/exercises/well_done_you_completes_all_exercises.mp3');
    this.finishScrId = '561288798';
    if (this.storyService.state.active_points > 100) {
      this.finishScrId = '561287576';
    } else if (this.storyService.state.active_points > 50){
      this.finishScrId = '561288191';
    }
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.allowToStartAgain = true;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  public onLastVideoPlayed($event){
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.allowToStartAgain = true;
  }

  public startAgain(){
    this.storyService.setDefaultState();
  }
}
