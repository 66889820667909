import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { StoryService } from '../../services/story.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  public registrationForm: FormGroup;
  public classList: any[] = [];
  public showForm = true;
  public ticket: any = null;
  public token: string = null;
  public gdrpLink = '';

  constructor(
    private stateService: StateService,
    private storyService: StoryService,
    private http: HttpService,
    private router: Router,
    private dialogRef: MatDialogRef<LoginModalComponent>
  ) {
  }

  ngOnInit(): void {
    const state = this.stateService.getState();
    this.ticket = state.ticket;
    this.registrationForm = new FormGroup({
      name: new FormControl(this.isEmpty(state.ticket.name) ? '' : state.ticket.name, Validators.required),
      surname: new FormControl(this.isEmpty(state.ticket.surname) ? '' : state.ticket.surname, Validators.required),
      class_id: new FormControl(this.isEmpty(state.ticket.class_id) ? '' : state.ticket.class_id, Validators.required),
      approve_gdpr: new FormControl(undefined, Validators.requiredTrue),
    });
    this.token = state.token;
    switch (this.stateService.state.lang){
      case 'sk':
        this.gdrpLink = 'https://www.divadelnecentrum.sk/ochrana-osobnych-udajov-kp';
        break;
      case 'cz':
        this.gdrpLink = 'https://www.divadelnicentrum.cz/ochrana-osobnich-udaju';
        break;
      case 'pl':
        this.gdrpLink = 'https://www.centrumteatralne.pl/gdpr';
        break;
    }
    this.getClassList();
  }

  getClassList(){
    this.http.get(environment.apiUrl + '/api/online/order-class/' + this.stateService.getState().token).subscribe((result) => {
      this.classList = result['data'];
      console.log(this.classList);
    });
  }

  onSubmitRegistrationForm(){
    this.http.post(environment.apiUrl + '/api/online/update-ticket/' + this.stateService.getState().token, this.registrationForm.value).subscribe((result) => {
        this.stateService.setStateByKey(result['data'], 'ticket');
        this.showForm = false;
    });
  }

  onAccept(){
    const state = this.stateService.getState();
    this.router.navigate(['/stories', state.active_story, state.active_part.key]);
    this.dialogRef.close();
  }

  public onClose(){
    this.storyService.clearStates();
    window.location.reload();
  }

  public isEmpty(str) {
    return (!str || 0 === str.length);
  }

}
