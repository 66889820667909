import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { EggOnATripComponent } from './egg-on-a-trip.component';
import { PartOneModule } from './part-one/part-one.module';
import { PartThreeModule } from './part-three/part-three.module';
import { PartTwoModule } from './part-two/part-two.module';
import { PartFourModule } from './part-four/part-four.module';
import { PartFiveModule } from './part-five/part-five.module';
import { PartSixModule } from './part-six/part-six.module';
import { PartSevenModule } from './part-seven/part-seven.module';
import { IntroModule } from './intro/intro.module';
import { FinishModule } from './finish/finish.module';
import { ExtraModule } from './extra/extra.module';
import { TutorialModule } from './tutorial/tutorial.module';

@NgModule({
  declarations: [
    EggOnATripComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    IntroModule,
    PartOneModule,
    PartTwoModule,
    PartThreeModule,
    PartFourModule,
    PartFiveModule,
    PartSixModule,
    PartSevenModule,
    FinishModule,
    ExtraModule,
    TutorialModule,
  ]
})
export class EggOnATripModule { }
