<mat-grid-list cols="1" rowHeight="400px">
    <mat-grid-tile>
        <form class="example-form" (ngSubmit)="onSubmitForm()">
          <mat-form-field class="example-full-width">
            <mat-label translate>Your code</mat-label>
            <input matInput [formControl]="tokenFormControl" placeholder="XXXXXX-XX-???">
            <mat-error *ngIf="!tokenFormControl.hasError('required')">Please enter code</mat-error>
            <mat-error *ngIf="tokenFormControl.hasError('required')">
             <strong>Code is required</strong>
            </mat-error>
            <div class="error" *ngIf="errorText != ''"><strong> Wrong code</strong></div>
          </mat-form-field>
          <button mat-raised-button type="submit" color="primary"><span>Confirm</span></button>
        </form>
    </mat-grid-tile>
</mat-grid-list>
