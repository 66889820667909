<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="TEXT" [disabled]="false">
      <div class="player-wrapper">
        <ngx-circular-player radius="80"  source="/assets/audio/november-89/N89_7.mp3"></ngx-circular-player>
      </div>
      <div class="with-background with-background--paper">
        <div class="text-center">
          <p>
            Na 17.11. 1989 organizovalo Nezávislé študentské združenie spomienkový pochod. V tento deň sme si pripomínali päťdesiate výročie pohrebu Jana Opletala, študenta Lekárskej fakulty Univerzity Karlovej v Prahe, smrteľne zraneného pri demonštráciách proti nacistickým okupantom. Študenti na spomienkovej akcii neplánovane zmenili trasu pochodu, ktorý na Národní třídě zastavili a rozohnali poriadkové jednotky.
            <br>
            O policajnom zásahu proti študentom sa začali šíriť rôzne fámy, medzi nimi aj správa o údajnej smrti študenta Martina Šmída. Rádio Slobodná Európa odvysielalo v sobotu 18.11. nasledujúcu správu:
          </p>
          <p>
            <i>“Dnes bolo vo večerných hodinách potvrdené, že 17. novembra 1989 medzi dvadsiatou prvou a dvadsiatou druhou hodinou bol pri manifestácii na Národnej triede ubitý študent Matematicko-fyzikálnej fakulty Univerzity Karlovej Martin Šmíd. Očitá svedkyňa, ktorá pozná Martina Šmída od detstva a ktorá s ním na manifestáciu prišla, vypovedala, že po dvadsiatej prvej hodine obkľúčili parašutisti v červených baretoch skupinu manifestujúcich pred jazykovou školou a bili ich obuškami. Jeden z nich vyberal z obkľúčenej skupiny zrejme náhodne jednotlivých študentov, ktorých parašutisti vždy odvliekli do pasáže rohového domu na križovatke Národnej triedy a Mikulandskej ulice."</i>
          </p>
          <p>
            Správa o smrti študenta spustila po celom Československu masové protesty, vedúce k pádu totalitného režimu.
          </p>
          <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
