<div *ngIf="true">
  <app-progress [step]="6" [length]="8"></app-progress>
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="TASK">
      <div class="with-background with-background--shelves">
        <div class="text-center">
          <h1>Never heard of Jackson Pollock? Try and find which artwork is his, and match each painting to its artist.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ">
      <app-test [test]="test" [readBefore]="true" [showOptionAs]="'html'" [showAnswerAs]="'image'" [isDone]="state.isDone" (onDone)="onTestStateChange($event)" *ngIf="state.tabIndex === 1" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
  <button class="with-image" (click)="next()" ><img src="/assets/img/american-dream/next.png"></button>
</div>


