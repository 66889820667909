<div class="test" [ngClass]="customClass">
    <ng-container *ngIf="test.type === 'questions'">
        <div>
            <div>
                <ng-container *ngIf="!isDone">
                    <h1> {{ test.name}}</h1>
                    <span> {{ test.description}}</span>
                    <h1 *ngIf="!rightAnswer" [innerHTML]="test.questions[step].text | dots"></h1>
                    <h1 *ngIf="rightAnswer != '' && showOptionAs === 'text'">{{ rightAnswer}}</h1>
                    <img *ngIf="test.questions[step].image !== ''" style="width: 200px" [src]="test.questions[step].image" [attr.alt]="test.questions[step].text">
                    <div class="options" *ngIf="showOptionAs === 'text'" style="margin-top: 20px">
                        <button mat-raised-button color="accent" *ngFor="let item of optionList" (click)="onAnswer(item)" [disabled]="disableToAddNewPoints || disableToClickAgain"><span [translate]="item.translation"></span></button>
                    </div>
                </ng-container>
                <ng-container *ngIf="isDone && allowRepeat">
                    <h1 *ngIf="(rightAnswer !== '' || rightAnswer) && showOptionAs === 'text'">
                        <span>{{ rightAnswer}}</span>
                    </h1>
                    <img *ngIf="(rightImage !== ''|| rightImage)" style="width: 200px" [src]="rightImage" [attr.alt]="rightAnswer">
                    <div class="options" *ngIf="showOptionAs === 'text'" style="margin-top: 20px">
                        <button mat-raised-button color="accent" *ngFor="let item of optionList" (click)="selectToRepeat(item)" [disabled]="disableToClickAgain"><span [translate]="item.translation"></span></button>
                    </div>
                    <h1 *ngIf="!rightAnswer" style="margin-top: 30px;">
                        <span translate>INTERFACE.REPEAT_IT</span>
                    </h1>
                </ng-container>
            </div>
            <div class="options-container" *ngIf="showOptionAs !== 'text'">
                <div class="image-options" *ngFor="let item of optionList">
                    <div class="card" (click)="onAnswer(item)" [ngClass]="{'passed': isPassed(item.key)}">
                      <img *ngIf="showOptionAs === 'image'" class="image" [src]="item.img" [attr.alt]="item.name">
                      <div *ngIf="showOptionAs === 'image+text'" class="image-text">
                        <img [src]="item.img" [attr.alt]="item.name">
                        <strong><span [translate]="item.translation"></span></strong>
                      </div>
                    </div>
                 </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="test.type === 'rightOrder'">
        <div>
            <div>
                <div>
                    <div *ngFor="let item of optionList; let i = index">
                        <mat-card (click)="selectOrder(item.key)" [ngClass]="{'selected': selectedOption === item.key, 'passed': isOrdered(item.key)}">
                            <img *ngIf="item.type === 'option'" mat-card-image [src]="item.img" [attr.alt]="item.name">
                            <strong><span [translate]="item.translation"></span></strong>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="test.type === 'anagram'">
        <div>
            <div col="1">
                <div class="answers" style="padding: 30px">
                    <button mat-raised-button color="accent" *ngFor="let item of test.anagrams[step]?.options" (click)="selectAnagram(item)"><span [translate]="item.translation"></span></button>
                </div>
                <div class="anagram" style="margin-top: 20px">
                    <div class="anagram-result" *ngFor="let item of test.anagrams[step]?.options ; let i = index">
                     <span *ngIf="anagramResult[i]">{{ anagramResult[i]}}</span>
                     <span *ngIf="!anagramResult[i]">___</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="test.type === 'pairs'">
        <div>
            <h1 *ngIf="title"> {{ title}}</h1>
            <div class="options-container">
                <div class="image-options" *ngFor="let item of optionList; let i = index">
                    <div class="card" (click)="selectWord(item,item.type)" [ngClass]="{'selected': (selectedAnswer?.key === item.key && item.type === 'answer') || (selectedOption?.key === item.key && item.type === 'option'), 'passed': isPassed(item.key)}">
                        <ng-container *ngIf="item.type === 'answer'">
                            <strong *ngIf="showAnswerAs === 'text'" ><span [translate]="item.translation"></span></strong>
                            <div *ngIf="showAnswerAs === 'image'" class="image"   [ngStyle]="{'background-image': 'url(' + item.img + ')'}"></div>
                            <div *ngIf="showAnswerAs === 'image+text'"  class="image-text">
                              <img [src]="item.img" [attr.alt]="item.name">
                              <strong><span [translate]="item.translation"></span></strong>
                            </div>
                            <strong *ngIf="showAnswerAs === 'html'" [innerHTML]="item.translation | safeHtml"></strong>
                        </ng-container>
                        <ng-container *ngIf="item.type === 'option'">
                            <strong *ngIf="showOptionAs === 'text'" ><span [translate]="item.translation"></span></strong>
                            <div *ngIf="showOptionAs === 'image'" class="image" [ngStyle]="{'background-image': 'url(' + item.img + ')'}"></div>
                            <div *ngIf="showOptionAs === 'image+text'" class="image-text">
                              <img [src]="item.img" [attr.alt]="item.name">
                              <strong><span [translate]="item.translation"></span></strong>
                            </div>
                            <strong *ngIf="showOptionAs === 'html'" [innerHTML]="item.translation | safeHtml"></strong>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
