<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="SCORE">
      <div class="with-background with-background--finish">
        <div class="text-center">
          <h1>You did it!</h1>
          <h1>Your score is</h1>
          <h1 class="score">{{storyService.state.active_points}}</h1>
          <br>
          <h1>Congratulations</h1>
          <button class="with-image" (click)="storyService.setDefaultState()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
<!--    <mat-tab label="SUBTITLES">-->
<!--      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)"></app-video>-->
<!--    </mat-tab>-->
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
  <button class="with-image" (click)="storyService.setDefaultState()" ><img src="/assets/img/american-dream/next.png"></button>
</div>



