<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="SCORE">
      <div class="with-background">
        <div class="text-center">
          <h1 style="font-size: 30px">Well done, you completed all the exercises!</h1>
          <h1>Your score is</h1>
          <h1 class="score">{{storyService.state.active_points}}</h1>
          <br>
          <h1>Congratulations</h1>
          <button mat-raised-button color="accent" (click)="nextTab()">GET YOUR PRICE</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="PRICE">
      <app-video [id]="finishScrId" *ngIf="state.tabIndex === 1" [allowPlayNext]="false" [endImmediately]="true" (onDone)="onVideoPlayed($event)"></app-video>
    </mat-tab>
    <mat-tab label="SUBTITLES">
      <app-video [id]="video.id" *ngIf="state.tabIndex === 2" (onDone)="onLastVideoPlayed($event)"></app-video>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': allowToStartAgain}">
  <h2 translate>INTERFACE.WELL_DONE</h2>
  <button mat-raised-button color="accent" (click)="startAgain()" ><span translate>INTERFACE.START_AGAIN</span></button>
</div>
