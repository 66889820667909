<div class="modal-header">
  <button class="close" mat-button (click)="onClose()">X</button>
</div>
<div>
  <h3 class="text-center" [innerHTML]="'INTERFACE.CLASS_NO_INTRODUCTION' | translate : { class_no_ordered: order?.class_no_ordered }"></h3>
</div>
  <div mat-dialog-content class="text-align-center">
    <table class="results">
      <thead>
      <tr>
        <th translate></th>
        <th translate>INTERFACE.CLASS</th>
        <th translate>INTERFACE.NO_CODE_GENERATED</th>
        <th translate>INTERFACE.NUMBER_OF_PRESENTATION</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let class of classList | sort:'asc':'title' ; let i = index">
        <td><span translate>{{i+1}}</span></td>
        <td><span translate>{{class.title}}</span></td>
        <td><span translate>{{ class.ticket_generated_count}}</span></td>
        <td><span>{{ class.plays_count}} / 5 </span><a class="play-button" *ngIf="class.plays_count < 5" (click)="selectClassForPresentation(class.id)" translate>INTERFACE.PLAY</a></td>
      </tr>
      </tbody>
    </table>
    <form *ngIf="classList.length < order?.class_no_ordered" class="example-form" [formGroup]="addClassForm" (ngSubmit)="onSubmitAddClassForm()">
      <mat-form-field appearance="fill">
        <mat-label translate>INTERFACE.CLASS</mat-label>
        <input matInput formControlName="title" placeholder="4A">
        <mat-error *ngIf="addClassForm.get('title').hasError('required')">
          <strong translate>INTERFACE.CLASS_IS_REQUIRED</strong>
        </mat-error>
        <mat-error *ngIf="addClassForm.get('title').hasError('pattern')">
          <strong translate>INTERFACE.CLASS_NEEDS_TO_BE_FILL_CORRECTLY</strong>
        </mat-error>
      </mat-form-field>
      <button mat-button mat-flat-button color="primary" type="submit" [disabled]="!addClassForm.valid"><span translate>INTERFACE.CONFIRM</span></button>
    </form>
  </div>

