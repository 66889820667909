import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss']
})
export class ExtraComponent implements OnInit {
  public downloadList;
  public vocabulary;
  constructor(
    public audioService: AudioService,
    public storyService: StoryService,
  ){
    this.downloadList = [
      {src: '/assets/downloads/MR WOLF - CZ.pdf', lang: 'cz', translation: 'Download English exercises in PDF (identical to online exercises)', img: '/assets/img/mr-wolf/print.jpg'},
      {src: '/assets/downloads/MR WOLF - SK.pdf', lang: 'sk', translation: 'Download English exercises in PDF (identical to online exercises)', img: '/assets/img/mr-wolf/print.jpg'},
      {src: '/assets/downloads/MR WOLF - PL.pdf', lang: 'pl', translation: 'Download English exercises in PDF (identical to online exercises)', img: '/assets/img/mr-wolf/print.jpg'},
      {src: '/assets/downloads/MR WOLF songs - CZ.pdf', lang: 'cz', translation: 'Download English songs in PDF', img: '/assets/img/mr-wolf/songs.jpg'},
      {src: '/assets/downloads/MR WOLF songs - SK.pdf', lang: 'sk', translation: 'Download English songs in PDF', img: '/assets/img/mr-wolf/songs.jpg'},
      {src: '/assets/downloads/MR WOLF songs  - PL.pdf', lang: 'pl', translation: 'Download English songs in PDF', img: '/assets/img/mr-wolf/songs.jpg'},
      {src: '/assets/downloads/MR WOLF glossary - CZ.pdf', lang: 'cz', translation: 'Download English glossary in PDF', img: '/assets/img/mr-wolf/glossary.jpg'},
      {src: '/assets/downloads/MR WOLF glossary - SK.pdf', lang: 'sk', translation: 'Download English glossary in PDF', img: '/assets/img/mr-wolf/glossary.jpg'},
      {src: '/assets/downloads/MR WOLF glossary - PL.pdf', lang: 'pl', translation: 'Download English glossary in PDF', img: '/assets/img/mr-wolf/glossary.jpg'},
    ];
    this.vocabulary = this.storyService.vocabulary['mr-wolf'];
  }

  ngOnInit(): void {
  }

  backToContent(){
    this.storyService.goBackToTest();
  }
}
