import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatVideoComponent } from 'mat-video/lib/video.component';
import * as Player from '@vimeo/player/dist/player.js';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements AfterViewInit{
  @Input() src: string;
  @Input() id: string;
  @Input() allowPlayAgain = true;
  @Input() allowPlayNext = true;
  @Input() endImmediately = false;
  @Input() timestamps = [[0, 1000]];
  video: HTMLVideoElement;
  @Output() public onDone: EventEmitter<any> = new EventEmitter();
  @Output() public onTimestamp: EventEmitter<any> = new EventEmitter();
  public showNext = false;
  public timestampKey = null;

  @ViewChild('player_container') playerContainer;
  private player: Player;

  constructor() { }

  ngAfterViewInit(): void {
    const options = {
      id: this.id,
      loop: false,
      responsive: true,
      autoplay: true,
      color: '#ed1c24',
    };

    this.player = new Player(this.playerContainer.nativeElement, options);

    this.player.on('ended', () => {
      if (this.endImmediately) {
        this.onDone.emit(true);
      } else {
        this.showNext = true;
      }
      this.closeFullscreen();
    });

    this.player.on('timeupdate', (data) => {
      let keyCalculated = null;
      const time = data.seconds.toFixed();
      this.timestamps.forEach((value , index) => {
        if (value[0] <= time && time < value[1]) {
          keyCalculated = index;
        }
      });
      if (this.timestampKey !== keyCalculated) {
        this.timestampKey = keyCalculated;
        this.onTimestamp.emit(this.timestampKey);
      }
    });

    setTimeout(() => {
      this.player.play();
    }, 5000);
  }

  onNext() {
    this.onDone.emit(true);
  }

  onPlay() {
    this.showNext = false;
    this.player.play();
  }

  onClose(){
    this.showNext = false;
  }

  closeFullscreen() {
    if (document['exitFullscreen']) {
      document['exitFullscreen']();
    } else if (document['mozCancelFullScreen']) {
      /* Firefox */
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      /* Chrome, Safari and Opera */
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      /* IE/Edge */
      document['msExitFullscreen']();
    }
  }


}
