import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-part-one',
  templateUrl: './part-one.component.html',
  styleUrls: ['./part-one.component.css']
})
export class PartOneComponent implements OnInit, AfterViewInit{
  public state = null;
  public showNextTab = null;
  public info = null;
  public info2 = null;
  public test: Test = null;
  public vocabulary: Test = null;
  public video;
  public video2;
  public video3;
  public timestampKey = null;

  constructor(
      private storyService: StoryService,
      private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.vocabulary = {
          name: '',
          description: '',
          type: 'questions',
          questions: [
              {
                  text: 'To go for a trip',
                  image: '',
                  options: [
                      {key: 'to go for a trip', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_TRIP', audio: '/assets/audio/to go for a trip.mp3', img: ''},
                      {key: 'be careful', translation: 'EGG_ON_TRIP.TRANSLATED.BE_CAREFUL', audio: '/assets/audio/be carefull.mp3', img: ''},
                      {key: 'to stop in a meadow', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STOP_IN_A_MEADOW', audio: '/assets/audio/to stop in a meadow.mp3', img: ''},
                      {key: 'to have a rest', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HAVE_A_REST', audio: '/assets/audio/to have a rest.mp3', img: ''},
                      {key: 'the wrong direction', translation: 'EGG_ON_TRIP.TRANSLATED.THE_WRONG_DIRECTION', audio: '/assets/audio/the wrong direction.mp3', img: ''}
                  ],
                  answers: ['to go for a trip'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'Be careful',
                  image: '',
                  options: [
                    {key: 'to go for a trip', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_TRIP', audio: '/assets/audio/to go for a trip.mp3', img: ''},
                    {key: 'be careful', translation: 'EGG_ON_TRIP.TRANSLATED.BE_CAREFUL', audio: '/assets/audio/be carefull.mp3', img: ''},
                    {key: 'to stop in a meadow', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STOP_IN_A_MEADOW', audio: '/assets/audio/to stop in a meadow.mp3', img: ''},
                    {key: 'to have a rest', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HAVE_A_REST', audio: '/assets/audio/to have a rest.mp3', img: ''},
                    {key: 'the wrong direction', translation: 'EGG_ON_TRIP.TRANSLATED.THE_WRONG_DIRECTION', audio: '/assets/audio/the wrong direction.mp3', img: ''}
                  ],
                  answers: ['be careful'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'To stop in a meadow',
                  image: '',
                  options: [
                    {key: 'to go for a trip', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_TRIP', audio: '/assets/audio/to go for a trip.mp3', img: ''},
                    {key: 'be careful', translation: 'EGG_ON_TRIP.TRANSLATED.BE_CAREFUL', audio: '/assets/audio/be carefull.mp3', img: ''},
                    {key: 'to stop in a meadow', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STOP_IN_A_MEADOW', audio: '/assets/audio/to stop in a meadow.mp3', img: ''},
                    {key: 'to have a rest', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HAVE_A_REST', audio: '/assets/audio/to have a rest.mp3', img: ''},
                    {key: 'the wrong direction', translation: 'EGG_ON_TRIP.TRANSLATED.THE_WRONG_DIRECTION', audio: '/assets/audio/the wrong direction.mp3', img: ''}
                  ],
                  answers: ['to stop in a meadow'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'To have a rest',
                  image: '',
                  options: [
                    {key: 'to go for a trip', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_TRIP', audio: '/assets/audio/to go for a trip.mp3', img: ''},
                    {key: 'be careful', translation: 'EGG_ON_TRIP.TRANSLATED.BE_CAREFUL', audio: '/assets/audio/be carefull.mp3', img: ''},
                    {key: 'to stop in a meadow', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STOP_IN_A_MEADOW', audio: '/assets/audio/to stop in a meadow.mp3', img: ''},
                    {key: 'to have a rest', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HAVE_A_REST', audio: '/assets/audio/to have a rest.mp3', img: ''},
                    {key: 'the wrong direction', translation: 'EGG_ON_TRIP.TRANSLATED.THE_WRONG_DIRECTION', audio: '/assets/audio/the wrong direction.mp3', img: ''}
                  ],
                  answers: ['to have a rest'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'The wrong direction',
                  image: '',
                  options: [
                    {key: 'to go for a trip', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_TRIP', audio: '/assets/audio/to go for a trip.mp3', img: ''},
                    {key: 'be careful', translation: 'EGG_ON_TRIP.TRANSLATED.BE_CAREFUL', audio: '/assets/audio/be carefull.mp3', img: ''},
                    {key: 'to stop in a meadow', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STOP_IN_A_MEADOW', audio: '/assets/audio/to stop in a meadow.mp3', img: ''},
                    {key: 'to have a rest', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HAVE_A_REST', audio: '/assets/audio/to have a rest.mp3', img: ''},
                    {key: 'the wrong direction', translation: 'EGG_ON_TRIP.TRANSLATED.THE_WRONG_DIRECTION', audio: '/assets/audio/the wrong direction.mp3', img: ''}
                  ],
                  answers: ['the wrong direction'],
                  correctAnswer: '',
                  audio: '',
              }
          ]
      };
    this.test = {
          name: '',
          description: '',
          type: 'questions',
          questions: [
              {
                  text: 'What colour is the daisy?',
                  image: '/assets/img/margareta.jpg',
                  options: [
                    {key: 'white', translation: 'EGG_ON_TRIP.WHITE', audio: '/assets/audio/white.mp3', img: ''},
                    {key: 'yellow', translation: 'EGG_ON_TRIP.YELLOW', audio: '/assets/audio/yellow.mp3', img: ''},
                    {key: 'blue', translation: 'EGG_ON_TRIP.BLUE', audio: '/assets/audio/blue.mp3', img: ''},
                    {key: 'green', translation: 'EGG_ON_TRIP.GREEN', audio: '/assets/audio/green.mp3', img: ''},
                    {key: 'grey', translation: 'EGG_ON_TRIP.GREY', audio: '/assets/audio/grey.mp3', img: ''},
                  ],
                  answers: ['white'],
                  correctAnswer: '',
                  audio: '/assets/audio/daisy.mp3',
              }, {
                  text: 'What colour is the sun?',
                  image: '/assets/img/slnko.jpg',
                  options: [
                    {key: 'white', translation: 'EGG_ON_TRIP.WHITE', audio: '/assets/audio/white.mp3', img: ''},
                    {key: 'yellow', translation: 'EGG_ON_TRIP.YELLOW', audio: '/assets/audio/yellow.mp3', img: ''},
                    {key: 'blue', translation: 'EGG_ON_TRIP.BLUE', audio: '/assets/audio/blue.mp3', img: ''},
                    {key: 'green', translation: 'EGG_ON_TRIP.GREEN', audio: '/assets/audio/green.mp3', img: ''},
                    {key: 'grey', translation: 'EGG_ON_TRIP.GREY', audio: '/assets/audio/grey.mp3', img: ''},
                  ],
                  answers: ['yellow'],
                  correctAnswer: '',
                  audio: '/assets/audio/sun.mp3',
              }, {
                  text: 'What colour is the sky?',
                  image: '/assets/img/obloha.jpg',
                  options: [
                    {key: 'white', translation: 'EGG_ON_TRIP.WHITE', audio: '/assets/audio/white.mp3', img: ''},
                    {key: 'yellow', translation: 'EGG_ON_TRIP.YELLOW', audio: '/assets/audio/yellow.mp3', img: ''},
                    {key: 'blue', translation: 'EGG_ON_TRIP.BLUE', audio: '/assets/audio/blue.mp3', img: ''},
                    {key: 'green', translation: 'EGG_ON_TRIP.GREEN', audio: '/assets/audio/green.mp3', img: ''},
                    {key: 'grey', translation: 'EGG_ON_TRIP.GREY', audio: '/assets/audio/grey.mp3', img: ''},
                  ],
                  answers: ['blue'],
                  correctAnswer: '',
                  audio: '/assets/audio/sky.mp3',
              }, {
                  text: 'What colour is the grass?',
                  image: '/assets/img/trava.jpg',
                  options: [
                    {key: 'white', translation: 'EGG_ON_TRIP.WHITE', audio: '/assets/audio/white.mp3', img: ''},
                    {key: 'yellow', translation: 'EGG_ON_TRIP.YELLOW', audio: '/assets/audio/yellow.mp3', img: ''},
                    {key: 'blue', translation: 'EGG_ON_TRIP.BLUE', audio: '/assets/audio/blue.mp3', img: ''},
                    {key: 'green', translation: 'EGG_ON_TRIP.GREEN', audio: '/assets/audio/green.mp3', img: ''},
                    {key: 'grey', translation: 'EGG_ON_TRIP.GREY', audio: '/assets/audio/grey.mp3', img: ''},
                  ],
                  answers: ['green'],
                  correctAnswer: '',
                  audio: '/assets/audio/grass.mp3',
              }, {
                  text: 'What colour is the donkey?',
                  image: '/assets/img/somar.png',
                  options: [
                      {key: 'white', translation: 'EGG_ON_TRIP.WHITE', audio: '/assets/audio/white.mp3', img: ''},
                      {key: 'yellow', translation: 'EGG_ON_TRIP.YELLOW', audio: '/assets/audio/yellow.mp3', img: ''},
                      {key: 'blue', translation: 'EGG_ON_TRIP.BLUE', audio: '/assets/audio/blue.mp3', img: ''},
                      {key: 'green', translation: 'EGG_ON_TRIP.GREEN', audio: '/assets/audio/green.mp3', img: ''},
                      {key: 'grey', translation: 'EGG_ON_TRIP.GREY', audio: '/assets/audio/grey.mp3', img: ''},
                  ],
                  answers: ['grey'],
                  correctAnswer: '',
                  audio: '/assets/audio/a donkey.mp3',
              }
          ]
      };
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_1_1', audio: ''},
    ];
    this.info2 = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_1_2', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka1.mp4', id: '462662022'};
    this.video2 = {src: '/assets/video/Egg On Trip_V5_1cast.mp4' , id: '462788540'};
    this.video3 = {src: '/assets/video/1_cvicenie_05_V1.mp4', id: '462657771'};
  }

    ngOnInit(): void {
        this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    }

    ngAfterViewInit(): void{
      // this.state.tabIndex = 0;
    }

    public onTestStateChange($event){
      this.state.isDone = $event;
    }

    public onVideoPlayed($event){
        this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    }

    public onVocabularyStateChange($event){
        this.showNextTab = true;
    }

    public onTimestampChange($event){
      this.timestampKey = $event;
    }

    nextTab(){
        this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
        this.showNextTab = null;
    }

    public next() {
        this.storyService.setPartAsDone();
    }

}
