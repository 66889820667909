import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { HrdinoviaService } from '../hrdinovia.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part3',
  templateUrl: './part3.component.html',
  styleUrls: ['./part3.component.css']
})
export class Part3Component implements OnInit {
  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;
  private correctAnswerKeyArray = [1, 2, 3];
  private answeredKeyArray = [];

  constructor(
    private storyService: StoryService,
    private hrdinoviaService: HrdinoviaService,
    private audioService: AudioService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: 'HRDINOVIA_3.mp4', id: '611060359'};
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  onWrongAnswer(){
    this.hrdinoviaService.onWrongAnswer();
  }

  onCorrectAnswer(){
    this.hrdinoviaService.onCorrectAnswer();
    setTimeout(() => { this.next(); }, 3000);
  }

  public isAnswered(key){
    return this.correctAnswerKeyArray.includes(key) &&  this.answeredKeyArray.includes(key);
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [1];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.audioService.playAudio('/assets/audio/hrdinovia/otazka_3.mp3');
    }
  }

}
