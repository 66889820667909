<div>
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed()" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="THANKS" [disabled]="false">
      <div class="with-background">
        <div class="text-center">
          <h1>Thanks for watching our video, we hoped you enjoyed watching it as much as we enjoyed making it for you! Let’s have some more fun now by practising English together!</h1>
          <button mat-raised-button color="accent" (click)="next()">NEXT</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button mat-raised-button color="accent" (click)="nextTab()" >NEXT</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button mat-raised-button color="accent" (click)="next()" >NEXT</button>
</div>
