import { Component, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { StoryService } from '../services/story.service';
import { Subscription } from 'rxjs';
import { StateService } from '../services/state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.css']
})
export class StoriesComponent implements OnInit{
  verificationObtained: Subscription;
  public errorText = '';
  private renderer: Renderer2;
  public tokenFormControl = new FormControl('', [Validators.required]);

  constructor(
    private router: Router,
    private storyService: StoryService,
    private stateService: StateService,
    public translate: TranslateService,
    private rendererFactory: RendererFactory2,
  ){
    this.renderer = rendererFactory.createRenderer(null, null);
    this.verificationObtained = this.storyService.verificationChanged.subscribe(result => {
      if (result['status'] === true){
        const userType = result['data']['type'];
        const ticket = result['data']['ticket'];
        const show = result['data']['show'];
        const firstPart = this.storyService.getStoryConfig(show).parts[0].key;
        if (ticket && (!this.isEmpty(ticket.name) || !this.isEmpty(ticket.name))){
          this.router.navigate(['/stories', show, firstPart]);
          this.router.navigate(['/profile']);
        }else if (userType === 'teacher'){
          this.renderer.addClass(document.body, 'teacher-access');
          this.router.navigate(['/profile']);
        }
      }else{
        if (result['message'] === 'EXPIRED'){
          this.errorText = 'INTERFACE.CODE_EXPIRED';
        }else{
          this.errorText = 'INTERFACE.WRONG_CODE';
        }
      }
    });
    this.tokenFormControl.valueChanges.subscribe(val => {
      this.errorText = '';
    });
  }

  ngOnInit(){
  }

  onSubmitForm(){
    this.storyService.verifiCode(this.tokenFormControl.value);
  }

  public isEmpty(str){
    return (!str || 0 === str.length);
  }
}
