import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-eight',
  templateUrl: './part-eight.component.html',
  styleUrls: ['./part-eight.component.css']
})
export class PartEightComponent implements OnInit {
  public test: Test;
  public vocabulary: Test;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
    private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.test = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Try to fall asleep while .... to music.',
          image: '',
          options: [
            {key: 'to listen', translation: 'to listen', audio: '', img: ''},
            {key: 'listening', translation: 'listening', audio: '', img: ''},
            {key: 'are listening', translation: 'are listening', audio: '', img: ''},
          ],
          answers: ['listening'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Are you allowed to smoke .... the plane.',
          image: '',
          options: [
            {key: 'on', translation: 'on', audio: '', img: ''},
            {key: 'onto', translation: 'onto', audio: '', img: ''},
            {key: 'at', translation: 'at', audio: '', img: ''},
          ],
          answers: ['on'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'I would like to know everything .... you and your country.',
          image: '',
          options: [
            {key: 'of', translation: 'of', audio: '', img: ''},
            {key: 'about', translation: 'about', audio: '', img: ''},
            {key: 'on', translation: 'on', audio: '', img: ''},
          ],
          answers: ['about'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'And he’s completely .... his own.',
          image: '',
          options: [
            {key: 'in', translation: 'in', audio: '', img: ''},
            {key: 'on', translation: 'on', audio: '', img: ''},
            {key: 'at', translation: 'at', audio: '', img: ''},
          ],
          answers: ['on'],
          correctAnswer : '',
          audio: '',
        }, {
          text: 'I .... a photo for my Mum on the Empire State Building and XXX ages queuing up for it.',
          image: '',
          options: [
            {key: 'am taking', translation: 'am taking', audio: '', img: ''},
            {key: 'took', translation: 'took', audio: '', img: ''},
            {key: 'was taken', translation: 'was taken', audio: '', img: ''},
          ],
          answers: ['took'],
          correctAnswer : '',
          audio: '',
        }, {
          text: 'I took a photo for my Mum on the Empire State Building and .... ages queuing up for it.',
          image: '',
          options: [
            {key: 'spended', translation: 'spended', audio: '', img: ''},
            {key: 'spent', translation: 'spent', audio: '', img: ''},
            {key: 'have spent', translation: 'have spent', audio: '', img: ''},
          ],
          answers: ['spent'],
          correctAnswer : '',
          audio: '',
        },
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_ZS/zadanie_8.mp3');
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

}
