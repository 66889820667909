import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { Test } from '../../../models/test.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {

  public state = null;
  public showNextTab = null;
  public info = null;
  public info2 = null;
  public video = null;
  public timestamps = [];
  public timestampKey = null;
  public video2 = null;
  public video3 = null;
  public test: Test = null;

  constructor(
      private storyService: StoryService,
      private translate: TranslateService,
  ) {
    this.state = {
      tabIndex: 0,
      isVocabularyDone: false,
      isDone: false,
    };
    this.timestamps = [[10, 18], [20, 28], [41, 63]];
    switch (this.storyService.state.lang) {
      case 'sk':
        this.video = {src: 'EGG_ON_TRIP_intro_2cvicenia_SK', id: '463003327'};
        break;
      case 'cz':
        this.video = {src: 'EGG_ON_TRIP_intro_2cvicenia_CZ', id: '463002092'};
        break;
      case 'pl':
        this.video = {src: 'EGG_ON_TRIP_intro_2cvicenia_PL', id: '463002798'};
    }
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_O_1', audio: ''},
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_O_2', audio: ''},
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_O_3', audio: ''},
    ];
    this.test = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Which of these is “a crayfish”?',
          image: '',
          options: [
            {key: 'crayfish', translation: 'EGG_ON_TRIP.A_CRAYFISH', audio: '/assets/audio/a crayfish.mp3', img: '/assets/img/crayfish.png'},
            {key: 'donkey', translation: 'EGG_ON_TRIP.A_DONKEY', audio: '/assets/audio/a donkey.mp3', img: '/assets/img/donkey.png'},
            {key: 'duck', translation: 'EGG_ON_TRIP.A_DUCK', audio: '/assets/audio/a duck.mp3', img: '/assets/img/duck.png'},
            {key: 'cow', translation: 'EGG_ON_TRIP.A_COW', audio: '/assets/audio/a cow.mp3', img: '/assets/img/cow.png'},
            {key: 'rooster', translation: 'EGG_ON_TRIP.A_ROOSTER',  audio: '/assets/audio/a rooster.mp3', img: '/assets/img/rooster.png'},
          ],
          answers: ['crayfish'],
          correctAnswer: '',
          audio: '',
        }
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onInfoStateChange($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.state.isDone = $event;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }
}
