import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StoriesComponent } from './stories/stories.component';
import { EggOnATripComponent } from './stories/egg-on-a-trip/egg-on-a-trip.component';
import { PartOneComponent } from './stories/egg-on-a-trip/part-one/part-one.component';
import { IntroComponent } from './stories/egg-on-a-trip/intro/intro.component';
import { PartTwoComponent } from './stories/egg-on-a-trip/part-two/part-two.component';
import { PartThreeComponent } from './stories/egg-on-a-trip/part-three/part-three.component';
import { PartFourComponent } from './stories/egg-on-a-trip/part-four/part-four.component';
import { PartFiveComponent } from './stories/egg-on-a-trip/part-five/part-five.component';
import { PartSixComponent } from './stories/egg-on-a-trip/part-six/part-six.component';
import { PartSevenComponent } from './stories/egg-on-a-trip/part-seven/part-seven.component';
import { FinishComponent } from './stories/egg-on-a-trip/finish/finish.component';
import { ExtraComponent } from './stories/egg-on-a-trip/extra/extra.component';
import { ProfileComponent } from './profile/profile.component';
import { TutorialComponent } from './stories/egg-on-a-trip/tutorial/tutorial.component';

const routes: Routes = [
  { path: '', component: StoriesComponent},
  { path: 'profile', component: ProfileComponent},
  { path: 'stories', component: StoriesComponent},
  { path: 'stories/egg-on-a-trip', component: EggOnATripComponent},
  { path: 'stories/egg-on-a-trip/tutorial', component: TutorialComponent},
  { path: 'stories/egg-on-a-trip/intro', component: IntroComponent},
  { path: 'stories/egg-on-a-trip/1', component: PartOneComponent},
  { path: 'stories/egg-on-a-trip/2', component: PartTwoComponent},
  { path: 'stories/egg-on-a-trip/3', component: PartThreeComponent},
  { path: 'stories/egg-on-a-trip/4', component: PartFourComponent},
  { path: 'stories/egg-on-a-trip/5', component: PartFiveComponent},
  { path: 'stories/egg-on-a-trip/6', component: PartSixComponent},
  { path: 'stories/egg-on-a-trip/7', component: PartSevenComponent},
  { path: 'stories/egg-on-a-trip/finish', component: FinishComponent},
  { path: 'stories/egg-on-a-trip/extra', component: ExtraComponent},
  { path: 'stories/american-dream-zs', loadChildren: './stories/american-dream/american-dream.module#AmericanDreamModule'},
  { path: 'stories/american-dream-ss', loadChildren: './stories/american-dream-ss/american-dream-ss.module#AmericanDreamSsModule'},
  { path: 'stories/november-89', loadChildren: './stories/november/november.module#NovemberModule'},
  { path: 'stories/mr-wolf', loadChildren: './stories/mr-wolf/mr-wolf.module#MrWolfModule'},
  { path: 'stories/hrdinovia', loadChildren: './stories/hrdinovia/hrdinovia.module#HrdinoviaModule'},
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
