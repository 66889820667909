import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {
  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
    public storyService: StoryService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: '11_nov89_v1.mp4', id: '477140229'};
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onInfoStateChange($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.state.isDone = $event;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  public onTabChange($event: MatTabChangeEvent){}
}
