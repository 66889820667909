import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss']
})
export class ExtraComponent implements OnInit {
  public downloadList;
  public vocabulary;
  constructor(
    public audioService: AudioService,
    public storyService: StoryService,
  ){
    this.downloadList = [
      {src: '/assets/downloads/hrdinovia/Hrdinovia kviz.pdf', lang: 'sk', translation: 'Hrdinovia kvíz', img: '/assets/img/hrdinovia/thumbnails/Hrdinovia kviz.png'},
      {src: '/assets/downloads/hrdinovia/Hrdinovia otazky na premyslanie.pdf', lang: 'sk', translation: 'Hrdinovia otázky na premýšľanie', img: '/assets/img/hrdinovia/thumbnails/Hrdinovia otazky na premyslanie.png'},
      {src: '/assets/downloads/hrdinovia/komix_ozivovanie.jpg', lang: 'sk', translation: 'Komix oživovanie', img: '/assets/img/hrdinovia/thumbnails/Komix_ozivovanie.png'},
      {src: '/assets/downloads/hrdinovia/Prva-pomoc-porezanie.pdf', lang: 'sk', translation: 'Prvá pomoc porezanie', img: '/assets/img/hrdinovia/thumbnails/Prva_pomoc_porezanie.png'},
      {src: '/assets/downloads/hrdinovia/Aktivita_Dobry pocit.pdf', lang: 'sk', translation: 'Aktivita Dobrý pocit', img: '/assets/img/hrdinovia/thumbnails/Aktivita_Dobry pocit.png'},
      {src: '/assets/downloads/hrdinovia/Aktivita_Ochrana slabsich.pdf', lang: 'sk', translation: 'Aktivita Ochrana slabších', img: '/assets/img/hrdinovia/thumbnails/Aktivita_ochrana slabsich.png'},
      {src: '/assets/downloads/hrdinovia/Aktivita_Ohrozenie zivota.pdf', lang: 'sk', translation: 'Aktivita Ohrozenie života', img: '/assets/img/hrdinovia/thumbnails/Aktivita_Ohrozenie zivota.png'},
      {src: '/assets/downloads/hrdinovia/Aktivita_Triedny hrdina kazdodennosti.pdf', lang: 'sk', translation: 'Aktivita Triedny hrdina', img: '/assets/img/hrdinovia/thumbnails/Aktivita_triedny hrdina.png'},
    ];
  }

  ngOnInit(): void {
  }

  backToContent(){
    this.storyService.goBackToTest();
  }
}
