import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-four',
  templateUrl: './part-four.component.html',
  styleUrls: ['./part-four.component.css']
})
export class PartFourComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public info = null;
  public info2 = null;
  public test: Test = null;
  public vocabulary: Test = null;
  public video;
  public video2;
  public video3;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
    private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.test = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'California', translation: 'San Francisco', audio: '', img: '/assets/img/american-dream/4_california.jpg'},
          {key: 'Texas', translation: 'Houston', audio: '', img: '/assets/img/american-dream/4_texas0.jpg'},
          {key: 'Nevada', translation: 'Las Vegas', audio: '', img: '/assets/img/american-dream/4_nevada.jpg'},
          {key: 'Florida', translation: 'Miami', audio: '', img: '/assets/img/american-dream/4_florida.jpg'},
          {key: 'Illinois', translation: 'Chicago', audio: '', img: '/assets/img/american-dream/4_illinois.jpg'},
        ],
        answers: [
          {key: 'California', translation: 'San Francisco', audio: '', img: '/assets/img/american-dream/4_california.jpg'},
          {key: 'Texas', translation: 'Houston', audio: '', img: '/assets/img/american-dream/4_texas0.jpg'},
          {key: 'Nevada', translation: 'Las Vegas', audio: '', img: '/assets/img/american-dream/4_nevada.jpg'},
          {key: 'Florida', translation: 'Miami', audio: '', img: '/assets/img/american-dream/4_florida.jpg'},
          {key: 'Illinois', translation: 'Chicago', audio: '', img: '/assets/img/american-dream/4_illinois.jpg'},
        ]
      }
    };
    this.info = [
      {translation: 'AMERICAN_DREAM.TRANSLATED.INFO_1_1', audio: ''},
    ];
    this.info2 = [
      {translation: 'AMERICAN_DREAM.TRANSLATED.INFO_1_2', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka1.mp4', id: '462662022'};
    this.video2 = {src: '/assets/video/Egg On Trip_V5_1cast.mp4' , id: '462788540'};
    this.video3 = {src: '/assets/video/1_cvicenie_05_V1.mp4', id: '462657771'};
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_SS/zadanie_4.mp3');
  }

  ngAfterViewInit(): void{
    // this.state.tabIndex = 0;
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }
}
