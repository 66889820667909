import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { HrdinoviaService } from '../hrdinovia.service';
import { AudioService } from '../../../services/audio.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-part6',
  templateUrl: './part7.component.html',
  styleUrls: ['./part7.component.css']
})
export class Part7Component implements OnInit {
  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private hrdinoviaService: HrdinoviaService,
    private audioService: AudioService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: 'HRDINOVIA_7.mp4', id: '611043398'};
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }
}
