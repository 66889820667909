import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-five',
  templateUrl: './part-five.component.html',
  styleUrls: ['./part-five.component.css']
})
export class PartFiveComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public test1: Test = null;
  public test2: Test = null;
  public test3: Test = null;
  public test4: Test = null;
  public test5: Test = null;
  public test6: Test = null;
  public video;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
    private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.test1 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'high school', translation: 'high school', audio: '/assets/audio/american-dream/AD_audio_ZS/5_highschool.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'secondary school', translation: 'secondary school', audio: '/assets/audio/american-dream/AD_audio_ZS/5_secondary school.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ],
        answers: [
          {key: 'high school', translation: 'high school', audio: '/assets/audio/american-dream/AD_audio_ZS/5_highschool.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'secondary school', translation: 'secondary school', audio: '/assets/audio/american-dream/AD_audio_ZS/5_secondary school.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ]
      }
    };
    this.test2 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'college', translation: 'college', audio: '/assets/audio/american-dream/AD_audio_ZS/5_college.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'university', translation: 'university', audio: '/assets/audio/american-dream/AD_audio_ZS/5_university.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ],
        answers: [
          {key: 'college', translation: 'college', audio: '/assets/audio/american-dream/AD_audio_ZS/5_college.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'university', translation: 'university', audio: '/assets/audio/american-dream/AD_audio_ZS/5_university.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ]
      }
    };
    this.test3 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'subway', translation: 'subway', audio: '/assets/audio/american-dream/AD_audio_ZS/5_subway.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'underground', translation: 'underground', audio: '/assets/audio/american-dream/AD_audio_ZS/5_underground.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ],
        answers: [
          {key: 'subway', translation: 'subway', audio: '/assets/audio/american-dream/AD_audio_ZS/5_subway.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'underground', translation: 'underground', audio: '/assets/audio/american-dream/AD_audio_ZS/5_underground.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ]
      }
    };
    this.test4 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'vacation', translation: 'vacation', audio: '/assets/audio/american-dream/AD_audio_ZS/5_vacation.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'holiday', translation: 'holiday', audio: '/assets/audio/american-dream/AD_audio_ZS/5_holiday.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ],
        answers: [
          {key: 'vacation', translation: 'vacation', audio: '/assets/audio/american-dream/AD_audio_ZS/5_vacation.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'holiday', translation: 'holiday', audio: '/assets/audio/american-dream/AD_audio_ZS/5_holiday.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ]
      }
    };
    this.test5 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'soccer', translation: 'soccer', audio: '/assets/audio/american-dream/AD_audio_ZS/5_soccer.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'football', translation: 'football', audio: '/assets/audio/american-dream/AD_audio_ZS/5_footbal.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ],
        answers: [
          {key: 'soccer', translation: 'soccer', audio: '/assets/audio/american-dream/AD_audio_ZS/5_soccer.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'football', translation: 'football', audio: '/assets/audio/american-dream/AD_audio_ZS/5_footbal.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ]
      }
    };
    this.test6 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'schedule', translation: 'schedule', audio: '/assets/audio/american-dream/AD_audio_ZS/5_schedule.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'timetable', translation: 'timetable', audio: '/assets/audio/american-dream/AD_audio_ZS/5_timetable.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ],
        answers: [
          {key: 'schedule', translation: 'schedule', audio: '/assets/audio/american-dream/AD_audio_ZS/5_schedule.mp3', img: '/assets/img/american-dream/5_homer.jpg'},
          {key: 'timetable', translation: 'timetable', audio: '/assets/audio/american-dream/AD_audio_ZS/5_timetable.mp3', img: '/assets/img/american-dream/5_kralovna_elisabeth2.jpg'},
        ]
      }
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_ZS/zadanie_5.mp3');
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
    this.next();
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }
}
