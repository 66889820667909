import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { StoriesModule } from './stories/stories.module';
import { EggOnATripModule } from './stories/egg-on-a-trip/egg-on-a-trip.module';
import { AudioService } from './services/audio.service';
import { StoryService } from './services/story.service';
import { HttpService } from './services/http.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AmericanDreamModule } from './stories/american-dream/american-dream.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmericanDreamSsModule } from './stories/american-dream-ss/american-dream-ss.module';
import { NovemberModule } from './stories/november/november.module';
import { NovemberService } from './stories/november/november.service';
import { ProfileModule } from './profile/profile.module';
import { MrWolfModule } from './stories/mr-wolf/mr-wolf.module';
import { HrdinoviaModule } from './stories/hrdinovia/hrdinovia.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoriesModule,
    EggOnATripModule,
    AmericanDreamModule,
    AmericanDreamSsModule,
    NovemberModule,
    MrWolfModule,
    ProfileModule,
    HrdinoviaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'sk',
    })
  ],
  providers: [
    AudioService,
    StoryService,
    HttpService,
    NovemberService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
