import { Injectable} from '@angular/core';
import { AudioService } from '../../services/audio.service';
import { StateService } from '../../services/state.service';
import { StoryService } from '../../services/story.service';

@Injectable({
  providedIn: 'root'
})
export class MrWolfService{

  constructor(
    protected stateService: StateService,
    protected storyService: StoryService,
    private audioService: AudioService,
  ){}

  /**
   * Plays sound and add points to storyService
   * @param  customAudio - filename of audiofile.
   */
  onCorrectAnswer(customAudio: string[] = null){
    let audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.correct;
    this.storyService.addPoints(3);
    if (customAudio) {
      audio =  customAudio;
    }
    this.audioService.playRandomAudio(audio);
  }
  /**
   * Plays sound and remove points from storyService
   * @param customAudio - filename of audiofile.
   */
  onWrongAnswer(customAudio: string[] = null){
    let audio = this.storyService.getStoryConfig(this.storyService.state.active_story).audio.wrong;
    if (customAudio) {
      audio =  customAudio;
    }
    this.storyService.addPoints(-1);
    this.audioService.playRandomAudio(audio);
  }
}
