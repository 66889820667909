<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO 1">
      <app-video [id]="video1.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 0" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="TASK 1">
      <div class="with-background with-background--superman">
        <div class="text-center">
          <h1>Were you paying attention? Choose the right answer.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 1">
      <app-test [test]="test1" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 2" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="VIDEO 2">
      <app-video [id]="video2.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 3" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="TASK 2">
      <div class="with-background with-background--superman">
        <div class="text-center">
          <h1>Were you paying attention? Choose the right answer.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 2">
      <app-test [test]="test2" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 5" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="VIDEO 3">
      <app-video [id]="video3.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 6" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="TASK 3">
      <div class="with-background with-background--superman">
        <div class="text-center">
          <h1>Were you paying attention? Choose the right answer.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 3">
      <app-test [test]="test3" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 8" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="VIDEO 4">
      <app-video [id]="video4.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 9" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="TASK 4">
      <div class="with-background with-background--superman">
        <div class="text-center">
          <h1>Were you paying attention? Choose the right answer.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 4">
      <app-test [test]="test4" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 11" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="VIDEO 5">
      <app-video [id]="video5.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 12" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="TASK 5">
      <div class="with-background with-background--superman">
        <div class="text-center">
          <h1>Were you paying attention? Choose the right answer.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 5">
      <app-test [test]="test5" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 14" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="VIDEO 6">
      <app-video [id]="video6.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 15" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="TASK 6">
      <div class="with-background with-background--superman">
        <div class="text-center">
          <h1>Were you paying attention? Choose the right answer.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 6">
      <app-test [test]="test6" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 17" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="VIDEO 7">
      <app-video [id]="video7.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 18" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="TASK 7">
      <div class="with-background with-background--superman">
        <div class="text-center">
          <h1>Were you paying attention? Choose the right answer.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 7">
      <app-test [test]="test7" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 20" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="VIDEO 8">
      <app-video [id]="video8.id" (onDone)="onLastVideoPlayed($event)" [endImmediately]="false" *ngIf="state.tabIndex === 21" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
  <button class="with-image" (click)="next()" ><img src="/assets/img/american-dream/next.png"></button>
</div>

