<div *ngIf="true" class="white-bcg allow-to-all">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="EXERCISES">
      <div class="text-center">
        <table style="width:100%">
          <tbody>
          <ng-container *ngFor="let download of downloadList">
            <tr *ngIf="storyService.state.lang === download.lang">
              <td style="text-align: center">
                <a [href]="download.src" target="_blank" style="border-bottom: none">
                  <img [src]="download.img" style="height: 200px;">
                  <br>
                  <span [translate]="download.translation"></span>
                </a>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>





