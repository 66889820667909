<div *ngIf="true">
<mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="VIDEO" [disabled]="false">
            <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true" [timestamps]="timestamps" (onTimestamp)="onTimestampChange($event)"></app-video>
            <app-info [content]="info[timestampKey]"></app-info>
    </mat-tab>
    <mat-tab label="VOCABULARY" >
        <app-test [test]="test" [showOptionAs]="'image'" [makeItCount]="true" [isDone]="state.isDone" (onDone)="onVocabularyStateChange($event)" *ngIf="state.tabIndex === 1"></app-test>
    </mat-tab>
</mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
    <h2 translate>INTERFACE.WELL_DONE</h2>
    <button mat-raised-button color="accent" (click)="nextTab()" ><span translate>INTERFACE.NEXT</span></button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
    <h2 translate>INTERFACE.WELL_DONE</h2>
    <button mat-raised-button color="accent" (click)="next()" ><span translate>INTERFACE.NEXT</span></button>
</div>

