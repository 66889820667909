import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
    public storyService: StoryService
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: 'AM_DREAM9_verziaAM_DREAM_verzia1.mp4', id: '476232289'};
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }
}
