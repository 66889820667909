import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-american-dream-video',
  templateUrl: './american-dream-video.component.html',
  styleUrls: ['./american-dream-video.component.css']
})
export class AmericanDreamVideoComponent implements OnInit {

  public state = null;
  public showNextTab = null;
  public test1: Test;
  public test2: Test;
  public test3: Test;
  public test4: Test;
  public test5: Test;
  public test6: Test;
  public test7: Test;
  public test8: Test;
  public video1;
  public video2;
  public video3;
  public video4;
  public video5;
  public video6;
  public video7;
  public video8;
  public video9;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video1 = {src: 'AM_DREAM1_verziaAM_DREAM_verzia1.mp4', id: '476225902'};
    this.video2 = {src: 'AM_DREAM2_verziaAM_DREAM_verzia1.mp4', id: '476227031'};
    this.video3 = {src: 'AM_DREAM3_verziaAM_DREAM_verzia1.mp4', id: '476227909'};
    this.video4 = {src: 'AM_DREAM4_verziaAM_DREAM_verzia1.mp4', id: '476228715'};
    this.video5 = {src: 'AM_DREAM5_verziaAM_DREAM_verzia1.mp4', id: '476229379'};
    this.video6 = {src: 'AM_DREAM6_verziaAM_DREAM_verzia1.mp4', id: '476229907'};
    this.video7 = {src: 'AM_DREAM7_verziaAM_DREAM_verzia1.mp4', id: '476230869'};
    this.video8 = {src: 'AM_DREAM8_verziaAM_DREAM_verzia1.mp4', id: '476231354'};
    this.video9 = {src: 'AM_DREAM9_verziaAM_DREAM_verzia1.mp4', id: '476232289'};
    this.test1 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'What is Otto’s teacher’s dream?',
          image: '',
          options: [
            {key: 'studying English literature in America', translation: 'studying English literature in America', audio: '', img: ''},
            {key: 'travelling around the world', translation: 'travelling around the world', audio: '', img: ''},
            {key: 'becoming a doctor at Harvard', translation: 'becoming a doctor at Harvard', audio: '', img: ''},
          ],
          answers: ['studying English literature in America'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_1.mp3',
        }
      ]
    };
    this.test2 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Why is Otto’s suitcase not very heavy?',
          image: '',
          options: [
            {key: 'he’s wearing half of it', translation: 'he’s wearing half of it', audio: '', img: ''},
            {key: 'he forgot to pack his clothes', translation: 'he forgot to pack his clothes', audio: '', img: ''},
            {key: 'his mother replaced his clothes with a photograph of herself', translation: 'his mother replaced his clothes with a photograph of herself', audio: '', img: ''},
          ],
          answers: ['he’s wearing half of it'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_2.mp3',
        }
      ]
    };
    this.test3 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Why did the police woman arrest Otto?',
          image: '',
          options: [
            {key: 'it’s her first day at work and she wanted to practice arresting someone', translation: 'it’s her first day at work and she wanted to practice arresting someone', audio: '', img: ''},
            {key: 'Otto was spraying graffiti on the wall', translation: 'Otto was spraying graffiti on the wall', audio: '', img: ''},
            {key: 'she thought he was hiding a bomb under his jacket', translation: 'she thought he was hiding a bomb under his jacket', audio: '', img: ''},
          ],
          answers: ['it’s her first day at work and she wanted to practice arresting someone'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_3.mp3',
        }
      ]
    };
    this.test4 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Why didn’t Donald want Otto’s money?',
          image: '',
          options: [
            {key: 'he has enough money of his own', translation: 'he has enough money of his own', audio: '', img: ''},
            {key: 'he thought it was a fake banknote', translation: 'he thought it was a fake banknote', audio: '', img: ''},
            {key: 'he only accepts credit cards', translation: 'he only accepts credit cards', audio: '', img: ''},
          ],
          answers: ['he has enough money of his own'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_4.mp3',
        }
      ]
    };
    this.test5 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'How would you describe Otto’s new family?',
          image: '',
          options: [
            {key: 'Christian and conservative', translation: 'Christian and conservative', audio: '', img: ''},
            {key: 'liberal and easy-going', translation: 'liberal and easy-going', audio: '', img: ''},
            {key: 'partying hippies', translation: 'partying hippies', audio: '', img: ''},
          ],
          answers: ['Christian and conservative'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_5.mp3',
        }
      ]
    };
    this.test6 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'What surprises Otto most about his new school friends?',
          image: '',
          options: [
            {key: 'that they spend time doing voluntary work', translation: 'that they spend time doing voluntary work', audio: '', img: ''},
            {key: 'that they are interested in where he comes from', translation: 'that they are interested in where he comes from', audio: '', img: ''},
            {key: 'that they don’t have any plans for the future', translation: 'that they don’t have any plans for the future', audio: '', img: ''},
          ],
          answers: ['that they spend time doing voluntary work'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_6.mp3',
        }
      ]
    };
    this.test7 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Why didn’t Bart carry on painting pictures?',
          image: '',
          options: [
            {key: 'he didn’t think he was good enough', translation: 'he didn’t think he was good enough', audio: '', img: ''},
            {key: 'he ran out of paint', translation: 'he ran out of paint', audio: '', img: ''},
            {key: 'he lost his inspiration', translation: 'he lost his inspiration', audio: '', img: ''},
          ],
          answers: ['he didn’t think he was good enough'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_7.mp3',
        }
      ]
    };
    this.test8 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'What does Otto want to do while he is in America?',
          image: '',
          options: [
            {key: 'enrol in art classes', translation: 'enrol in art classes', audio: '', img: ''},
            {key: 'improve his football technique', translation: 'improve his football technique', audio: '', img: ''},
            {key: 'get rich quick', translation: 'get rich quick', audio: '', img: ''},
          ],
          answers: ['enrol in art classes'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/0_8.mp3',
        }
      ]
    };

  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }


  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  onPlayAttention(){
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_SS/zadanie_0_Where you paying attentione_.mp3');
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [1, 4, 7, 10, 13, 16, 19];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.onPlayAttention();
    }
  }

}
