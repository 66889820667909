// Load html in Angular 2

// In content.html use:
// <div [innerHTML]="post.content.rendered | safeHtml " class="entry-body"></div>

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
