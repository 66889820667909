<div *ngIf="true">
<mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="VIDEO 1">
        <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 0" (onTimestamp)="onTimestampChange($event)"></app-video>
        <app-info [content]="info[timestampKey]"></app-info>
    </mat-tab>
    <mat-tab label="VOCABULARY">
        <app-test [test]="vocabulary" [readRightOptionBefore]="true" [allowRepeat]="true" [showOptionAs]="'text'" [isDone]="state.isDone" (onDone)="onVocabularyStateChange($event)" *ngIf="state.tabIndex === 1"></app-test>
    </mat-tab>
    <mat-tab label="VIDEO MAIN" [disabled]="false">
        <app-video [id]="video2.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 2" (onTimestamp)="onTimestampChange($event)"></app-video>
    </mat-tab>
    <mat-tab label="VIDEO 3">
        <app-video [id]="video3.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 3" (onTimestamp)="onTimestampChange($event)"></app-video>
        <app-info [content]="info3[timestampKey]"></app-info>
    </mat-tab>
    <mat-tab label="VIDEO 4">
        <app-video [id]="video4.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 4" (onTimestamp)="onTimestampChange($event)"></app-video>
        <app-info [content]="info4[timestampKey]"></app-info>
    </mat-tab>
    <mat-tab label="ANAGRAMS" [disabled]="false">
        <app-test [test]="test" [makeItCount]="true" [isDone]="state.isDone" (onDone)="onTestStateChange($event)" *ngIf="state.tabIndex === 5"></app-test>
    </mat-tab>
</mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
    <h2 translate>INTERFACE.WELL_DONE</h2>
    <button mat-raised-button color="accent" (click)="nextTab()" ><span translate>INTERFACE.NEXT</span></button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
    <h2 translate>INTERFACE.WELL_DONE</h2>
    <button mat-raised-button color="accent" (click)="next()" ><span translate>INTERFACE.NEXT</span></button>
</div>

