import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { NovemberService } from '../november.service';
import { AudioService } from '../../../services/audio.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-part9',
  templateUrl: './part9.component.html',
  styleUrls: ['./part9.component.css']
})
export class Part9Component implements OnInit {
  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private novemberService: NovemberService,
    private audioService: AudioService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: '09_nov89_v1.mp4', id: '477136205'};
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onInfoStateChange($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  onWrongAnswer(){
    this.novemberService.onWrongAnswer();
  }

  onCorrectAnswer(){
    this.novemberService.onCorrectAnswer();
    setTimeout(() => { this.nextTab(); }, 3000);
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [1];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.audioService.playAudio('/assets/audio/november-89/N89_9A.mp3');
    }
  }
}
