<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="TEXT" [disabled]="false">
      <div class="player-wrapper">
        <ngx-circular-player radius="80" source="/assets/audio/november-89/N89_10.mp3"></ngx-circular-player>
      </div>
      <div class="with-background with-background--paper">
        <div class="text-center">
          <p>Podľa zákona číslo 119/1990 bolo súdne rehabilitovaných 260-tisíc obetí totalitného režimu. Historici odhadujú, že represálie sa dotkli viac ako dvoch miliónov ľudí a približne pol milióna rodín. Dokazovanie zločinov je v tomto prípade veľmi zložité. Počet rehabilitovaných tvorí iba zlomok z počtu skutočných obetí. Drvivá väčšina z nich nebude nikdy odškodnená.</p>
          <p>Počet rehabilitovaných obetí komunistického režimu v ČSSR: 260 000</p>
          <p>Počet ľudí postihnutých politickým represáliami v rokoch 1948-1989: 2 000 000</p>
          <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
