import { NgModule } from '@angular/core';
import { AmericanDreamSsComponent } from './american-dream-ss.component';
import { SharedModule } from '../../shared/shared.module';
import { AmericanDreamRoutingModule } from './american-dream-ss-routing.module';
import { IntroComponent } from './intro/intro.component';
import { PartEightComponent } from './part-eight/part-eight.component';
import { PartFiveComponent } from './part-five/part-five.component';
import { PartFourComponent } from './part-four/part-four.component';
import { PartOneComponent } from './part-one/part-one.component';
import { PartSevenComponent } from './part-seven/part-seven.component';
import { PartSixComponent } from './part-six/part-six.component';
import { PartThreeComponent } from './part-three/part-three.component';
import { PartTwoComponent } from './part-two/part-two.component';
import { AmericanDreamVideoComponent } from './american-dream-video/american-dream-video.component';
import { NgxCircularPlayerModule } from 'ngx-circular-player';
import { ExtraComponent } from './extra/extra.component';
import { FinishComponent } from './finish/finish.component';

@NgModule({
  declarations: [
    AmericanDreamSsComponent,
    IntroComponent,
    PartOneComponent,
    PartTwoComponent,
    PartThreeComponent,
    PartFourComponent,
    PartFiveComponent,
    PartSixComponent,
    PartSevenComponent,
    PartEightComponent,
    AmericanDreamVideoComponent,
    ExtraComponent,
    FinishComponent,
  ],
  imports: [
    SharedModule,
    AmericanDreamRoutingModule,
    NgxCircularPlayerModule,
  ]
})
export class AmericanDreamSsModule { }
