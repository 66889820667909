<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onLastVideoPlayed($event)" [endImmediately]="true" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button mat-raised-button color="accent" (click)="nextTab()" ><span translate>INTERFACE.NEXT</span></button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button mat-raised-button color="accent" (click)="next()" ><span translate>INTERFACE.NEXT</span></button>
</div>



