import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-two',
  templateUrl: './part-two.component.html',
  styleUrls: ['./part-two.component.css']
})
export class PartTwoComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public info = null;
  public info2 = null;
  public test: Test = null;
  public vocabulary: Test = null;
  public video;
  public video2;
  public video3;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
    private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.test = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'What doesn’t belong on a plane?',
          image: '/assets/img/american-dream/2_v_lietadle.jpg',
          options: [
            {key: 'carry-on luggage', translation: 'carry-on luggage', audio: '', img: ''},
            {key: 'overhead bin', translation: 'overhead bin', audio: '', img: ''},
            {key: 'flight attendant', translation: 'flight attendant', audio: '', img: ''},
            {key: 'seat belt', translation: 'seat belt', audio: '', img: ''},
            {key: 'boarding card', translation: 'boarding card', audio: '', img: ''},
            {key: 'take-off luggage', translation: 'take-off luggage', audio: '', img: ''},
          ],
          answers: ['take-off luggage'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/2_1.mp3',
        }, {
          text: 'Which expression doesn’t refer to travelling?',
          image: '/assets/img/american-dream/2_travelling.jpg',
          options: [
            {key: 'package holiday', translation: 'package holiday', audio: '', img: ''},
            {key: 'seaside holiday', translation: 'seaside holiday', audio: '', img: ''},
            {key: 'city break', translation: 'city break', audio: '', img: ''},
            {key: 'tour holiday', translation: 'tour holiday', audio: '', img: ''},
            {key: 'student exchange', translation: 'student exchange', audio: '', img: ''},
            {key: 'prison break', translation: 'prison break', audio: '', img: ''},
          ],
          answers: ['prison break'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/2_2.mp3',
        }
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_SS/zadanie_2.mp3');
  }


  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }
}
