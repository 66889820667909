import { Component, OnInit } from '@angular/core';
import { StateService } from '../services/state.service';
import { StoryService } from '../services/story.service';
import { HttpService } from '../services/http.service';
import { environment } from '../../environments/environment';
import { LogoutModalComponent } from '../shared/logout-modal/logout-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AddClassComponent } from '../shared/add-class-modal/add-class-modal.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit{
  public  state: any = null;
  public  story: any = null;
  public  order: any;
  public  classList: any;
  public  selectedClass = '';

  constructor(
    private stateService: StateService,
    public storyService: StoryService,
    private http: HttpService,
    public dialog: MatDialog,
  ){
  }

  ngOnInit(): void{
    this.state = this.stateService.getState();
    this.story = this.storyService.getStoryConfig(this.state.active_story);
    if (this.state.type === 'teacher'){
      this.http.get(environment.apiUrl + '/api/online/order-detail/' + this.state.token).subscribe((result: any) => {
        this.order = result.data;
        this.classList = this.getClassesArray(this.order.ticketList);
      });
    }
  }

  convertStringToJson( input: string, key: string){
    if (input && input !== ''){
      return JSON.parse(input)[key];
    }
    return '';
  }

  getActivePart( input: any){
    const activePart = this.convertStringToJson(input.meta, 'active_part').key;
    if (input && activePart){
      if (activePart === 'intro' || activePart === 'finish' || activePart === 'tutorial'){
        return activePart;
      }else{
        return activePart + '/' + (this.story.parts.length - 2);
      }
    } else {
      return '--';
    }
  }

  backToContent(){
    this.storyService.goBackToTest();
  }

  getTimeDiff(ticket){
    if (ticket.finished && ticket.started){
      const diff = Math.abs(new Date(ticket.started).getTime() - new Date(ticket.finished).getTime());
      return Math.floor((diff / 1000) / 60);
    }
    return '--';
  }

  public getClassesArray(ticketList: any[]): string[]{
    const classList = [];
    ticketList.forEach(value => {
      classList.push(value.class);
    });
    return [...new Set(classList)].sort();
  }

  public selectClass(selectedClass: any): void{
    this.selectedClass = selectedClass.value;
  }

  public openAddClassDialog(){
    this.dialog.open(AddClassComponent,{ width: '600px'});
  }

}
