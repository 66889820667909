<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="TEXT" [disabled]="false">
      <div class="player-wrapper">
        <ngx-circular-player radius="80"  source="/assets/audio/november-89/N89_3A.mp3"></ngx-circular-player>
      </div>
      <div class="with-background with-background--paper">
        <div class="text-center">
          <p>Od nástupu komunistov k moci až do roku 1989 fungovala prísna cenzúra médií ako aj verejne prezentovaných umeleckých diel. Po vpáde vojsk Varšavskej zmluvy do Československa v roku 1968 cenzúra pritvrdila. Bol zriadený Úrad pre tlač a informácie, cenzori schvaľovali všetky odvysielané televízne a rozhlasové programy, boli dokonca prítomní aj na priamych prenosoch estrád či diskusných programov. Schvaľovali sa ale aj knihy, piesňové texty, divadelné hry, filmové scenáre.</p>
          <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper">
        <div class="text-center">
          <h1>Prečo bola západná hudba nebezpečná?</h1>
          <ul>
            <li><a (click)="onCorrectAnswer(1)" [ngClass]="{'done': isAnswered(1)}">a) bola príliš veselá</a></li>
            <li><a (click)="onCorrectAnswer(2)" [ngClass]="{'done': isAnswered(2)}">b) texty v nej propagovali ideály slobody</a></li>
            <li><a (click)="onCorrectAnswer(3)" [ngClass]="{'done': isAnswered(3)}">c) nevychádzala z našich folklórnych tradícií</a></li>
            <li><a (click)="onWrongAnswer()">d) vychádzala na nosičoch, ktoré sa u nás nedali prehrať</a></li>
          </ul>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
