import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { AmericanDreamComponent } from './american-dream.component';
import { PartOneComponent } from './part-one/part-one.component';
import { PartTwoComponent } from './part-two/part-two.component';
import { PartThreeComponent } from './part-three/part-three.component';
import { PartFourComponent } from './part-four/part-four.component';
import { PartFiveComponent } from './part-five/part-five.component';
import { PartSixComponent } from './part-six/part-six.component';
import { PartSevenComponent } from './part-seven/part-seven.component';
import { PartEightComponent } from './part-eight/part-eight.component';
import { AmericanDreamVideoComponent } from './american-dream-video/american-dream-video.component';
import { ExtraComponent } from './extra/extra.component';
import { FinishComponent } from './finish/finish.component';

const americaDreamRoutes: Routes = [
  {path: '', component: AmericanDreamComponent, children: [
      {path: '', redirectTo: 'intro', pathMatch: 'full'},
      {path: 'intro', component : IntroComponent},
      {path: 'video', component : AmericanDreamVideoComponent},
      {path: '1', component : PartOneComponent},
      {path: '2', component : PartTwoComponent},
      {path: '3', component : PartThreeComponent},
      {path: '4', component : PartFourComponent},
      {path: '5', component : PartFiveComponent},
      {path: '6', component : PartSixComponent},
      {path: '7', component : PartSevenComponent},
      {path: '8', component : PartEightComponent},
      {path: 'finish', component : FinishComponent},
      {path: 'extra', component : ExtraComponent},
    ] },
];

@NgModule({
  imports: [
    RouterModule.forChild(americaDreamRoutes)
  ],
  exports: [RouterModule]
})
export class AmericanDreamRoutingModule { }
