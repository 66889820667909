<div *ngIf="true">
  <app-progress [step]="5" [length]="8"></app-progress>
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="TASK">
      <div class="with-background with-background--shelves">
        <div class="text-center">
          <h1>In theory, it’s one language, but in practice, British and Americans use different words for the same thing. Which words are used by whom?</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ 1">
      <app-test [test]="test1" [readBefore]="true" [showAnswerAs]="'text'" [showOptionAs]="'image'" [isDone]="state.isDone" (onDone)="nextTab()" *ngIf="state.tabIndex === 1" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="QUIZ 2">
      <app-test [test]="test2" [readBefore]="true" [showAnswerAs]="'text'" [showOptionAs]="'image'" [isDone]="state.isDone" (onDone)="nextTab()" *ngIf="state.tabIndex === 2" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="QUIZ 3">
      <app-test [test]="test3" [readBefore]="true" [showAnswerAs]="'text'" [showOptionAs]="'image'" [isDone]="state.isDone" (onDone)="nextTab()" *ngIf="state.tabIndex === 3" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="QUIZ 4">
      <app-test [test]="test4" [readBefore]="true" [showAnswerAs]="'text'" [showOptionAs]="'image'" [isDone]="state.isDone" (onDone)="nextTab()" *ngIf="state.tabIndex === 4" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="QUIZ 5">
      <app-test [test]="test5" [readBefore]="true" [showAnswerAs]="'text'" [showOptionAs]="'image'" [isDone]="state.isDone" (onDone)="nextTab()" *ngIf="state.tabIndex === 5" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="QUIZ 6">
      <app-test [test]="test6" [readBefore]="true" [showAnswerAs]="'text'" [showOptionAs]="'image'" [isDone]="state.isDone" (onDone)="onTestStateChange($event)" *ngIf="state.tabIndex === 6" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
  <button class="with-image" (click)="next()" ><img src="/assets/img/american-dream/next.png"></button>
</div>


