import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { Test } from '../../../models/test.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  public video;
  public videoStudent;
  public videoTeacher;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
      private storyService: StoryService,
      private translate: TranslateService,
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };

    this.videoStudent = {src: 'DC_TUTOR_NOV_ziak_SK.mp4', id: '511099949'};
    this.videoTeacher = {src: 'DC_TUTOR_NOV_ucitel_SK.mp4', id: '511098473'};

    if (storyService.state.type === 'teacher'){
      this.video = this.videoTeacher;
    }else{
      this.video = this.videoStudent;
    }

    this.state = {
      tabIndex: 0,
      isVocabularyDone: false,
      isDone: false,
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.state.isDone = $event;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }
}
