<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="EXERCISE 1">
      <app-test [test]="test1" [readBefore]="true" [showOptionAs]="'text'" [showAnswerAs]="'image'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 0" [playSoundAfterDone]="false"[makeItCount]="true" [title]="'How is Mr Wolf feeling? Match the word to the picture.'"></app-test>
    </mat-tab>
    <mat-tab label="ASSIGNMENT" [disabled]="false">
      <div class="with-background">
        <div class="text-center">
          <h1>Mr Wolf is travelling through Europe to find the three little pigs. <br> What is the weather like in the countries he visits? <br> Choose the correct word.</h1>
          <button mat-raised-button color="accent" (click)="nextTab()">NEXT</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="EXERCISE 2">
      <app-test [test]="test2" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 2" [playSoundAfterDone]="true" [makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="EXERCISE 3">
      <app-test [test]="test3" [readBefore]="true" [showOptionAs]="'text'" [showAnswerAs]="'image'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 3" [playSoundAfterDone]="false" [makeItCount]="true" [title]="'Mr Wolf likes dressing up as other people. Can you guess which job he is showing in the picture?'"></app-test>
    </mat-tab>
    <mat-tab label="ASSIGNMENT" [disabled]="false">
      <div class="with-background">
        <div class="text-center">
          <h1>Mr Wolf is trying very, very hard <br>to get into the little pigs’ house. <br> Can you see what he’s doing? <br> Choose the correct word.</h1>
          <button mat-raised-button color="accent" (click)="nextTab()">NEXT</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="EXERCISE 4">
      <app-test [test]="test4" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 5" [playSoundAfterDone]="false" [makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="EXERCISE 5">
      <app-test [test]="test5" [readBefore]="true" [showOptionAs]="'image+text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 6" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="EXERCISE 6">
      <app-test [test]="test6" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 7" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="ASSIGNMENT" [disabled]="false">
      <div class="with-background">
        <div class="text-center">
          <h1>Poor Mr Wolf isn’t feeling very well. <br> Can you find out what’s wrong with him? <br> What’s the right diagnosis?</h1>
          <button mat-raised-button color="accent" (click)="nextTab()">NEXT</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="EXERCISE 7">
      <app-test [test]="test7" [readBefore]="true" [showOptionAs]="'image+text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 9" [playSoundAfterDone]="false" [makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="ASSIGNMENT" [disabled]="false">
      <div class="with-background">
        <div class="text-center">
          <h1>Choose the right  preposition to complete the sentence.</h1>
          <button mat-raised-button color="accent" (click)="nextTab()">NEXT</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="EXERCISE 8">
      <app-test [test]="test8" [readBefore]="false"  [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="nextTab()" *ngIf="state.tabIndex === 11" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
    <mat-tab label="ASSIGNMENT" [disabled]="false">
      <div class="with-background">
        <div class="text-center">
          <h1>Wolfie likes thinking about his life in the past, <br>but at the end of the story, he is thinking about the future, too. <br> Which sentences are in the past, the present and the future?</h1>
          <button mat-raised-button color="accent" (click)="nextTab()">NEXT</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="EXERCISE 9">
      <app-test [test]="test9" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone"  (onDone)="next()" *ngIf="state.tabIndex === 13" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button mat-raised-button color="accent" (click)="nextTab()">NEXT</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
  <button mat-raised-button color="accent" (click)="next()" >NEXT</button>
</div>

