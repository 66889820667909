import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { StateService } from '../../../services/state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.css']
})
export class ExtraComponent implements OnInit {
  public downloadList;
  constructor(
    public storyService: StoryService,
  ){
    this.downloadList = [
      {src: '/assets/downloads/N89_test.pdf', lang: 'sk', translation: 'Test k inscenácii na stiahnutie', img: '/assets/img/november/November PDF_foto.jpg'},
      {src: '/assets/downloads/N89_charta_77.pdf', lang: 'sk', translation: 'Plné znenie Charty 77', img: '/assets/img/november/charta_foto.jpg'},
    ];
  }

  ngOnInit(): void {
  }

  backToContent(){
    this.storyService.goBackToTest();
  }
}
