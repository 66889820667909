import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { NovemberComponent } from './november.component';
import { Part1Component } from './part1/part1.component';
import { Part2Component } from './part2/part2.component';
import { Part3Component } from './part3/part3.component';
import { Part4Component } from './part4/part4.component';
import { Part5Component } from './part5/part5.component';
import { Part6Component } from './part6/part6.component';
import { Part7Component } from './part7/part7.component';
import { Part8Component } from './part8/part8.component';
import { Part9Component } from './part9/part9.component';
import { Part10Component } from './part10/part10.component';
import { FinishComponent } from './finish/finish.component';
import { ExtraComponent } from './extra/extra.component';
import { TutorialComponent } from './tutorial/tutorial.component';


const novemberRoutes: Routes = [
  {path: '', component: NovemberComponent, children: [
      {path: '', redirectTo: 'tutorial', pathMatch: 'full'},
      {path: 'tutorial', component : TutorialComponent},
      {path: 'intro', component : IntroComponent},
      {path: '1', component : Part1Component},
      {path: '2', component : Part2Component},
      {path: '3', component : Part3Component},
      {path: '4', component : Part4Component},
      {path: '5', component : Part5Component},
      {path: '6', component : Part6Component},
      {path: '7', component : Part7Component},
      {path: '8', component : Part8Component},
      {path: '9', component : Part9Component},
      {path: '10', component : Part10Component},
      {path: 'finish', component : FinishComponent},
      {path: 'extra', component : ExtraComponent},
    ] },
];

@NgModule({
  imports: [
    RouterModule.forChild(novemberRoutes)
  ],
  exports: [RouterModule]
})
export class NovemberRoutingModule { }
