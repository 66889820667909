import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialComponent } from './tutorial.component';
import { SharedModule } from '../../../shared/shared.module';



@NgModule({
  declarations: [TutorialComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class TutorialModule { }
