import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';

@Component({
  selector: 'app-part-six',
  templateUrl: './part-six.component.html',
  styleUrls: ['./part-six.component.css']
})
export class PartSixComponent implements OnInit{
  public state = null;
  public showNextTab = null;
  public test: Test;
  public vocabulary: Test;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_6_1', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka2.mp4', id: '462662166'};
    this.video2 = {src: '/assets/video/Egg On Trip_V5_6cast.mp4', id: '462795467'};
    this.video3 = {src: '/assets/video/6_cvicenie_12_V1.mp4', id: '462658684'};
    this.info2 = [
      {
        translation: 'EGG_ON_TRIP.TRANSLATED.INFO_6_2',
        audio: ''
      },
    ];
    this.test = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'crayfish', translation: 'EGG_ON_TRIP.A_CRAYFISH', audio: '/assets/audio/into the bucket of water.mp3', img: '/assets/img/crayfish.png'},
          {key: 'egg', translation: 'EGG_ON_TRIP.A_EGG', audio: '/assets/audio/in the fireplace.mp3', img: '/assets/img/sing.png'},
          {key: 'duck', translation: 'EGG_ON_TRIP.A_DUCK', audio: '/assets/audio/under the stairs.mp3', img: '/assets/img/duck.png'},
          {key: 'cow', translation: 'EGG_ON_TRIP.A_COW', audio: '/assets/audio/in the backroom.mp3', img: '/assets/img/cow.png'},
          {key: 'rooster', translation: 'EGG_ON_TRIP.A_ROOSTER', audio: '/assets/audio/next to the spider.mp3', img: '/assets/img/rooster.png'},
        ],
        answers: [
          {key: 'crayfish', translation: 'EGG_ON_TRIP.INTO_THE_BUCKET_OF_WATER', audio: '/assets/audio/into the bucket of water.mp3', img: '/assets/img/do_vedra.jpg'},
          {key: 'egg', translation: 'EGG_ON_TRIP.IN_THE_FIRE-PLACE', audio: '/assets/audio/in the fireplace.mp3', img: '/assets/img/v_peci.jpg'},
          {key: 'duck', translation: 'EGG_ON_TRIP.UNDER_THE_STAIRS', audio: '/assets/audio/under the stairs.mp3', img: '/assets/img/pod_schodami.jpg'},
          {key: 'cow', translation: 'EGG_ON_TRIP.BEHIND_THE_DOOR', audio: '/assets/audio/in the backroom.mp3', img: '/assets/img/za_dverami.jpg'},
          {key: 'rooster', translation: 'EGG_ON_TRIP.NEXT_TO_THE_SPIDER', audio: '/assets/audio/next to the spider.mp3', img: '/assets/img/pri_pavukovi.jpg'
          },
        ]
      }
    };
    this.vocabulary = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Under the stairs',
          image: '',
          options: [
            {key: 'under the stairs', translation: 'EGG_ON_TRIP.TRANSLATED.UNDER_THE_STAIRS', audio: '/assets/audio/under the stairs.mp3', img: ''},
            {key: 'everybody is in his position', translation: 'EGG_ON_TRIP.TRANSLATED.EVERYBODY_IS_IN_HIS_POSITION', audio: '/assets/audio/everybody is in his position.mp3', img: ''},
            {key: 'ash in the fireplace', translation: 'EGG_ON_TRIP.TRANSLATED.ASH_IN_THE_FIREPLACE', audio: '/assets/audio/ash in the fireplace.mp3', img: ''},
          ],
          answers: ['under the stairs'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Everybody is in his position',
          image: '',
          options: [
            {key: 'under the stairs', translation: 'EGG_ON_TRIP.TRANSLATED.UNDER_THE_STAIRS', audio: '/assets/audio/under the stairs.mp3', img: ''},
            {key: 'everybody is in his position', translation: 'EGG_ON_TRIP.TRANSLATED.EVERYBODY_IS_IN_HIS_POSITION', audio: '/assets/audio/everybody is in his position.mp3', img: ''},
            {key: 'ash in the fireplace', translation: 'EGG_ON_TRIP.TRANSLATED.ASH_IN_THE_FIREPLACE', audio: '/assets/audio/ash in the fireplace.mp3', img: ''},
          ],
          answers: ['everybody is in his position'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Ash in the fireplace',
          image: '',
          options: [
            {key: 'under the stairs', translation: 'EGG_ON_TRIP.TRANSLATED.UNDER_THE_STAIRS', audio: '/assets/audio/under the stairs.mp3', img: ''},
            {key: 'everybody is in his position', translation: 'EGG_ON_TRIP.TRANSLATED.EVERYBODY_IS_IN_HIS_POSITION', audio: '/assets/audio/everybody is in his position.mp3', img: ''},
            {key: 'ash in the fireplace', translation: 'EGG_ON_TRIP.TRANSLATED.ASH_IN_THE_FIREPLACE', audio: '/assets/audio/ash in the fireplace.mp3', img: ''},
          ],
          answers: ['ash in the fireplace'],
          correctAnswer: '',
          audio: '',
        }
      ]
    };
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

}
