<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper with-background--shelves">
        <div class="text-center">
          <h1>Čo znamená perestrojka?</h1>
          <ul>
            <li><a (click)="onCorrectAnswer()">a) reformný proces, vedúci k demokratizácii spoločnosti a uvoľňovaniu centrálnej moci Komunistickej strany v Sovietskom zväze</a></li>
            <li><a (click)="onWrongAnswer()">b) prestavba jadrovej elektrárne Černobyl</a></li>
            <li><a (click)="onWrongAnswer()">c) ruský názov hnutia rozbíjačov strojov</a></li>
          </ul>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="TEXT" [disabled]="false">
      <div class="player-wrapper">
        <ngx-circular-player radius="80"  source="/assets/audio/november-89/N89_6B.mp3"></ngx-circular-player>
      </div>
      <div class="with-background with-background--paper">
        <div class="text-center">
          <p>Najvyšším predstaviteľom sovietskej komunistickej strany sa Michail Gorbačov stal 11. marca 1985. Jeho programu reforiem, vedúcim k demokratizácii spoločnosti a "prestavby" krajiny sa hovorilo perestrojka. Po jadrovej havárii v černobyľskej elektrárni v apríli 1986 nastolil "glasnosť" čiže otvorenosť informácií. V Sovietskom zväze a jeho satelitoch, akými boli Československo, Poľsko, Maďarsko, Rumunsko, Bulharsko a ďalšie krajiny, sa začali reformy, ktoré postupne viedli k rozpadu tzv. Východného bloku. Reformy v Sovietskom zväze boli zavŕšené zánikom Sovietskeho zväzu 31.12.1991.</p>
          <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
