<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="TEXT" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>
            “Ak sa chceme stať hrdinami, mali by sme vedieť, ako v ktorej situácii správne reagovať.
            <br>
            Skús správne odpovedať na nasledujúce otázky. Pri niektorých otázkach je správnych odpovedí viac.”
          </h1>
          <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Čo urobíš v situácii, keď niekto potrebuje pomoc?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o1_babka.png"></div>
            <div>
              <ul>
                <li><a (click)="onWrongAnswer()">a) Nevšímam si ho a odídem preč.</a></li>
                <li><a (click)="onCorrectAnswer()">b) Opýtam sa, ako mu môžem pomôcť a pomôžem mu.</a></li>
                <li><a (click)="onWrongAnswer()">c) Zavolám Supermana.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Ako zlepšíš náladu spolužiakovi, ktorému je smutno?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o2_dieta.png"></div>
            <div>
              <ul>
                <li><a (click)="onWrongAnswer()">a) Nevšímam si ho.</a></li>
                <li><a (click)="onCorrectAnswer()">b) Vypočujem ho, ak sa mi chce zdôveriť.</a></li>
                <li><a (click)="onWrongAnswer()">c) Zjem mu desiatu.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Čo urobíš v situácii, keď vidíš niekoho ležať na zemi v bezvedomí?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o3_bezvedomie.png"></div>
            <div>
              <ul>
                <li><a (click)="onWrongAnswer()">a) Rýchlo utekám preč.</a></li>
                <li><a (click)="onCorrectAnswer()">b) Zavolám najbližšiu dospelú osobu.</a></li>
                <li><a (click)="onCorrectAnswer()">c) Zavolám záchranku na čísle 155 a poskytnem prvú pomoc.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Ako môžeš doma pomôcť rodičom?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o4_rodic.png"></div>
            <div>
              <ul>
                <li><a (click)="onCorrectAnswer()">a) Upracem si svoju izbu a vynesiem smeti.</a></li>
                <li><a (click)="onWrongAnswer()">b) Pospím si, nech majú odo mňa pokoj.</a></li>
                <li><a (click)="onWrongAnswer()">c) Vyjem celú chladničku, veď plytvať jedlom sa nemá.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Čo urobíš v situácii, keď vidíš, že je niekomu ubližované, alebo ho/ju šikanujú?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o5_sikana.png"></div>
            <div>
              <ul>
                <li><a (click)="onWrongAnswer()">a) Ignorujem to a idem preč.</a></li>
                <li><a (click)="onWrongAnswer()">b) Zapojím sa a všetkých zbijem.</a></li>
                <li><a (click)="onCorrectAnswer()">c) Oznámim to dospelej osobe, alebo zavolám na Linku detskej istoty na číslo 116 111.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Ako by si mohol pomôcť prírode?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o6_les.png"></div>
            <div>
              <ul>
                <li><a (click)="onCorrectAnswer()">a) Vyzbieram odpadky v lese.</a></li>
                <li><a (click)="onWrongAnswer()">b) Odnesiem odpadky do lesa, nech majú zvieratká čo jesť.</a></li>
                <li><a (click)="onCorrectAnswer()">c) Odfotografujem odpadky v blízkom lese, pozbieram ich a urobím ekoplagát do školy.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Čo urobíš v situácii, keď niekto ubližuje zvieraťu?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o7_zviera.png"></div>
            <div>
              <ul>
                <li><a (click)="onWrongAnswer()">a) Zbijem ho.</a></li>
                <li><a (click)="onCorrectAnswer()">b) Zavolám na Linku proti krutosti (Sloboda zvierat) na číslo 0917 662 030, alebo to ohlásim niekomu dospelému.</a></li>
                <li><a (click)="onWrongAnswer()">c) Nespravím nič.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Vieš, čo treba spraviť, ak si niekto porezal ruku a silno krváca?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o8_krvacanie.png"></div>
            <div>
              <ul>
                <li><a (click)="onCorrectAnswer()">a) Zatlačím na ranu a obviažem obväzom.</a></li>
                <li><a (click)="onWrongAnswer()">b) Umyjem mu ranu vodou.</a></li>
                <li><a (click)="onWrongAnswer()">c) Nevenujem tomu pozornosť.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <h1>Vieš, čo treba spraviť s človekom, ktorý sa topil a nedýcha?</h1>
          <div class="wrapper">
            <div><img src="/assets/img/hrdinovia/o9_topiaci.png"></div>
            <div>
              <ul>
                <li><a (click)="onCorrectAnswer()">a) Položím ho na chrbát a poskytnem mu umelé dýchanie.</a></li>
                <li><a (click)="onWrongAnswer()">b) Položím ho na bok a budem ním triasť, aby mu vytiekla voda z úst.</a></li>
                <li><a (click)="onWrongAnswer()">c) Budem na neho kričať, aby sa prebral.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper-rumpled">
        <div class="text-center">
          <app-test [isDone]="state.isDone" [makeItCount]="true" (onDone)="onTestStateChange($event)" [test]="pexeso" [showAnswerAs]="'image'" [showOptionAs]="'image'" *ngIf="state.tabIndex === 10"></app-test>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
