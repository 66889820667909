import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
        protected http: HttpClient,
    ) {
    }

    get(url) {
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            })
        });
    }

    post(url, data) {
      return this.http.post(url, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        })
      });
    }

    put(url, data) {
      return this.http.put(url, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        })
      });
    }
}
