import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'dots'
})
export class DotsPipe implements PipeTransform {
  constructor(private sanitizer:DomSanitizer){}

  transform(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value.replace('....', '<span style="background-color: #ed1c24; border-radius: 4px; min-width: 64px; line-height: 36px; padding: 6px 16px; color: white; font-size: 19px; height: 30px">....</span>'));
  }

}
