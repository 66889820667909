import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class StateService{
    public state: any = null;
    stateChanged = new Subject();
    viewStateChanged = new Subject();
    public viewState = {};

    constructor(
        protected router: Router,
    ){
    }

    setState(state){
        this.state = state;
        localStorage.setItem('state', JSON.stringify(this.state));
        this.stateChanged.next(this.state);
    }

    setStateByKey(state, key){
        this.state[key] = state;
        localStorage.setItem('state', JSON.stringify(this.state));
        this.stateChanged.next(this.state);
    }

    getState(){
        if (this.state){
            return this.state;
        }else{
            return this.state = JSON.parse(localStorage.getItem('state'));
        }
        return false;
    }

    setViewStateValue(value: any, key: string){
        const objectPropArray = key.split('.');
        if (!this.viewState[this.router.url]) {
          this.setViewState([], this.router.url);
        }
        const viewStateByUrl = this.viewState[this.router.url];
        if (objectPropArray[0] && objectPropArray[1] && objectPropArray[2] && objectPropArray[3]){
            viewStateByUrl[objectPropArray[0]][objectPropArray[1]][objectPropArray[2]][objectPropArray[3]] = value;
        }else if (objectPropArray[0] && objectPropArray[1] && objectPropArray[2]){
            viewStateByUrl[objectPropArray[0]][objectPropArray[1]][objectPropArray[2]] = value;
        }else if (objectPropArray[0] && objectPropArray[1]){
            viewStateByUrl[objectPropArray[0]][objectPropArray[1]] = value;
        }else{
            viewStateByUrl[objectPropArray[0]] = value;
        }
        this.setViewState(this.viewState[this.router.url]);
    }

    setViewState(state: any[], componentName?: string){
        if (!this.viewState[this.router.url]){
            this.viewState[this.router.url] = {};
        }

        if (componentName){
            this.viewState[this.router.url][componentName] = state;
        }else{
            this.viewState[this.router.url] = state;
        }

        localStorage.setItem('viewState', JSON.stringify(this.viewState));
        this.viewStateChanged.next(this.viewState);
    }

    clearViewStates(){
      localStorage.removeItem('viewState');
    }

    clearState(){
      localStorage.removeItem('state');
    }

    getViewState(componentName?: string){
        if (!Object.keys(this.viewState).length && localStorage.getItem('viewState')){
            this.viewState = JSON.parse(localStorage.getItem('viewState'));
        }

        if (this.viewState[this.router.url] && !componentName){
            return this.viewState[this.router.url];
        }else{
            if (this.viewState[this.router.url] && this.viewState[this.router.url][componentName]){
                return this.viewState[this.router.url][componentName];
            }
        }
        return false;
    }

    setViewStateOrSetDefault(defaultSettings){
        if (!this.getViewState()){
            this.setViewState(defaultSettings);
        }
        this.viewStateChanged.next(this.viewState);
    }

    setStateOrSetDefault(defaultSettings){
        if (!this.getState()){
            this.setState(defaultSettings);
        }
        this.stateChanged.next(this.state);
    }

    setComponentStateOrSetDefault(defaultSettings, componentName: string){
        if (!this.getViewState(componentName)){
            this.setViewState(defaultSettings, componentName);
        }
        this.viewStateChanged.next(this.viewState);
    }
}
