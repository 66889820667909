import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { formatDate, Location } from '@angular/common';
import { StateService } from './state.service';
import { HttpService } from './http.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ExpirationModalComponent } from '../shared/expiration-modal/expiration-modal.component';
import { environment } from '../../environments/environment';
import { LoginModalComponent } from '../shared/login-modal/login-modal.component';
import { VocabularyModalComponent } from '../shared/vocabulary-modal/vocabulary-modal.component';
import { AddClassComponent } from '../shared/add-class-modal/add-class-modal.component';

@Injectable({
  providedIn: 'root'
})
export class StoryService{

  public config;
  public vocabulary;
  private story;
  public state;
  public verificationSubscription: Subscription;
  public verificationChanged = new Subject();
  public stateSubscription: Subscription;
  private renderer: Renderer2;
  public defaultState = {
    active_story: '',
    active_part: {translation: 'INTERFACE.INTRO',  key: 'intro'},
    tabIndex: 0,
    active_points: 0,
    token: '',
    type: '',
    lang: 'sk',
  };

  constructor(
    protected stateService: StateService,
    protected http: HttpService,
    private router: Router,
    private location: Location,
    public translate: TranslateService,
    private rendererFactory: RendererFactory2,
    private titleService: Title,
    public dialog: MatDialog,
  ){
    this.renderer = rendererFactory.createRenderer(null, null);
    this.vocabulary = {
      'egg-on-a-trip': [
        {english: 'EGG_ON_TRIP.A_BUCKET_OF_GRAIN', translation: 'EGG_ON_TRIP.TRANSLATED.A_BUCKET_OF_GRAIN', audio: '/assets/audio/a bucket of grain.mp3'},
        {english: 'EGG_ON_TRIP.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', translation: 'EGG_ON_TRIP.TRANSLATED.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', audio: '/assets/audio/as fast as their legs could carry them.mp3'},
        {english: 'EGG_ON_TRIP.ASH_IN_THE_FIREPLACE', translation: 'EGG_ON_TRIP.TRANSLATED.ASH_IN_THE_FIREPLACE', audio: '/assets/audio/ash in the fireplace.mp3'},
        {english: 'EGG_ON_TRIP.BE_CAREFUL', translation: 'EGG_ON_TRIP.TRANSLATED.BE_CAREFUL', audio: '/assets/audio/be carefull.mp3'},
        {english: 'EGG_ON_TRIP.DANGEROUS_THIEVES', translation: 'EGG_ON_TRIP.TRANSLATED.DANGEROUS_THIEVES', audio: '/assets/audio/dangerous thieves.mp3'},
        {english: 'EGG_ON_TRIP.EVERYBODY_IS_IN_HIS_POSITION', translation: 'EGG_ON_TRIP.TRANSLATED.EVERYBODY_IS_IN_HIS_POSITION', audio: '/assets/audio/everybody is in his position.mp3'},
        {english: 'EGG_ON_TRIP.FIND_THE_HANDLE', translation: 'EGG_ON_TRIP.TRANSLATED.FIND_THE_HANDLE', audio: '/assets/audio/find the handle.mp3'},
        {english: 'EGG_ON_TRIP.HOLD_ME_TIGHT', translation: 'EGG_ON_TRIP.TRANSLATED.HOLD_ME_TIGHT', audio: '/assets/audio/hold me tight.mp3'},
        {english: 'EGG_ON_TRIP.I_AM_LOST', translation: 'EGG_ON_TRIP.TRANSLATED.I_AM_LOST', audio: '/assets/audio/I am lost.mp3'},
        {english: 'EGG_ON_TRIP.LISTEN_CAREFULLY', translation: 'EGG_ON_TRIP.TRANSLATED.LISTEN_CAREFULLY', audio: '/assets/audio/listen carefully.mp3'},
        {english: 'EGG_ON_TRIP.MOTHER_NATURE', translation: 'EGG_ON_TRIP.TRANSLATED.MOTHER_NATURE', audio: '/assets/audio/mother nature.mp3'},
        {english: 'EGG_ON_TRIP.PUNCH_IN_THE_STOMACH', translation: 'EGG_ON_TRIP.TRANSLATED.PUNCH_IN_THE_STOMACH', audio: '/assets/audio/punch in the stomach.mp3'},
        {english: 'EGG_ON_TRIP.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', translation: 'EGG_ON_TRIP.TRANSLATED.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', audio: '/assets/audio/the chef looks in the cookery book.mp3'},
        {english: 'EGG_ON_TRIP.THE_WRONG_DIRECTION', translation: 'EGG_ON_TRIP.TRANSLATED.THE_WRONG_DIRECTION', audio: '/assets/audio/the wrong direction.mp3'},
        {english: 'EGG_ON_TRIP.TO_CATCH', translation: 'EGG_ON_TRIP.TRANSLATED.TO_CATCH', audio: '/assets/audio/to catch.mp3'},
        {english: 'EGG_ON_TRIP.TO_DRIVE_THE_THIEVES_OUT', translation: 'EGG_ON_TRIP.TRANSLATED.TO_DRIVE_THE_THIEVES_OUT', audio: '/assets/audio/to drive the thieves out.mp3'},
        {english: 'EGG_ON_TRIP.TO_ELIMINATE_THE_ENEMY', translation: 'EGG_ON_TRIP.TRANSLATED.TO_ELIMINATE_THE_ENEMY', audio: '/assets/audio/to eliminate the enemy.mp3'},
        {english: 'EGG_ON_TRIP.TO_FIND_A_LIGHT', translation: 'EGG_ON_TRIP.TRANSLATED.TO_FIND_A_LIGHT', audio: '/assets/audio/to find a light.mp3'},
        {english: 'EGG_ON_TRIP.TO_GO_FOR_A_TRIP', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_TRIP', audio: '/assets/audio/to go for a trip.mp3'},
        {english: 'EGG_ON_TRIP.TO_GO_FOR_A_WALK', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_WALK', audio: '/assets/audio/to go for a walk.mp3'},
        {english: 'EGG_ON_TRIP.TO_HAVE_A_REST', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HAVE_A_REST', audio: '/assets/audio/to have a rest.mp3'},
        {english: 'EGG_ON_TRIP.TO_HURRY_UP', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HURRY_UP', audio: '/assets/audio/to hurry up.mp3'},
        {english: 'EGG_ON_TRIP.TO_JUMP_ON_SOMEONES_BACK', translation: 'EGG_ON_TRIP.TRANSLATED.TO_JUMP_ON_SOMEONES_BACK', audio: '/assets/audio/to jump on someones back.mp3'},
        {english: 'EGG_ON_TRIP.TO_KNOCK_ON_THE_DOOR', translation: 'EGG_ON_TRIP.TRANSLATED.TO_KNOCK_ON_THE_DOOR', audio: '/assets/audio/to knock on the door.mp3'},
        {english: 'EGG_ON_TRIP.TO_LOSE_YOUR_WAY', translation: 'EGG_ON_TRIP.TRANSLATED.TO_LOSE_YOUR_WAY', audio: '/assets/audio/to lose your way.mp3'},
        {english: 'EGG_ON_TRIP.TO_SCREAM_FOR_HELP', translation: 'EGG_ON_TRIP.TRANSLATED.TO_SCREAM_FOR_HELP', audio: '/assets/audio/to scream for help.mp3'},
        {english: 'EGG_ON_TRIP.TO_STIR_THE_MILK', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STIR_THE_MILK', audio: '/assets/audio/to stir the milk.mp3'},
        {english: 'EGG_ON_TRIP.TO_STOP_IN_A_MEADOW', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STOP_IN_A_MEADOW', audio: '/assets/audio/to stop in a meadow.mp3'},
        {english: 'EGG_ON_TRIP.UNDER_THE_STAIRS', translation: 'EGG_ON_TRIP.TRANSLATED.UNDER_THE_STAIRS', audio: '/assets/audio/under the stairs.mp3'},
        {english: 'EGG_ON_TRIP.WE_ARE_SAVED', translation: 'EGG_ON_TRIP.TRANSLATED.WE_ARE_SAVED', audio: '/assets/audio/we are saved.mp3'},
        {english: 'EGG_ON_TRIP.WILD_ANIMAL', translation: 'EGG_ON_TRIP.TRANSLATED.WILD_ANIMAL', audio: '/assets/audio/wild animal.mp3'},
      ],
      'american-dream-zs': [
        {english: 'AMERICAN_DREAM.ABSOLUTE_MONARCHY', translation: 'AMERICAN_DREAM.TRANSLATED.ABSOLUTE_MONARCHY', audio: '/assets/audio/american-dream/slovicka/absolut monarchy.mp3'},
        {english: 'AMERICAN_DREAM.ADVERTS', translation: 'AMERICAN_DREAM.TRANSLATED.ADVERTS', audio: '/assets/audio/american-dream/slovicka/adverts 2.mp3'},
        {english: 'AMERICAN_DREAM.ATTORNEY', translation: 'AMERICAN_DREAM.TRANSLATED.ATTORNEY', audio: '/assets/audio/american-dream/slovicka/attorney.mp3'},
        {english: 'AMERICAN_DREAM.AWESOME', translation: 'AMERICAN_DREAM.TRANSLATED.AWESOME', audio: '/assets/audio/american-dream/slovicka/awesome.mp3'},
        {english: 'AMERICAN_DREAM.BAGEL', translation: 'AMERICAN_DREAM.TRANSLATED.BAGEL', audio: '/assets/audio/american-dream/slovicka/bagel.mp3'},
        {english: 'AMERICAN_DREAM.BEAUTY_SLEEP', translation: 'AMERICAN_DREAM.TRANSLATED.BEAUTY_SLEEP', audio: '/assets/audio/american-dream/slovicka/beauty sleep.mp3'},
        {english: 'AMERICAN_DREAM.BENEFIT_CONCERTS', translation: 'AMERICAN_DREAM.TRANSLATED.BENEFIT_CONCERTS', audio: '/assets/audio/american-dream/slovicka/benefit concerts.mp3'},
        {english: 'AMERICAN_DREAM.BODY_CLOCK', translation: 'AMERICAN_DREAM.TRANSLATED.BODY_CLOCK', audio: '/assets/audio/american-dream/slovicka/body clock.mp3'},
        {english: 'AMERICAN_DREAM.BOTTLED', translation: 'AMERICAN_DREAM.TRANSLATED.BOTTLED', audio: '/assets/audio/american-dream/slovicka/bottled.mp3'},
        {english: 'AMERICAN_DREAM.BROAD', translation: 'AMERICAN_DREAM.TRANSLATED.BROAD', audio: '/assets/audio/american-dream/slovicka/broad.mp3'},
        {english: 'AMERICAN_DREAM.CARBON_FOOTPRINT', translation: 'AMERICAN_DREAM.TRANSLATED.CARBON_FOOTPRINT', audio: '/assets/audio/american-dream/slovicka/carbon footprint.mp3'},
        {english: 'AMERICAN_DREAM.CARRY_ON_LUGGAGE', translation: 'AMERICAN_DREAM.TRANSLATED.CARRY_ON_LUGGAGE', audio: '/assets/audio/american-dream/slovicka/carry on luggage.mp3'},
        {english: 'AMERICAN_DREAM.CHARITY', translation: 'AMERICAN_DREAM.TRANSLATED.CHARITY', audio: '/assets/audio/american-dream/slovicka/charity.mp3'},
        {english: 'AMERICAN_DREAM.CITY_BREAKS', translation: 'AMERICAN_DREAM.TRANSLATED.CITY_BREAKS', audio: '/assets/audio/american-dream/slovicka/city breaks.mp3'},
        {english: 'AMERICAN_DREAM.CONSTITUTIONAL_MONARCHY', translation: 'AMERICAN_DREAM.TRANSLATED.CONSTITUTIONAL_MONARCHY', audio: '/assets/audio/american-dream/slovicka/constitutional monarchy.mp3'},
        {english: 'AMERICAN_DREAM.CROSSROADS', translation: 'AMERICAN_DREAM.TRANSLATED.CROSSROADS', audio: '/assets/audio/american-dream/slovicka/crossroads.mp3'},
        {english: 'AMERICAN_DREAM.ETHNIC_GROUPS', translation: 'AMERICAN_DREAM.TRANSLATED.ETHNIC_GROUPS', audio: '/assets/audio/american-dream/slovicka/ethnic groups.mp3'},
        {english: 'AMERICAN_DREAM.FLIGHT_ATTENDANTS', translation: 'AMERICAN_DREAM.TRANSLATED.FLIGHT_ATTENDANTS', audio: '/assets/audio/american-dream/slovicka/flight attendants.mp3'},
        {english: 'AMERICAN_DREAM.GOALS', translation: 'AMERICAN_DREAM.TRANSLATED.GOALS', audio: '/assets/audio/american-dream/slovicka/goals.mp3'},
        {english: 'AMERICAN_DREAM.HUSTLE_AND_BUSTLE', translation: 'AMERICAN_DREAM.TRANSLATED.HUSTLE_AND_BUSTLE', audio: '/assets/audio/american-dream/slovicka/hustle and bustle.mp3'},
        {english: 'AMERICAN_DREAM.KIDDING', translation: 'AMERICAN_DREAM.TRANSLATED.KIDDING', audio: '/assets/audio/american-dream/slovicka/kidding.mp3'},
        {english: 'AMERICAN_DREAM.LANDMARKS', translation: 'AMERICAN_DREAM.TRANSLATED.LANDMARKS', audio: '/assets/audio/american-dream/slovicka/landmarks.mp3'},
        {english: 'AMERICAN_DREAM.MINORITIES', translation: 'AMERICAN_DREAM.TRANSLATED.MINORITIES', audio: '/assets/audio/american-dream/slovicka/minorities.mp3'},
        {english: 'AMERICAN_DREAM.OVERHEAD_BIN', translation: 'AMERICAN_DREAM.TRANSLATED.OVERHEAD_BIN', audio: '/assets/audio/american-dream/slovicka/overhead bin.mp3'},
        {english: 'AMERICAN_DREAM.PACKAGE_HOLIDAY', translation: 'AMERICAN_DREAM.TRANSLATED.PACKAGE_HOLIDAY', audio: '/assets/audio/american-dream/slovicka/package holiday.mp3'},
        {english: 'AMERICAN_DREAM.PAINTER-DECORATOR', translation: 'AMERICAN_DREAM.TRANSLATED.PAINTER-DECORATOR', audio: '/assets/audio/american-dream/slovicka/painter decorateur.mp3'},
        {english: 'AMERICAN_DREAM.PIGEONS', translation: 'AMERICAN_DREAM.TRANSLATED.PIGEONS', audio: '/assets/audio/american-dream/slovicka/pigeons.mp3'},
        {english: 'AMERICAN_DREAM.QUEUING', translation: 'AMERICAN_DREAM.TRANSLATED.QUEUING', audio: '/assets/audio/american-dream/slovicka/queuing.mp3'},
        {english: 'AMERICAN_DREAM.QUIZ_NIGHT', translation: 'AMERICAN_DREAM.TRANSLATED.CARBON_FOOTPRINT', audio: '/assets/audio/american-dream/slovicka/quiz night.mp3'},
        {english: 'AMERICAN_DREAM.RELIEVED', translation: 'AMERICAN_DREAM.TRANSLATED.RELIEVED', audio: '/assets/audio/american-dream/slovicka/relieved.mp3'},
        {english: 'AMERICAN_DREAM.RETIREMENT_HOME', translation: 'AMERICAN_DREAM.TRANSLATED.RETIREMENT_HOME', audio: '/assets/audio/american-dream/slovicka/retirement home.mp3'},
        {english: 'AMERICAN_DREAM.SEASIDE_RESORT', translation: 'AMERICAN_DREAM.TRANSLATED.SEASIDE_RESORT', audio: '/assets/audio/american-dream/slovicka/seaside resort.mp3'},
        {english: 'AMERICAN_DREAM.SHADING', translation: 'AMERICAN_DREAM.TRANSLATED.SHADING', audio: '/assets/audio/american-dream/slovicka/shading.mp3'},
        {english: 'AMERICAN_DREAM.SLEEPYHEADS', translation: 'AMERICAN_DREAM.TRANSLATED.SLEEPYHEADS', audio: '/assets/audio/american-dream/slovicka/sleepy heads.mp3'},
        {english: 'AMERICAN_DREAM.STEADY', translation: 'AMERICAN_DREAM.TRANSLATED.STEADY', audio: '/assets/audio/american-dream/slovicka/steady.mp3'},
        {english: 'AMERICAN_DREAM.STUDENT_EXCHANGE_PROGRAMMES', translation: 'AMERICAN_DREAM.TRANSLATED.STUDENT_EXCHANGE_PROGRAMMES', audio: '/assets/audio/american-dream/slovicka/student excange programs.mp3'},
        {english: 'AMERICAN_DREAM.SUBWAY', translation: 'AMERICAN_DREAM.TRANSLATED.SUBWAY', audio: '/assets/audio/american-dream/slovicka/subway.mp3'},
        {english: 'AMERICAN_DREAM.SWANS', translation: 'AMERICAN_DREAM.TRANSLATED.SWANS', audio: '/assets/audio/american-dream/slovicka/swans.mp3'},
        {english: 'AMERICAN_DREAM.SWEATY', translation: 'AMERICAN_DREAM.TRANSLATED.SWEATY', audio: '/assets/audio/american-dream/slovicka/sweaty.mp3'},
        {english: 'AMERICAN_DREAM.TALK_SHOW', translation: 'AMERICAN_DREAM.TRANSLATED.TALK_SHOW', audio: '/assets/audio/american-dream/slovicka/talkshow.mp3'},
        {english: 'AMERICAN_DREAM.TO_FOLLOW_IN_HIS_FATHER_S_FOOTSTEPS', translation: 'AMERICAN_DREAM.TRANSLATED.TO_FOLLOW_IN_HIS_FATHER_S_FOOTSTEPS', audio: '/assets/audio/american-dream/slovicka/to follow in his father footsteps.mp3'},
        {english: 'AMERICAN_DREAM.TO_GO_ALONG_WITH_THE_FLOW', translation: 'AMERICAN_DREAM.TRANSLATED.TO_GO_ALONG_WITH_THE_FLOW', audio: '/assets/audio/american-dream/slovicka/to go along with the flow.mp3'},
        {english: 'AMERICAN_DREAM.TO_SLEEP_A_WINK', translation: 'AMERICAN_DREAM.TRANSLATED.TO_SLEEP_A_WINK', audio: '/assets/audio/american-dream/slovicka/to sleep a wink.mp3'},
        {english: 'AMERICAN_DREAM.UNDER_ARREST', translation: 'AMERICAN_DREAM.TRANSLATED.UNDER_ARREST', audio: '/assets/audio/american-dream/slovicka/under arrest.mp3'},
        {english: 'AMERICAN_DREAM.VARIED', translation: 'AMERICAN_DREAM.TRANSLATED.VARIED', audio: '/assets/audio/american-dream/slovicka/varied.mp3'},
        {english: 'AMERICAN_DREAM.VOLUNTARY_WORK', translation: 'AMERICAN_DREAM.TRANSLATED.CARBON_FOOTPRINT', audio: '/assets/audio/american-dream/slovicka/voluntary work.mp3'},
        {english: 'AMERICAN_DREAM.WILL_POWER', translation: 'AMERICAN_DREAM.TRANSLATED.WILL_POWER', audio: '/assets/audio/american-dream/slovicka/will power.mp3'},
      ],
      'american-dream-ss': [
        {english: 'AMERICAN_DREAM.ABSOLUTE_MONARCHY', translation: 'AMERICAN_DREAM.TRANSLATED.ABSOLUTE_MONARCHY', audio: '/assets/audio/american-dream/slovicka/absolut monarchy.mp3'},
        {english: 'AMERICAN_DREAM.ADVERTS', translation: 'AMERICAN_DREAM.TRANSLATED.ADVERTS', audio: '/assets/audio/american-dream/slovicka/adverts 2.mp3'},
        {english: 'AMERICAN_DREAM.ATTORNEY', translation: 'AMERICAN_DREAM.TRANSLATED.ATTORNEY', audio: '/assets/audio/american-dream/slovicka/attorney.mp3'},
        {english: 'AMERICAN_DREAM.AWESOME', translation: 'AMERICAN_DREAM.TRANSLATED.AWESOME', audio: '/assets/audio/american-dream/slovicka/awesome.mp3'},
        {english: 'AMERICAN_DREAM.BAGEL', translation: 'AMERICAN_DREAM.TRANSLATED.BAGEL', audio: '/assets/audio/american-dream/slovicka/bagel.mp3'},
        {english: 'AMERICAN_DREAM.BEAUTY_SLEEP', translation: 'AMERICAN_DREAM.TRANSLATED.BEAUTY_SLEEP', audio: '/assets/audio/american-dream/slovicka/beauty sleep.mp3'},
        {english: 'AMERICAN_DREAM.BENEFIT_CONCERTS', translation: 'AMERICAN_DREAM.TRANSLATED.BENEFIT_CONCERTS', audio: '/assets/audio/american-dream/slovicka/benefit concerts.mp3'},
        {english: 'AMERICAN_DREAM.BODY_CLOCK', translation: 'AMERICAN_DREAM.TRANSLATED.BODY_CLOCK', audio: '/assets/audio/american-dream/slovicka/body clock.mp3'},
        {english: 'AMERICAN_DREAM.BOTTLED', translation: 'AMERICAN_DREAM.TRANSLATED.BOTTLED', audio: '/assets/audio/american-dream/slovicka/bottled.mp3'},
        {english: 'AMERICAN_DREAM.BROAD', translation: 'AMERICAN_DREAM.TRANSLATED.BROAD', audio: '/assets/audio/american-dream/slovicka/broad.mp3'},
        {english: 'AMERICAN_DREAM.CARBON_FOOTPRINT', translation: 'AMERICAN_DREAM.TRANSLATED.CARBON_FOOTPRINT', audio: '/assets/audio/american-dream/slovicka/carbon footprint.mp3'},
        {english: 'AMERICAN_DREAM.CARRY_ON_LUGGAGE', translation: 'AMERICAN_DREAM.TRANSLATED.CARRY_ON_LUGGAGE', audio: '/assets/audio/american-dream/slovicka/carry on luggage.mp3'},
        {english: 'AMERICAN_DREAM.CHARITY', translation: 'AMERICAN_DREAM.TRANSLATED.CHARITY', audio: '/assets/audio/american-dream/slovicka/charity.mp3'},
        {english: 'AMERICAN_DREAM.CITY_BREAKS', translation: 'AMERICAN_DREAM.TRANSLATED.CITY_BREAKS', audio: '/assets/audio/american-dream/slovicka/city breaks.mp3'},
        {english: 'AMERICAN_DREAM.CONSTITUTIONAL_MONARCHY', translation: 'AMERICAN_DREAM.TRANSLATED.CONSTITUTIONAL_MONARCHY', audio: '/assets/audio/american-dream/slovicka/constitutional monarchy.mp3'},
        {english: 'AMERICAN_DREAM.CROSSROADS', translation: 'AMERICAN_DREAM.TRANSLATED.CROSSROADS', audio: '/assets/audio/american-dream/slovicka/crossroads.mp3'},
        {english: 'AMERICAN_DREAM.ETHNIC_GROUPS', translation: 'AMERICAN_DREAM.TRANSLATED.ETHNIC_GROUPS', audio: '/assets/audio/american-dream/slovicka/ethnic groups.mp3'},
        {english: 'AMERICAN_DREAM.FLIGHT_ATTENDANTS', translation: 'AMERICAN_DREAM.TRANSLATED.FLIGHT_ATTENDANTS', audio: '/assets/audio/american-dream/slovicka/flight attendants.mp3'},
        {english: 'AMERICAN_DREAM.GOALS', translation: 'AMERICAN_DREAM.TRANSLATED.GOALS', audio: '/assets/audio/american-dream/slovicka/goals.mp3'},
        {english: 'AMERICAN_DREAM.HUSTLE_AND_BUSTLE', translation: 'AMERICAN_DREAM.TRANSLATED.HUSTLE_AND_BUSTLE', audio: '/assets/audio/american-dream/slovicka/hustle and bustle.mp3'},
        {english: 'AMERICAN_DREAM.KIDDING', translation: 'AMERICAN_DREAM.TRANSLATED.KIDDING', audio: '/assets/audio/american-dream/slovicka/kidding.mp3'},
        {english: 'AMERICAN_DREAM.LANDMARKS', translation: 'AMERICAN_DREAM.TRANSLATED.LANDMARKS', audio: '/assets/audio/american-dream/slovicka/landmarks.mp3'},
        {english: 'AMERICAN_DREAM.MINORITIES', translation: 'AMERICAN_DREAM.TRANSLATED.MINORITIES', audio: '/assets/audio/american-dream/slovicka/minorities.mp3'},
        {english: 'AMERICAN_DREAM.OVERHEAD_BIN', translation: 'AMERICAN_DREAM.TRANSLATED.OVERHEAD_BIN', audio: '/assets/audio/american-dream/slovicka/overhead bin.mp3'},
        {english: 'AMERICAN_DREAM.PACKAGE_HOLIDAY', translation: 'AMERICAN_DREAM.TRANSLATED.PACKAGE_HOLIDAY', audio: '/assets/audio/american-dream/slovicka/package holiday.mp3'},
        {english: 'AMERICAN_DREAM.PAINTER-DECORATOR', translation: 'AMERICAN_DREAM.TRANSLATED.PAINTER-DECORATOR', audio: '/assets/audio/american-dream/slovicka/painter decorateur.mp3'},
        {english: 'AMERICAN_DREAM.PIGEONS', translation: 'AMERICAN_DREAM.TRANSLATED.PIGEONS', audio: '/assets/audio/american-dream/slovicka/pigeons.mp3'},
        {english: 'AMERICAN_DREAM.QUEUING', translation: 'AMERICAN_DREAM.TRANSLATED.QUEUING', audio: '/assets/audio/american-dream/slovicka/queuing.mp3'},
        {english: 'AMERICAN_DREAM.QUIZ_NIGHT', translation: 'AMERICAN_DREAM.TRANSLATED.CARBON_FOOTPRINT', audio: '/assets/audio/american-dream/slovicka/quiz night.mp3'},
        {english: 'AMERICAN_DREAM.RELIEVED', translation: 'AMERICAN_DREAM.TRANSLATED.RELIEVED', audio: '/assets/audio/american-dream/slovicka/relieved.mp3'},
        {english: 'AMERICAN_DREAM.RETIREMENT_HOME', translation: 'AMERICAN_DREAM.TRANSLATED.RETIREMENT_HOME', audio: '/assets/audio/american-dream/slovicka/retirement home.mp3'},
        {english: 'AMERICAN_DREAM.SEASIDE_RESORT', translation: 'AMERICAN_DREAM.TRANSLATED.SEASIDE_RESORT', audio: '/assets/audio/american-dream/slovicka/seaside resort.mp3'},
        {english: 'AMERICAN_DREAM.SHADING', translation: 'AMERICAN_DREAM.TRANSLATED.SHADING', audio: '/assets/audio/american-dream/slovicka/shading.mp3'},
        {english: 'AMERICAN_DREAM.SLEEPYHEADS', translation: 'AMERICAN_DREAM.TRANSLATED.SLEEPYHEADS', audio: '/assets/audio/american-dream/slovicka/sleepy heads.mp3'},
        {english: 'AMERICAN_DREAM.STEADY', translation: 'AMERICAN_DREAM.TRANSLATED.STEADY', audio: '/assets/audio/american-dream/slovicka/steady.mp3'},
        {english: 'AMERICAN_DREAM.STUDENT_EXCHANGE_PROGRAMMES', translation: 'AMERICAN_DREAM.TRANSLATED.STUDENT_EXCHANGE_PROGRAMMES', audio: '/assets/audio/american-dream/slovicka/student excange programs.mp3'},
        {english: 'AMERICAN_DREAM.SUBWAY', translation: 'AMERICAN_DREAM.TRANSLATED.SUBWAY', audio: '/assets/audio/american-dream/slovicka/subway.mp3'},
        {english: 'AMERICAN_DREAM.SWANS', translation: 'AMERICAN_DREAM.TRANSLATED.SWANS', audio: '/assets/audio/american-dream/slovicka/swans.mp3'},
        {english: 'AMERICAN_DREAM.SWEATY', translation: 'AMERICAN_DREAM.TRANSLATED.SWEATY', audio: '/assets/audio/american-dream/slovicka/sweaty.mp3'},
        {english: 'AMERICAN_DREAM.TALK_SHOW', translation: 'AMERICAN_DREAM.TRANSLATED.TALK_SHOW', audio: '/assets/audio/american-dream/slovicka/talkshow.mp3'},
        {english: 'AMERICAN_DREAM.TO_FOLLOW_IN_HIS_FATHER_S_FOOTSTEPS', translation: 'AMERICAN_DREAM.TRANSLATED.TO_FOLLOW_IN_HIS_FATHER_S_FOOTSTEPS', audio: '/assets/audio/american-dream/slovicka/to follow in his father footsteps.mp3'},
        {english: 'AMERICAN_DREAM.TO_GO_ALONG_WITH_THE_FLOW', translation: 'AMERICAN_DREAM.TRANSLATED.TO_GO_ALONG_WITH_THE_FLOW', audio: '/assets/audio/american-dream/slovicka/to go along with the flow.mp3'},
        {english: 'AMERICAN_DREAM.TO_SLEEP_A_WINK', translation: 'AMERICAN_DREAM.TRANSLATED.TO_SLEEP_A_WINK', audio: '/assets/audio/american-dream/slovicka/to sleep a wink.mp3'},
        {english: 'AMERICAN_DREAM.UNDER_ARREST', translation: 'AMERICAN_DREAM.TRANSLATED.UNDER_ARREST', audio: '/assets/audio/american-dream/slovicka/under arrest.mp3'},
        {english: 'AMERICAN_DREAM.VARIED', translation: 'AMERICAN_DREAM.TRANSLATED.VARIED', audio: '/assets/audio/american-dream/slovicka/varied.mp3'},
        {english: 'AMERICAN_DREAM.VOLUNTARY_WORK', translation: 'AMERICAN_DREAM.TRANSLATED.CARBON_FOOTPRINT', audio: '/assets/audio/american-dream/slovicka/voluntary work.mp3'},
        {english: 'AMERICAN_DREAM.WILL_POWER', translation: 'AMERICAN_DREAM.TRANSLATED.WILL_POWER', audio: '/assets/audio/american-dream/slovicka/will power.mp3'},
      ],
      'mr-wolf': [
        {english: 'MR_WOLF.ANGRY', translation: 'MR_WOLF.TRANSLATED.ANGRY', audio: '/assets/audio/mr-wolf/vocabulary/angry.mp3'},
        {english: 'MR_WOLF.ARCHITECT', translation: 'MR_WOLF.TRANSLATED.ARCHITECT', audio: '/assets/audio/mr-wolf/vocabulary/architect.mp3'},
        {english: 'MR_WOLF.AWESOME', translation: 'MR_WOLF.TRANSLATED.AWESOME', audio: '/assets/audio/mr-wolf/vocabulary/awesome.mp3'},
        {english: 'MR_WOLF.A_BOMB', translation: 'MR_WOLF.TRANSLATED.A_BOMB', audio: '/assets/audio/mr-wolf/vocabulary/a_bomb.mp3'},
        {english: 'MR_WOLF.BRAND_NEW', translation: 'MR_WOLF.TRANSLATED.BRAND_NEW', audio: '/assets/audio/mr-wolf/vocabulary/brand_new.mp3'},
        {english: 'MR_WOLF.BRAVE', translation: 'MR_WOLF.TRANSLATED.BRAVE', audio: '/assets/audio/mr-wolf/vocabulary/brave.mp3'},
        {english: 'MR_WOLF.BREAK', translation: 'MR_WOLF.TRANSLATED.BREAK', audio: '/assets/audio/mr-wolf/vocabulary/break.mp3'},
        {english: 'MR_WOLF.BROKEN_LEG', translation: 'MR_WOLF.TRANSLATED.BROKEN_LEG', audio: '/assets/audio/mr-wolf/vocabulary/broken_leg.mp3'},
        {english: 'MR_WOLF.BUILDER', translation: 'MR_WOLF.TRANSLATED.BUILDER', audio: '/assets/audio/mr-wolf/vocabulary/builder.mp3'},
        {english: 'MR_WOLF.BUS', translation: 'MR_WOLF.TRANSLATED.BUS', audio: '/assets/audio/mr-wolf/vocabulary/bus.mp3'},
        {english: 'MR_WOLF.BUSY', translation: 'MR_WOLF.TRANSLATED.BUSY', audio: '/assets/audio/mr-wolf/vocabulary/busy.mp3'},
        {english: 'MR_WOLF.CHIMNEY', translation: 'MR_WOLF.TRANSLATED.CHIMNEY', audio: '/assets/audio/mr-wolf/vocabulary/chimney.mp3'},
        {english: 'MR_WOLF.CLOUDY', translation: 'MR_WOLF.TRANSLATED.CLOUDY', audio: '/assets/audio/mr-wolf/vocabulary/cloudy.mp3'},
        {english: 'MR_WOLF.COLD', translation: 'MR_WOLF.TRANSLATED.COLD', audio: '/assets/audio/mr-wolf/vocabulary/cold.mp3'},
        {english: 'MR_WOLF.COOK', translation: 'MR_WOLF.TRANSLATED.COOK', audio: '/assets/audio/mr-wolf/vocabulary/cook.mp3'},
        {english: 'MR_WOLF.DOOR', translation: 'MR_WOLF.TRANSLATED.DOOR', audio: '/assets/audio/mr-wolf/vocabulary/door.mp3'},
        {english: 'MR_WOLF.EXAM', translation: 'MR_WOLF.TRANSLATED.EXAM', audio: '/assets/audio/mr-wolf/vocabulary/exam.mp3'},
        {english: 'MR_WOLF.EXPERIENCE', translation: 'MR_WOLF.TRANSLATED.EXPERIENCE', audio: '/assets/audio/mr-wolf/vocabulary/experience.mp3'},
        {english: 'MR_WOLF.FIREPLACE', translation: 'MR_WOLF.TRANSLATED.FIREPLACE', audio: '/assets/audio/mr-wolf/vocabulary/fireplace.mp3'},
        {english: 'MR_WOLF.FIREPROOF', translation: 'MR_WOLF.TRANSLATED.FIREPROOF', audio: '/assets/audio/mr-wolf/vocabulary/fireproof.mp3'},
        {english: 'MR_WOLF.FOGGY', translation: 'MR_WOLF.TRANSLATED.FOGGY', audio: '/assets/audio/mr-wolf/vocabulary/foggy.mp3'},
        {english: 'MR_WOLF.FOOL', translation: 'MR_WOLF.TRANSLATED.FOOL', audio: '/assets/audio/mr-wolf/vocabulary/fool.mp3'},
        {english: 'MR_WOLF.FREAK', translation: 'MR_WOLF.TRANSLATED.FREAK', audio: '/assets/audio/mr-wolf/vocabulary/freak.mp3'},
        {english: 'MR_WOLF.GOOD_AFTERNOON', translation: 'MR_WOLF.TRANSLATED.GOOD_AFTERNOON', audio: '/assets/audio/mr-wolf/vocabulary/good_afternoon.mp3'},
        {english: 'MR_WOLF.GOOD_EVENING', translation: 'MR_WOLF.TRANSLATED.GOOD_EVENING', audio: '/assets/audio/mr-wolf/vocabulary/good_evening.mp3'},
        {english: 'MR_WOLF.GOOD_MORNING', translation: 'MR_WOLF.TRANSLATED.GOOD_MORNING', audio: '/assets/audio/mr-wolf/vocabulary/good_morning.mp3'},
        {english: 'MR_WOLF.GORGEOUS', translation: 'MR_WOLF.TRANSLATED.GORGEOUS', audio: '/assets/audio/mr-wolf/vocabulary/gorgeous.mp3'},
        {english: 'MR_WOLF.HEADACHE', translation: 'MR_WOLF.TRANSLATED.HEADACHE', audio: '/assets/audio/mr-wolf/vocabulary/headache.mp3'},
        {english: 'MR_WOLF.HEART', translation: 'MR_WOLF.TRANSLATED.HEART', audio: '/assets/audio/mr-wolf/vocabulary/heart.mp3'},
        {english: 'MR_WOLF.HEATING', translation: 'MR_WOLF.TRANSLATED.HEATING', audio: '/assets/audio/mr-wolf/vocabulary/heating.mp3'},
        {english: 'MR_WOLF.HOLE', translation: 'MR_WOLF.TRANSLATED.HOLE', audio: '/assets/audio/mr-wolf/vocabulary/hole.mp3'},
        {english: 'MR_WOLF.HOPELESS', translation: 'MR_WOLF.TRANSLATED.HOPELESS', audio: '/assets/audio/mr-wolf/vocabulary/hopeless.mp3'},
        {english: 'MR_WOLF.HOT', translation: 'MR_WOLF.TRANSLATED.HOT', audio: '/assets/audio/mr-wolf/vocabulary/hot.mp3'},
        {english: 'MR_WOLF.HUNGRY', translation: 'MR_WOLF.TRANSLATED.HUNGRY', audio: '/assets/audio/mr-wolf/vocabulary/hungry.mp3'},
        {english: 'MR_WOLF.HUNTER', translation: 'MR_WOLF.TRANSLATED.HUNTER', audio: '/assets/audio/mr-wolf/vocabulary/hunter.mp3'},
        {english: 'MR_WOLF.HURT', translation: 'MR_WOLF.TRANSLATED.HURT', audio: '/assets/audio/mr-wolf/vocabulary/hurt.mp3'},
        {english: 'MR_WOLF.ICE_LOLLY_STICK', translation: 'MR_WOLF.TRANSLATED.ICE_LOLLY_STICK', audio: '/assets/audio/mr-wolf/vocabulary/ice_lolly_stick.mp3'},
        {english: 'MR_WOLF.JOY', translation: 'MR_WOLF.TRANSLATED.JOY', audio: '/assets/audio/mr-wolf/vocabulary/joy.mp3'},
        {english: 'MR_WOLF.LAZY', translation: 'MR_WOLF.TRANSLATED.LAZY', audio: '/assets/audio/mr-wolf/vocabulary/lazy.mp3'},
        {english: 'MR_WOLF.LIAR', translation: 'MR_WOLF.TRANSLATED.LIAR', audio: '/assets/audio/mr-wolf/vocabulary/liar.mp3'},
        {english: 'MR_WOLF.LITTLE_RED_RIDING_HOOD', translation: 'MR_WOLF.TRANSLATED.LITTLE_RED_RIDING_HOOD', audio: '/assets/audio/mr-wolf/vocabulary/little_red_riding_hood.mp3'},
        {english: 'MR_WOLF.MONUMENT', translation: 'MR_WOLF.TRANSLATED.MONUMENT', audio: '/assets/audio/mr-wolf/vocabulary/monument.mp3'},
        {english: 'MR_WOLF.MOODY', translation: 'MR_WOLF.TRANSLATED.MOODY', audio: '/assets/audio/mr-wolf/vocabulary/moody.mp3'},
        {english: 'MR_WOLF.NOON', translation: 'MR_WOLF.TRANSLATED.NOON', audio: '/assets/audio/mr-wolf/vocabulary/noon.mp3'},
        {english: 'MR_WOLF.PAIN', translation: 'MR_WOLF.TRANSLATED.PAIN', audio: '/assets/audio/mr-wolf/vocabulary/pain.mp3'},
        {english: 'MR_WOLF.POLICEMAN', translation: 'MR_WOLF.TRANSLATED.POLICEMAN', audio: '/assets/audio/mr-wolf/vocabulary/policeman.mp3'},
        {english: 'MR_WOLF.POLITE', translation: 'MR_WOLF.TRANSLATED.POLITE', audio: '/assets/audio/mr-wolf/vocabulary/polite.mp3'},
        {english: 'MR_WOLF.POSTMAN', translation: 'MR_WOLF.TRANSLATED.POSTMAN', audio: '/assets/audio/mr-wolf/vocabulary/postman.mp3'},
        {english: 'MR_WOLF.PRESENT', translation: 'MR_WOLF.TRANSLATED.PRESENT', audio: '/assets/audio/mr-wolf/vocabulary/present.mp3'},
        {english: 'MR_WOLF.RAINBOW', translation: 'MR_WOLF.TRANSLATED.RAINBOW', audio: '/assets/audio/mr-wolf/vocabulary/rainbow.mp3'},
        {english: 'MR_WOLF.RAINY', translation: 'MR_WOLF.TRANSLATED.RAINY', audio: '/assets/audio/mr-wolf/vocabulary/rainy.mp3'},
        {english: 'MR_WOLF.REAL', translation: 'MR_WOLF.TRANSLATED.REAL', audio: '/assets/audio/mr-wolf/vocabulary/real.mp3'},
        {english: 'MR_WOLF.RESPONSIBLE', translation: 'MR_WOLF.TRANSLATED.RESPONSIBLE', audio: '/assets/audio/mr-wolf/vocabulary/responsible.mp3'},
        {english: 'MR_WOLF.ROOF', translation: 'MR_WOLF.TRANSLATED.ROOF', audio: '/assets/audio/mr-wolf/vocabulary/roof.mp3'},
        {english: 'MR_WOLF.SAD', translation: 'MR_WOLF.TRANSLATED.SAD', audio: '/assets/audio/mr-wolf/vocabulary/sad.mp3'},
        {english: 'MR_WOLF.SALARY', translation: 'MR_WOLF.TRANSLATED.SALARY', audio: '/assets/audio/mr-wolf/vocabulary/salary.mp3'},
        {english: 'MR_WOLF.SCARY', translation: 'MR_WOLF.TRANSLATED.SCARY', audio: '/assets/audio/mr-wolf/vocabulary/scary.mp3'},
        {english: 'MR_WOLF.SICK', translation: 'MR_WOLF.TRANSLATED.SICK', audio: '/assets/audio/mr-wolf/vocabulary/sick.mp3'},
        {english: 'MR_WOLF.SIGN', translation: 'MR_WOLF.TRANSLATED.SIGN', audio: '/assets/audio/mr-wolf/vocabulary/sign.mp3'},
        {english: 'MR_WOLF.SKY', translation: 'MR_WOLF.TRANSLATED.SKY', audio: '/assets/audio/mr-wolf/vocabulary/sky.mp3'},
        {english: 'MR_WOLF.SORE_THROAT', translation: 'MR_WOLF.TRANSLATED.SORE_THROAT', audio: '/assets/audio/mr-wolf/vocabulary/sore_throat.mp3'},
        {english: 'MR_WOLF.SOUL', translation: 'MR_WOLF.TRANSLATED.SOUL', audio: '/assets/audio/mr-wolf/vocabulary/soul.mp3'},
        {english: 'MR_WOLF.STOMACH_ACHE', translation: 'MR_WOLF.TRANSLATED.STOMACH_ACHE', audio: '/assets/audio/mr-wolf/vocabulary/stomach_ache.mp3'},
        {english: 'MR_WOLF.STRAW', translation: 'MR_WOLF.TRANSLATED.STRAW', audio: '/assets/audio/mr-wolf/vocabulary/straw.mp3'},
        {english: 'MR_WOLF.SUNNY', translation: 'MR_WOLF.TRANSLATED.SUNNY', audio: '/assets/audio/mr-wolf/vocabulary/sunny.mp3'},
        {english: 'MR_WOLF.SWEET_TOOTH', translation: 'MR_WOLF.TRANSLATED.SWEET_TOOTH', audio: '/assets/audio/mr-wolf/vocabulary/sweet_tooth.mp3'},
        {english: 'MR_WOLF.TAXI', translation: 'MR_WOLF.TRANSLATED.TAXI', audio: '/assets/audio/mr-wolf/vocabulary/taxi.mp3'},
        {english: 'MR_WOLF.TO_BE_AFRAID_OF_SOMEONE', translation: 'MR_WOLF.TRANSLATED.TO_BE_AFRAID_OF_SOMEONE', audio: '/assets/audio/mr-wolf/vocabulary/to_be_afraid_of_someone.mp3'},
        {english: 'MR_WOLF.TO_BE_LOST', translation: 'MR_WOLF.TRANSLATED.TO_BE_LOST', audio: '/assets/audio/mr-wolf/vocabulary/to_be_lost.mp3'},
        {english: 'MR_WOLF.TO_BLOW_DOWN', translation: 'MR_WOLF.TRANSLATED.TO_BLOW_DOWN', audio: '/assets/audio/mr-wolf/vocabulary/to_blow_down.mp3'},
        {english: 'MR_WOLF.TO_BUILD', translation: 'MR_WOLF.TRANSLATED.TO_BUILD', audio: '/assets/audio/mr-wolf/vocabulary/to_build.mp3'},
        {english: 'MR_WOLF.TO_CLIMB', translation: 'MR_WOLF.TRANSLATED.TO_CLIMB', audio: '/assets/audio/mr-wolf/vocabulary/to_climb.mp3'},
        {english: 'MR_WOLF.TO_CROSS', translation: 'MR_WOLF.TRANSLATED.TO_CROSS', audio: '/assets/audio/mr-wolf/vocabulary/to_cross.mp3'},
        {english: 'MR_WOLF.TO_DREAM', translation: 'MR_WOLF.TRANSLATED.TO_DREAM', audio: '/assets/audio/mr-wolf/vocabulary/to_dream.mp3'},
        {english: 'MR_WOLF.TO_EAT_UP', translation: 'MR_WOLF.TRANSLATED.TO_EAT_UP', audio: '/assets/audio/mr-wolf/vocabulary/to_eat_up.mp3'},
        {english: 'MR_WOLF.TO_FORGET', translation: 'MR_WOLF.TRANSLATED.TO_FORGET', audio: '/assets/audio/mr-wolf/vocabulary/to_forget.mp3'},
        {english: 'MR_WOLF.TO_GET_READY', translation: 'MR_WOLF.TRANSLATED.TO_GET_READY', audio: '/assets/audio/mr-wolf/vocabulary/to_get_ready.mp3'},
        {english: 'MR_WOLF.TO_HOWL', translation: 'MR_WOLF.TRANSLATED.TO_HOWL', audio: '/assets/audio/mr-wolf/vocabulary/to_howl.mp3'},
        {english: 'MR_WOLF.TO_HURT', translation: 'MR_WOLF.TRANSLATED.TO_HURT', audio: '/assets/audio/mr-wolf/vocabulary/to_hurt.mp3'},
        {english: 'MR_WOLF.TO_JUMP', translation: 'MR_WOLF.TRANSLATED.TO_JUMP', audio: '/assets/audio/mr-wolf/vocabulary/to_jump.mp3'},
        {english: 'MR_WOLF.TO_LEAVE', translation: 'MR_WOLF.TRANSLATED.TO_LEAVE', audio: '/assets/audio/mr-wolf/vocabulary/to_leave.mp3'},
        {english: 'MR_WOLF.TO_LEAVE_HOME', translation: 'MR_WOLF.TRANSLATED.TO_LEAVE_HOME', audio: '/assets/audio/mr-wolf/vocabulary/to_leave_home.mp3'},
        {english: 'MR_WOLF.TO_LOOK_FOR', translation: 'MR_WOLF.TRANSLATED.TO_LOOK_FOR', audio: '/assets/audio/mr-wolf/vocabulary/to_look_for.mp3'},
        {english: 'MR_WOLF.TO_MISS_SOMEONE', translation: 'MR_WOLF.TRANSLATED.TO_MISS_SOMEONE', audio: '/assets/audio/mr-wolf/vocabulary/to_miss_someone.mp3'},
        {english: 'MR_WOLF.TO_PACK', translation: 'MR_WOLF.TRANSLATED.TO_PACK', audio: '/assets/audio/mr-wolf/vocabulary/to_pack.mp3'},
        {english: 'MR_WOLF.TO_PRETEND', translation: 'MR_WOLF.TRANSLATED.TO_PRETEND', audio: '/assets/audio/mr-wolf/vocabulary/to_pretend.mp3'},
        {english: 'MR_WOLF.TO_REMEMBER', translation: 'MR_WOLF.TRANSLATED.TO_REMEMBER', audio: '/assets/audio/mr-wolf/vocabulary/to_remember.mp3'},
        {english: 'MR_WOLF.TO_RUN_AFTER_SOMEONE', translation: 'MR_WOLF.TRANSLATED.TO_RUN_AFTER_SOMEONE', audio: '/assets/audio/mr-wolf/vocabulary/to_run_after_someone.mp3'},
        {english: 'MR_WOLF.TO_RUN_AWAY', translation: 'MR_WOLF.TRANSLATED.TO_RUN_AWAY', audio: '/assets/audio/mr-wolf/vocabulary/to_run_away.mp3'},
        {english: 'MR_WOLF.TO_SPEND', translation: 'MR_WOLF.TRANSLATED.TO_SPEND', audio: '/assets/audio/mr-wolf/vocabulary/to_spend.mp3'},
        {english: 'MR_WOLF.TO_STAY', translation: 'MR_WOLF.TRANSLATED.TO_STAY', audio: '/assets/audio/mr-wolf/vocabulary/to_stay.mp3'},
        {english: 'MR_WOLF.TO_TICK', translation: 'MR_WOLF.TRANSLATED.TO_TICK', audio: '/assets/audio/mr-wolf/vocabulary/to_tick.mp3'},
        {english: 'MR_WOLF.TO_TRAVEL', translation: 'MR_WOLF.TRANSLATED.TO_TRAVEL', audio: '/assets/audio/mr-wolf/vocabulary/to_travel.mp3'},
        {english: 'MR_WOLF.TO_TRY_ON', translation: 'MR_WOLF.TRANSLATED.TO_TRY_ON', audio: '/assets/audio/mr-wolf/vocabulary/to_try_on.mp3'},
        {english: 'MR_WOLF.TO_VISIT', translation: 'MR_WOLF.TRANSLATED.TO_VISIT', audio: '/assets/audio/mr-wolf/vocabulary/to_visit.mp3'},
        {english: 'MR_WOLF.TO_WATER_FLOWERS', translation: 'MR_WOLF.TRANSLATED.TO_WATER_FLOWERS', audio: '/assets/audio/mr-wolf/vocabulary/to_water_flowers.mp3'},
        {english: 'MR_WOLF.TRAIN', translation: 'MR_WOLF.TRANSLATED.TRAIN', audio: '/assets/audio/mr-wolf/vocabulary/train.mp3'},
        {english: 'MR_WOLF.UFO', translation: 'MR_WOLF.TRANSLATED.UFO', audio: '/assets/audio/mr-wolf/vocabulary/ufo.mp3'},
        {english: 'MR_WOLF.UMBRELLA', translation: 'MR_WOLF.TRANSLATED.UMBRELLA', audio: '/assets/audio/mr-wolf/vocabulary/umbrella.mp3'},
        {english: 'MR_WOLF.WALL', translation: 'MR_WOLF.TRANSLATED.WALL', audio: '/assets/audio/mr-wolf/vocabulary/wall.mp3'},
        {english: 'MR_WOLF.WEAK', translation: 'MR_WOLF.TRANSLATED.WEAK', audio: '/assets/audio/mr-wolf/vocabulary/weak.mp3'},
        {english: 'MR_WOLF.WINDOW', translation: 'MR_WOLF.TRANSLATED.WINDOW', audio: '/assets/audio/mr-wolf/vocabulary/window.mp3'},
        {english: 'MR_WOLF.STOMACH_ACHE', translation: 'MR_WOLF.TRANSLATED.STOMACH_ACHE', audio: '/assets/audio/mr-wolf/vocabulary/stomach_ache.mp3'},
        {english: 'MR_WOLF.BROKEN_LEG', translation: 'MR_WOLF.TRANSLATED.BROKEN_LEG', audio: '/assets/audio/mr-wolf/vocabulary/broken_leg.mp3'},
        {english: 'MR_WOLF.SORE_THROAT', translation: 'MR_WOLF.TRANSLATED.SORE_THROAT', audio: '/assets/audio/mr-wolf/vocabulary/sore_throat.mp3'},
      ]
    };
    this.config = {
      'egg-on-a-trip': {
        url: 'egg-on-a-trip',
        title: 'Egg on a trip',
        duration: 50,
        pointsAvailable: 153,
        parts: [
          {translation: 'INTERFACE.TUTORIAL',  key: 'tutorial'},
          {translation: 'INTERFACE.INTRO',  key: 'intro'},
          {translation: 'INTERFACE.PART_1', key: '1'},
          {translation: 'INTERFACE.PART_2', key: '2'},
          {translation: 'INTERFACE.PART_3', key: '3'},
          {translation: 'INTERFACE.PART_4', key: '4'},
          {translation: 'INTERFACE.PART_5', key: '5'},
          {translation: 'INTERFACE.PART_6', key: '6'},
          {translation: 'INTERFACE.PART_7', key: '7'},
          {translation: 'INTERFACE.FINISH', key: 'finish'}
        ],
        audio: {
          correct: ['X_wow', 'X_good job', 'X_well done', 'X_excellent'],
          wrong: ['X_pazvuk 1', 'X_pazvuk 2']
        },
        extraContent: [
          {translation: 'INTERFACE.EXTRA', key: 'extra'},
          // {translation: 'INTERFACE.VOCABULARY', callback: () => this.openVocabularyModal()},
        ],
      },
      'american-dream-zs': {
        url: 'american-dream-zs',
        title: 'American dream',
        duration: 60,
        pointsAvailable: 161,
        parts: [
          {translation: 'INTERFACE.INTRO', key: 'intro'},
          {translation: 'INTERFACE.VIDEO', key: 'video'},
          {translation: 'INTERFACE.TASK_1', key: '1'},
          {translation: 'INTERFACE.TASK_2', key: '2'},
          {translation: 'INTERFACE.TASK_3', key: '3'},
          {translation: 'INTERFACE.TASK_4', key: '4'},
          {translation: 'INTERFACE.TASK_5', key: '5'},
          {translation: 'INTERFACE.TASK_6', key: '6'},
          {translation: 'INTERFACE.TASK_7', key: '7'},
          {translation: 'INTERFACE.TASK_8', key: '8'},
          {translation: 'INTERFACE.FINISH', key: 'finish'},
        ],
        extraContent: [
          {translation: 'INTERFACE.EXTRA', key: 'extra'},
          // {translation: 'INTERFACE.VOCABULARY', callback: () => this.openVocabularyModal()},
        ],
        audio: {
          correct: ['/american-dream/pazvuky/good job', '/american-dream/pazvuky/hey super', '/american-dream/pazvuky/nice joob', '/american-dream/pazvuky/well done', '/american-dream/pazvuky/yeah', '/american-dream/pazvuky/yes'],
          wrong: ['/american-dream/pazvuky/i am sorry thats incorrect', '/american-dream/pazvuky/O-ouh', '/american-dream/pazvuky/Ou dear', '/american-dream/pazvuky/pazvuk wrong', '/american-dream/pazvuky/try again', '/american-dream/pazvuky/try harder', '/american-dream/pazvuky/you can do better']
        }
      },
      'american-dream-ss': {
        url: 'american-dream-ss',
        title: 'American dream',
        duration: 60,
        pointsAvailable: 161,
        parts: [
          {translation: 'INTERFACE.INTRO', key: 'intro'},
          {translation: 'INTERFACE.VIDEO', key: 'video'},
          {translation: 'INTERFACE.TASK_1', key: '1'},
          {translation: 'INTERFACE.TASK_2', key: '2'},
          {translation: 'INTERFACE.TASK_3', key: '3'},
          {translation: 'INTERFACE.TASK_4', key: '4'},
          {translation: 'INTERFACE.TASK_5', key: '5'},
          {translation: 'INTERFACE.TASK_6', key: '6'},
          {translation: 'INTERFACE.TASK_7', key: '7'},
          {translation: 'INTERFACE.TASK_8', key: '8'},
          {translation: 'INTERFACE.FINISH', key: 'finish'},
        ],
        extraContent: [
          {translation: 'INTERFACE.EXTRA', key: 'extra'},
          // {translation: 'INTERFACE.VOCABULARY', callback: () => this.openVocabularyModal()},
        ],
        audio: {
          correct: ['/american-dream/pazvuky/good job', '/american-dream/pazvuky/hey super', '/american-dream/pazvuky/nice joob', '/american-dream/pazvuky/well done', '/american-dream/pazvuky/yeah', '/american-dream/pazvuky/yes'],
          wrong: ['/american-dream/pazvuky/i am sorry thats incorrect', '/american-dream/pazvuky/O-ouh', '/american-dream/pazvuky/Ou dear', '/american-dream/pazvuky/pazvuk wrong', '/american-dream/pazvuky/try again', '/american-dream/pazvuky/try harder', '/american-dream/pazvuky/you can do better']
        }
      },
      'november-89': {
        url: 'november-89',
        title: 'November 89',
        duration: 65,
        pointsAvailable: 21,
        parts: [
          {translation: 'Tutorial',  key: 'tutorial'},
          {translation: 'Úvod', key: 'intro'},
          {translation: 'Časť 1', key: '1'},
          {translation: 'Časť 2', key: '2'},
          {translation: 'Časť 3', key: '3'},
          {translation: 'Časť 4', key: '4'},
          {translation: 'Časť 5', key: '5'},
          {translation: 'Časť 6', key: '6'},
          {translation: 'Časť 7', key: '7'},
          {translation: 'Časť 8', key: '8'},
          {translation: 'Časť 9', key: '9'},
          {translation: 'Časť 10', key: '10'},
          {translation: 'Titulky', key: 'finish'},
        ],
        extraContent: [
          {translation: 'Extra', key: 'extra'},
        ],
        audio: {
          correct: ['/november-89/N89_pazvuk_kladny1', '/november-89/N89_pazvuk_kladny2', '/november-89/N89_pazvuk_kladny3', '/november-89/N89_pazvuk_kladny4', '/november-89/N89_pazvuk_kladny5'],
          wrong: ['/november-89/N89_pazvuk_negativ1', '/november-89/N89_pazvuk_negativ2', '/november-89/N89_pazvuk_negativ3', '/november-89/N89_pazvuk_negativ4', '/november-89/N89_pazvuk_negativ5'],
        }
      },
      'mr-wolf': {
        url: 'mr-wolf',
        title: 'Mr Wolf',
        duration: 60,
        pointsAvailable: 161,
        parts: [
          {translation: 'Tutorial',  key: 'tutorial'},
          {translation: 'INTERFACE.PART_1', key: '1'},
          {translation: 'INTERFACE.PART_2', key: '2'},
          {translation: 'INTERFACE.PART_3', key: '3'},
          {translation: 'INTERFACE.PART_4', key: '4'},
          {translation: 'INTERFACE.PART_5', key: '5'},
          {translation: 'INTERFACE.PART_6', key: '6'},
          {translation: 'INTERFACE.PART_7', key: '7'},
          {translation: 'INTERFACE.PART_8', key: '8'},
          {translation: 'INTERFACE.EXERCISES', key: 'exercises'},
          {translation: 'INTERFACE.FINISH', key: 'finish'},
        ],
        extraContent: [
          {translation: 'INTERFACE.EXTRA', key: 'extra'},
          // {translation: 'INTERFACE.VOCABULARY', callback: () => this.openVocabularyModal()},
        ],
        audio: {
          correct: ['/mr-wolf/positive/positive_1', '/mr-wolf/positive/positive_2', '/mr-wolf/positive/positive_3', '/mr-wolf/positive/positive_4', '/mr-wolf/positive/positive_5', '/mr-wolf/positive/positive_6'],
          wrong: ['/mr-wolf/negative/negative_1', '/mr-wolf/negative/negative_2', '/mr-wolf/negative/negative_3', '/mr-wolf/negative/negative_4', '/mr-wolf/negative/negative_5', '/mr-wolf/negative/negative_6'],
        }
      },
      hrdinovia: {
        url: 'hrdinovia',
        title: 'Hrdinovia',
        duration: 60,
        pointsAvailable: 50,
        parts: [
          {translation: 'Tutorial',  key: 'tutorial'},
          {translation: 'Časť 1', key: '1'},
          {translation: 'Časť 2', key: '2'},
          {translation: 'Časť 3', key: '3'},
          {translation: 'Časť 4', key: '4'},
          {translation: 'Časť 5', key: '5'},
          {translation: 'Časť 6', key: '6'},
          {translation: 'Časť 7', key: '7'},
          {translation: 'Cvičenia', key: 'exercises'},
          {translation: 'Vyhodnotenie', key: 'finish'},
        ],
        extraContent: [
          {translation: 'Extra', key: 'extra'},
        ],
        audio: {
          correct: ['hrdinovia/positiv1', 'hrdinovia/positiv2', 'hrdinovia/positiv3', 'hrdinovia/positiv4', 'hrdinovia/positiv5', 'hrdinovia/positiv6', 'hrdinovia/positiv7'],
          wrong: ['hrdinovia/negativ1', 'hrdinovia/negativ2', 'hrdinovia/negativ3', 'hrdinovia/negativ4', 'hrdinovia/negativ5', 'hrdinovia/negativ6'],
        }
      },
    };

    this.state = this.defaultState;
    this.setStoryByUrl();
    this.stateSubscription = this.stateService.stateChanged.subscribe(data => {
      this.state = data;
      if (this.state.token === ''){
        this.router.navigate(['/stories']);
      }else{
        this.partGuard();
        this.translate.use(this.state.lang);
        if (this.state.type === 'teacher'){
          this.renderer.addClass(document.body, 'teacher-access');
        }
      }
      this.stateSubscription.unsubscribe();
    });
    this.stateService.setStateOrSetDefault(this.state);
    this.verificationSubscription = this.verificationChanged.subscribe(data => {
      if (data['status'] === true) {
        const ticket = data['data']['ticket'];
        const token = data['data']['token'];
        const show = data['data']['show'];
        const lang = data['data']['lang'];
        this.stateService.setStateByKey(token, 'token');
        this.stateService.setStateByKey(show, 'active_story');
        this.stateService.setStateByKey(this.getStoryConfig(show).parts[0], 'active_part');
        this.stateService.setStateByKey(ticket, 'ticket');
        this.stateService.setStateByKey(data['data']['type'], 'type');
        this.stateService.setStateByKey(data['data']['expiration'], 'expiration');
        this.stateService.setStateByKey(data['data']['title'], 'title');
        this.stateService.setStateByKey(lang, 'lang');
        this.translate.use(lang);
        if (ticket && (this.isEmpty(ticket.name) || this.isEmpty(ticket.surname) || this.isEmpty(ticket.class))){
          this.openRegistrationDialog();
        } else if (data['data']['type'] === 'teacher') {
          if (this.isPresentationExpired()) {
            this.openClassDialog();
          }
        }
      } else if (data['status'] === false && data['message'] === 'EXPIRED'){
        this.clearStates();
        this.dialog.open(ExpirationModalComponent, {data, disableClose: true });
      }
    });
    this.verifiCode(this.state.token);
  }

  setDefaultState(){ // PLAY AGAIN;
    const showString = this.state.active_story;
    const tokenString = this.state.token;
    const typeString = this.state.type;
    const langString = this.state.lang;
    const firstPart = this.getStoryConfig(showString).parts[0];
    this.state = {
      active_story: showString,
      active_part: {translation: firstPart.translation,  key: firstPart.key},
      tabIndex: 0,
      active_points: 0,
      token: tokenString,
      type: typeString,
      lang: langString,
    };
    this.stateService.setState(this.state);
    this.stateService.clearViewStates();
    if (typeString === 'teacher'){
      this.renderer.addClass(document.body, 'teacher-access');
    }
    this.router.navigate(['/stories', this.state.active_story, this.state.active_part.key]);
    window.location.reload();
  }

  setStoryByKey(key){
    if (this.config[key]){
      this.story = this.config[key];
      this.titleService.setTitle(this.story.title);
    }
  }

  setStoryByUrl(){
    const routeArray = window.location.href.split('/');
    const activeStory = routeArray.slice(-2)[0];
    this.setStoryByKey(activeStory);
  }

  setDefaultPartStateIfNotExists(defaultState){
    this.stateService.setViewStateOrSetDefault(defaultState);
  }

  addPoints(points: number){
    this.state.active_points = +this.state.active_points + points;
    this.stateService.setState(this.state);
  }

  setPartState(value, key){
    this.stateService.setViewStateValue(value, key);
  }

  getPartState(){
    return this.stateService.getViewState();
  }

  setPartAsDone(){
    this.setStoryByUrl();
    const routeArray = this.router.url.split('/');
    const donePartKey = routeArray.slice(-1)[0];
    const donePart = this.getKeyByValue(this.story.parts, donePartKey);
    this.state.active_part = this.story.parts[+donePart + 1];

    this.stateService.setState(this.state);
    this.stateService.setViewStateValue(true, 'isDone');
    this.router.navigate(['/stories', this.story.url, this.state.active_part.key]);
    if (this.state.type === 'pupil'){
      this.saveState();
    }
  }

  partGuard(){
    const routeArray = this.location.path().split('/');
    const activePart = routeArray.slice(-1)[0];
    if (this.state.type === 'teacher' || activePart === 'profile'){
      return true;
    }else if (activePart !== this.state.active_part.key){
      this.router.navigate(['/stories', this.state.active_story, this.state.active_part.key]);
    }
  }

  goBackToTest(){
    this.router.navigate(['/stories', this.state.active_story, this.state.active_part.key]);
  }

  getKeyByValue(object, value){
    return Object.keys(object).find(key => object[key].key === value);
  }

  verifiCode(code){
    if (code !== '') {
      this.http.get(environment.apiUrl + '/api/online/get-ticket/' + code).subscribe((result) => {
        this.verificationChanged.next(result);
      });
    }
  }

  public isPresentationExpired(): boolean{
    let isExpired = false;
    const localState = this.stateService.getState();
    const milliseconds: number = new Date().getTime() - new Date(localState.presentation_expiration).getTime();
    const hours: number = (milliseconds / (1000 * 60 * 60));
    if (!localState.presentation_expiration || hours > 26) { // todo UTC FIX
      isExpired = true;
    }
    return isExpired;
  }

  saveState(){
    const stateToSave = {
      points: this.state.active_points,
      meta: this.state,
    };
    this.http.post(environment.apiUrl + '/api/online/update-ticket/' + this.stateService.getState().token, stateToSave).subscribe((result) => {});
  }

  saveAnswer(key: string, answer: string){
    const stateToSave = {
      answer: {
        key,
        answer
      },
    };
    this.http.post(environment.apiUrl + '/api/online/update-ticket/' + this.stateService.getState().token, stateToSave).subscribe((result) => {});
  }

  clearStates(){
    this.state = this.defaultState;
    this.stateService.clearViewStates();
    this.stateService.clearState();
  }

  public getStoryConfig(name: string){
    if (this.config[name]){
      return this.config[name];
    }
    return false;
  }

  public openRegistrationDialog(){
    this.dialog.open(LoginModalComponent, { disableClose: true });
  }

  public openClassDialog(){
    this.dialog.open(AddClassComponent, { disableClose: true });
  }

  public isEmpty(str) {
    return (!str || 0 === str.length);
  }

  public openVocabularyModal(){
    this.dialog.open(VocabularyModalComponent, {data: this.vocabulary[this.state.active_story]});
  }

  public hasStoryVocabulary(){
    if (this.vocabulary[this.state.active_story]){
      return true;
    }
    return false;
  }
}
