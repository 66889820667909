import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-part-four',
  templateUrl: './part-four.component.html',
  styleUrls: ['./part-four.component.css']
})
export class PartFourComponent implements OnInit {

  public test: Test;
  public vocabulary: Test;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
      private storyService: StoryService,
      private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_4_1', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka2.mp4', id: '462662166'};
    this.video2 = {src: '/assets/video/Egg On Trip_V5_4cast.mp4', id: '462793531'};
    this.video3 = {src: '/assets/video/4_cvicenie_09_V1.mp4',  id: '462658375'};
    this.info2 = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_4_2', audio: ''},
    ];
    this.test = {
      name: 'Set right order',
      description: 'Here’s the first exercise to check you’ve been listening carefully. Don’t worry, I’m sure it’ll be easy for you. Just match the colours to the right words. You’ll need to answer correctly to carry on watching our video.As we play together, you can collect eggs for every correct answer. If you collect enough eggs, at the end of our adventure, you can bake a lovely cake for all my animal friends!',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'crayfish', translation: 'EGG_ON_TRIP.A_CRAYFISH', audio: '/assets/audio/a crayfish.mp3', img: '/assets/img/crayfish.png'},
          {key: 'donkey', translation: 'EGG_ON_TRIP.A_DONKEY', audio: '/assets/audio/a donkey.mp3', img: '/assets/img/donkey.png'},
          {key: 'duck', translation: 'EGG_ON_TRIP.A_DUCK', audio: '/assets/audio/a duck.mp3', img: '/assets/img/duck.png'},
          {key: 'cow', translation: 'EGG_ON_TRIP.A_COW', audio: '/assets/audio/a cow.mp3', img: '/assets/img/cow.png'},
          {key: 'rooster', translation: 'EGG_ON_TRIP.A_ROOSTER', audio: '/assets/audio/a rooster.mp3', img: '/assets/img/rooster.png'},
        ],
        answers: [
          {key: 'crayfish', translation: '1', audio: '/assets/audio/a crayfish.mp3', img: '/assets/img/do_vedra.jpg'},
          {key: 'donkey', translation: '2', audio: '/assets/audio/a donkey.mp3', img: '/assets/img/v_peci.jpg'},
          {key: 'duck', translation: '3', audio: '/assets/audio/a duck.mp3', img: '/assets/img/pod_schodami.jpg'},
          {key: 'cow', translation: '4', audio: '/assets/audio/a cow.mp3', img: '/assets/img/za_dverami.jpg'},
          {key: 'rooster', translation: '5', audio: '/assets/audio/a rooster.mp3', img: '/assets/img/pri_pavukovi.jpg'},
        ]
      }
    };
    this.vocabulary = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'To scream for help',
          image: '',
          options: [
            {key: 'to scream for help', translation: 'EGG_ON_TRIP.TRANSLATED.TO_SCREAM_FOR_HELP', audio: '/assets/audio/to scream for help.mp3', img: ''},
            {key: 'to lose your way', translation: 'EGG_ON_TRIP.TRANSLATED.TO_LOSE_YOUR_WAY', audio: '/assets/audio/to lose your way.mp3', img: ''},
            {key: 'to find a light', translation: 'EGG_ON_TRIP.TRANSLATED.TO_FIND_A_LIGHT', audio: '/assets/audio/to find a light.mp3', img: ''},
            {key: 'mother nature', translation: 'EGG_ON_TRIP.TRANSLATED.MOTHER_NATURE', audio: '/assets/audio/mother nature.mp3', img: ''},
            {key: 'we are saved', translation: 'EGG_ON_TRIP.TRANSLATED.WE_ARE_SAVED', audio: '/assets/audio/we are saved.mp3', img: ''},
          ],
          answers: ['to scream for help'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'To lose your way',
          image: '',
          options: [
            {key: 'to scream for help', translation: 'EGG_ON_TRIP.TRANSLATED.TO_SCREAM_FOR_HELP', audio: '/assets/audio/to scream for help.mp3', img: ''},
            {key: 'to lose your way', translation: 'EGG_ON_TRIP.TRANSLATED.TO_LOSE_YOUR_WAY', audio: '/assets/audio/to lose your way.mp3', img: ''},
            {key: 'to find a light', translation: 'EGG_ON_TRIP.TRANSLATED.TO_FIND_A_LIGHT', audio: '/assets/audio/to find a light.mp3', img: ''},
            {key: 'mother nature', translation: 'EGG_ON_TRIP.TRANSLATED.MOTHER_NATURE', audio: '/assets/audio/mother nature.mp3', img: ''},
            {key: 'we are saved', translation: 'EGG_ON_TRIP.TRANSLATED.WE_ARE_SAVED', audio: '/assets/audio/we are saved.mp3', img: ''},
          ],
          answers: ['to lose your way'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'To find a light',
          image: '',
          options: [
            {key: 'to scream for help', translation: 'EGG_ON_TRIP.TRANSLATED.TO_SCREAM_FOR_HELP', audio: '/assets/audio/to scream for help.mp3', img: ''},
            {key: 'to lose your way', translation: 'EGG_ON_TRIP.TRANSLATED.TO_LOSE_YOUR_WAY', audio: '/assets/audio/to lose your way.mp3', img: ''},
            {key: 'to find a light', translation: 'EGG_ON_TRIP.TRANSLATED.TO_FIND_A_LIGHT', audio: '/assets/audio/to find a light.mp3', img: ''},
            {key: 'mother nature', translation: 'EGG_ON_TRIP.TRANSLATED.MOTHER_NATURE', audio: '/assets/audio/mother nature.mp3', img: ''},
            {key: 'we are saved', translation: 'EGG_ON_TRIP.TRANSLATED.WE_ARE_SAVED', audio: '/assets/audio/we are saved.mp3', img: ''},
          ],
          answers: ['to find a light'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Mother nature',
          image: '',
          options: [
            {key: 'to scream for help', translation: 'EGG_ON_TRIP.TRANSLATED.TO_SCREAM_FOR_HELP', audio: '/assets/audio/to scream for help.mp3', img: ''},
            {key: 'to lose your way', translation: 'EGG_ON_TRIP.TRANSLATED.TO_LOSE_YOUR_WAY', audio: '/assets/audio/to lose your way.mp3', img: ''},
            {key: 'to find a light', translation: 'EGG_ON_TRIP.TRANSLATED.TO_FIND_A_LIGHT', audio: '/assets/audio/to find a light.mp3', img: ''},
            {key: 'mother nature', translation: 'EGG_ON_TRIP.TRANSLATED.MOTHER_NATURE', audio: '/assets/audio/mother nature.mp3', img: ''},
            {key: 'we are saved', translation: 'EGG_ON_TRIP.TRANSLATED.WE_ARE_SAVED', audio: '/assets/audio/we are saved.mp3', img: ''},
          ],
          answers: ['mother nature'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'We are saved',
          image: '',
          options: [
            {key: 'to scream for help', translation: 'EGG_ON_TRIP.TRANSLATED.TO_SCREAM_FOR_HELP', audio: '/assets/audio/to scream for help.mp3', img: ''},
            {key: 'to lose your way', translation: 'EGG_ON_TRIP.TRANSLATED.TO_LOSE_YOUR_WAY', audio: '/assets/audio/to lose your way.mp3', img: ''},
            {key: 'to find a light', translation: 'EGG_ON_TRIP.TRANSLATED.TO_FIND_A_LIGHT', audio: '/assets/audio/to find a light.mp3', img: ''},
            {key: 'mother nature', translation: 'EGG_ON_TRIP.TRANSLATED.MOTHER_NATURE', audio: '/assets/audio/mother nature.mp3', img: ''},
            {key: 'we are saved', translation: 'EGG_ON_TRIP.TRANSLATED.WE_ARE_SAVED', audio: '/assets/audio/we are saved.mp3', img: ''},
          ],
          answers: ['we are saved'],
          correctAnswer: '',
          audio: '',
        },
      ]
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

}
