import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-part-two',
  templateUrl: './part-two.component.html',
  styleUrls: ['./part-two.component.css']
})
export class PartTwoComponent implements OnInit {

  public state = null;
  public showNextTab = null;
  public test: Test;
  public vocabulary: Test;
  public video;
  public video2;
  public video3;
  public info;
  public info2;
  public timestampKey = null;

  constructor(
      private storyService: StoryService,
      private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_2_1', audio: ''},
    ];
    this.video = {src: '/assets/video/nove slovicka2.mp4', id: '462662166'};
    this.video2 = {src: '/assets/video/Egg On Trip_V5_2cast.mp4', id: '462792109'};
    this.video3 = {src: '/assets/video/2_cvicenie_06_V1.mp4', id: '462658016'};
    this.info2 = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_2_2', audio: ''},
    ];
    this.test = {
      name: '',
      description: '',
      type: 'pairs',
          pairs: {
            options: [
              {key: 'catch', translation: 'EGG_ON_TRIP.CATCH', audio: '/assets/audio/catch.mp3', img: '/assets/img/catch.png'},
              {key: 'sing', translation: 'EGG_ON_TRIP.SING', audio: '/assets/audio/sing.mp3', img: '/assets/img/sing.png'},
              {key: 'jump', translation: 'EGG_ON_TRIP.JUMP', audio: '/assets/audio/jump.mp3', img: '/assets/img/jump.png'},
              {key: 'sleep', translation: 'EGG_ON_TRIP.SLEEP', audio: '/assets/audio/sleep.mp3', img: '/assets/img/sleep.png'},
              {key: 'swim', translation: 'EGG_ON_TRIP.SWIM', audio: '/assets/audio/swim.mp3', img: '/assets/img/swim.png'}
            ],
          answers: [
              {key: 'catch', translation: 'EGG_ON_TRIP.CATCH', audio: '/assets/audio/catch.mp3', img: '/assets/img/catch.png'},
              {key: 'sing', translation: 'EGG_ON_TRIP.SING', audio: '/assets/audio/sing.mp3', img: '/assets/img/sing.png'},
              {key: 'jump', translation: 'EGG_ON_TRIP.JUMP', audio: '/assets/audio/jump.mp3', img: '/assets/img/jump.png'},
              {key: 'sleep', translation: 'EGG_ON_TRIP.SLEEP', audio: '/assets/audio/sleep.mp3', img: '/assets/img/sleep.png'},
              {key: 'swim', translation: 'EGG_ON_TRIP.SWIM', audio: '/assets/audio/swim.mp3', img: '/assets/img/swim.png'}
          ]
      }
    };
    this.vocabulary = {
          name: '',
          description: '',
          type: 'questions',
          questions: [
              {
                  text: 'To go for a walk',
                  image: '',
                  options: [
                      {key: 'to go for a walk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_WALK', audio: '/assets/audio/to go for a walk.mp3', img: ''},
                      {key: 'to jump on someone’s back', translation: 'EGG_ON_TRIP.TRANSLATED.TO_JUMP_ON_SOMEONES_BACK', audio: '/assets/audio/to jump on someones back.mp3', img: ''},
                      {key: 'to catch', translation: 'EGG_ON_TRIP.TRANSLATED.TO_CATCH', audio: '/assets/audio/to catch.mp3', img: ''},
                      {key: 'hold me tight', translation: 'EGG_ON_TRIP.TRANSLATED.HOLD_ME_TIGHT', audio: '/assets/audio/hold me tight.mp3', img: ''},
                      {key: 'listen carefully', translation: 'EGG_ON_TRIP.TRANSLATED.LISTEN_CAREFULLY', audio: '/assets/audio/listen carefully.mp3', img: ''}
                  ],
                  answers: ['to go for a walk'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'To jump on someone’s back',
                  image: '',
                  options: [
                    {key: 'to go for a walk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_WALK', audio: '/assets/audio/to go for a walk.mp3', img: ''},
                    {key: 'to jump on someone’s back', translation: 'EGG_ON_TRIP.TRANSLATED.TO_JUMP_ON_SOMEONES_BACK', audio: '/assets/audio/to jump on someones back.mp3', img: ''},
                    {key: 'to catch', translation: 'EGG_ON_TRIP.TRANSLATED.TO_CATCH', audio: '/assets/audio/to catch.mp3', img: ''},
                    {key: 'hold me tight', translation: 'EGG_ON_TRIP.TRANSLATED.HOLD_ME_TIGHT', audio: '/assets/audio/hold me tight.mp3', img: ''},
                    {key: 'listen carefully', translation: 'EGG_ON_TRIP.TRANSLATED.LISTEN_CAREFULLY', audio: '/assets/audio/listen carefully.mp3', img: ''}
                  ],
                  answers: ['to jump on someone’s back'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'To catch',
                  image: '',
                  options: [
                    {key: 'to go for a walk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_WALK', audio: '/assets/audio/to go for a walk.mp3', img: ''},
                    {key: 'to jump on someone’s back', translation: 'EGG_ON_TRIP.TRANSLATED.TO_JUMP_ON_SOMEONES_BACK', audio: '/assets/audio/to jump on someones back.mp3', img: ''},
                    {key: 'to catch', translation: 'EGG_ON_TRIP.TRANSLATED.TO_CATCH', audio: '/assets/audio/to catch.mp3', img: ''},
                    {key: 'hold me tight', translation: 'EGG_ON_TRIP.TRANSLATED.HOLD_ME_TIGHT', audio: '/assets/audio/hold me tight.mp3', img: ''},
                    {key: 'listen carefully', translation: 'EGG_ON_TRIP.TRANSLATED.LISTEN_CAREFULLY', audio: '/assets/audio/listen carefully.mp3', img: ''}
                  ],
                  answers: ['to catch'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'Hold me tight',
                  image: '',
                  options: [
                    {key: 'to go for a walk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_WALK', audio: '/assets/audio/to go for a walk.mp3', img: ''},
                    {key: 'to jump on someone’s back', translation: 'EGG_ON_TRIP.TRANSLATED.TO_JUMP_ON_SOMEONES_BACK', audio: '/assets/audio/to jump on someones back.mp3', img: ''},
                    {key: 'to catch', translation: 'EGG_ON_TRIP.TRANSLATED.TO_CATCH', audio: '/assets/audio/to catch.mp3', img: ''},
                    {key: 'hold me tight', translation: 'EGG_ON_TRIP.TRANSLATED.HOLD_ME_TIGHT', audio: '/assets/audio/hold me tight.mp3', img: ''},
                    {key: 'listen carefully', translation: 'EGG_ON_TRIP.TRANSLATED.LISTEN_CAREFULLY', audio: '/assets/audio/listen carefully.mp3', img: ''}
                  ],
                  answers: ['hold me tight'],
                  correctAnswer: '',
                  audio: '',
              }, {
                  text: 'Listen carefully',
                  image: '',
                  options: [
                    {key: 'to go for a walk', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_WALK', audio: '/assets/audio/to go for a walk.mp3', img: ''},
                    {key: 'to jump on someone’s back', translation: 'EGG_ON_TRIP.TRANSLATED.TO_JUMP_ON_SOMEONES_BACK', audio: '/assets/audio/to jump on someones back.mp3', img: ''},
                    {key: 'to catch', translation: 'EGG_ON_TRIP.TRANSLATED.TO_CATCH', audio: '/assets/audio/to catch.mp3', img: ''},
                    {key: 'hold me tight', translation: 'EGG_ON_TRIP.TRANSLATED.HOLD_ME_TIGHT', audio: '/assets/audio/hold me tight.mp3', img: ''},
                    {key: 'listen carefully', translation: 'EGG_ON_TRIP.TRANSLATED.LISTEN_CAREFULLY', audio: '/assets/audio/listen carefully.mp3', img: ''}
                  ],
                  answers: ['listen carefully'],
                  correctAnswer: '',
                  audio: '',
              },
          ]
      };
  }

    ngOnInit(): void {
        this.storyService.setDefaultPartStateIfNotExists(this.state);
        this.state = this.storyService.getPartState();
    }

    public onTestStateChange($event){
        this.state.isDone = $event;
    }

    public onVideoPlayed($event){
        this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    }

    public onVocabularyStateChange($event){
        this.showNextTab = true;
    }

    public onTimestampChange($event){
      this.timestampKey = $event;
    }

    nextTab(){
        this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
        this.showNextTab = null;
    }

    public next() {
        this.storyService.setPartAsDone();
    }

}
