<div *ngIf="true" class="white-bcg allow-to-all">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="EXERCISES">
      <div class="text-center">
        <table style="width:100%">
          <tbody>
          <ng-container *ngFor="let download of downloadList">
            <tr *ngIf="storyService.state.lang === download.lang">
              <td style="text-align: center">
                <a [href]="download.src" target="_blank">
                  <img src="/assets/img/EGG-cvicenia_nahlad.png" style="height: 200px;">
                  <br>
                  <span [translate]="download.translation"></span>
                </a>
              </td>
            </tr>
          </ng-container>
          <br>
          <ng-container *ngFor="let download of downloadGlossaryList">
            <tr *ngIf="storyService.state.lang === download.lang">
              <td style="text-align: center">
                <a [href]="download.src" target="_blank">
                  <img src="/assets/img/EGG-glossary_nahlad.png" style="height: 200px;">
                  <br>
                  <span [translate]="download.translation"></span>
                </a>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="VOCABULARY">
      <table class="vocabulary">
        <tbody>
        <tr *ngFor="let item of vocabulary">
          <td class="with-play" (click)="audioService.playAudio(item.audio)">
            <img class="icon-play" src="/assets/img/american-dream/icon-play.png">
            <strong translate>{{ item.english}}</strong>
          </td>
          <td><span translate>{{ item.translation}}</span></td>
        </tbody>
      </table>
    </mat-tab>
  </mat-tab-group>
</div>






