<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed()" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper with-background--shelves">
        <div class="text-center">
          <h1>Chceli by ste žiť v Československu pred rokom 1989?</h1>
          <ul>
            <li><a (click)="onCorrectAnswer();">a) áno</a></li>
            <li><a (click)="onCorrectAnswer();">b) nie</a></li>
          </ul>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
