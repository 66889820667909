import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { Test } from '../../../models/test.model';
import { NovemberService } from '../november.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part1',
  templateUrl: './part1.component.html',
  styleUrls: ['./part1.component.css']
})
export class Part1Component implements OnInit {
  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;
  public test: Test;
  public selected = false;

  constructor(
    private storyService: StoryService,
    private novemberService: NovemberService,
    private audioService: AudioService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: '01_nov89_v1.mp4', id: '477125477'};
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onInfoStateChange(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVideoPlayed(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  onWrongAnswer(){
    this.novemberService.onWrongAnswer();
  }

  onCorrectAnswer(){
    if (!this.selected){
      this.novemberService.onCorrectAnswer(['/november-89/N89_pazvuk_negativ1']);
      this.selected = true;
      setTimeout(() => { this.next(); }, 3000);
    }
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [1];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.audioService.playAudio('/assets/audio/november-89/N89_1.mp3');
    }
  }
}
