<div *ngIf="true">
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="OTÁZKA" [disabled]="false">
      <div class="with-background with-background--paper">
        <div class="text-center">
          <h1>Charta 77 chcela:</h1>
          <ul>
            <li><a (click)="onWrongAnswer()">a) štátny prevrat</a></li>
            <li><a (click)="onCorrectAnswer()">b) Upozorňovať na rôzne konkrétne prípady porušovania ľudských a občianskych práv, pripravovať ich dokumentáciu, navrhovať riešenia, predkladať rôzne všeobecnejšie návrhy smerujúce k prehlbovaniu týchto práv a ich záruk, pôsobiť ako prostredník v prípadných konfliktných situáciách, ktoré môže bezprávie vyvolať, a pod.</a></li>
            <li><a (click)="onWrongAnswer()">c) dosadiť za prezidenta Václava Havla</a></li>
          </ul>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="button button-retro" (click)="nextTab()" >--> ĎALEJ <--</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button class="button button-retro" (click)="next()" >--> ĎALEJ <--</button>
</div>
