import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-part-one',
  templateUrl: './part-one.component.html',
  styleUrls: ['./part-one.component.css']
})
export class PartOneComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public info = null;
  public info2 = null;
  public test: Test = null;
  public vocabulary: Test = null;
  public video;
  public video2;
  public video3;
  public timestampKey = null;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
    private router: Router
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.test = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'What’s a carbon footprint?',
          image: '',
          options: [
            {key: 'a dirty footprint left by someone who has stepped in some coal', translation: 'a dirty footprint left by someone who has stepped in some coal', audio: '', img: ''},
            {key: 'the symbolic consumption of fossil fuels of a journey', translation: 'the symbolic consumption of fossil fuels of a journey', audio: '', img: ''},
            {key: 'the actual amount of fuel used during a journey', translation: 'the actual amount of fuel used during a journey', audio: '', img: ''},
          ],
          answers: ['the symbolic consumption of fossil fuels of a journey'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/1_1.mp3',
        }, {
          text: 'What’s a body clock?',
          image: '',
          options: [
            {key: 'a ticking noise that some people make when they are late for school', translation: 'a ticking noise that some people make when they are late for school', audio: '', img: ''},
            {key: 'an inner feeling people have which tells them what period they are at in their life', translation: 'an inner feeling people have which tells them what period they are at in their life', audio: '', img: ''},
            {key: 'an inner feeling people have which tells them when it’s time to eat', translation: 'an inner feeling people have which tells them when it’s time to eat', audio: '', img: ''},
          ],
          answers: ['an inner feeling people have which tells them what period they are at in their life'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/1_2.mp3',
        }, {
          text: 'Hustle and bustle',
          image: '',
          options: [
            {key: 'are the names of Otto’s pet hamsters', translation: 'are the names of Otto’s pet hamsters', audio: '', img: ''},
            {key: 'are some popular sights in Manhattan, New York', translation: 'are some popular sights in Manhattan, New York', audio: '', img: ''},
            {key: 'is an expression used to mean something is busy and noisy', translation: 'is an expression used to mean something is busy and noisy', audio: '', img: ''},
          ],
          answers: ['is an expression used to mean something is busy and noisy'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/1_3.mp3',
        }, {
          text: 'What is the American Dream?',
          image: '',
          options: [
            {key: 'A frequent dream American children have when they are growing up', translation: 'A frequent dream American children have when they are growing up', audio: '', img: ''},
            {key: 'The idea that you can achieve anything you want in America', translation: 'The idea that you can achieve anything you want in America', audio: '', img: ''},
            {key: 'The dream that European children have about what America is', translation: 'The dream that European children have about what America is', audio: '', img: ''},
          ],
          answers: ['The idea that you can achieve anything you want in America'],
          correctAnswer: '',
          audio: '/assets/audio/american-dream/AD_audio_SS/1_4.mp3',
        }
      ]
    };
  }

  ngOnInit(): void {
    this.audioService.playAudio('/assets/audio/american-dream/AD_audio_SS/zadanie_1.mp3');
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  ngAfterViewInit(): void{
    // this.state.tabIndex = 0;
  }

  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.showNextTab = true;
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

}
