import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartOneComponent } from './part-one.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    PartOneComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class PartOneModule { }
