import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartSixComponent } from './part-six.component';
import { SharedModule } from '../../../shared/shared.module';



@NgModule({
  declarations: [PartSixComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class PartSixModule { }
