<div *ngIf="true">
<mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="VIDEO">
        <app-video [id]="video.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 0" (onTimestamp)="onTimestampChange($event)"></app-video>
        <app-info [content]="info[timestampKey]"></app-info>
    </mat-tab>
    <mat-tab label="CAKE">
        <app-video [id]="cakeScrId"  (onDone)="onLastVideoPlayed($event)" *ngIf="state.tabIndex === 1" [allowPlayNext]="false" [endImmediately]="true"></app-video>
    </mat-tab>
<!--    <mat-tab label="SUBTITLES">-->
<!--        <app-video [id]="video3.id" (onDone)="onVideoPlayed($event)" *ngIf="state.tabIndex === 2" [allowPlayNext]="false"></app-video>-->
<!--    </mat-tab>-->
</mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': allowToStartAgain}">
    <h2 translate>INTERFACE.WELL_DONE</h2>
    <button mat-raised-button color="accent" (click)="startAgain()" ><span translate>INTERFACE.START_AGAIN</span></button>
</div>

