import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hrdinovia',
  templateUrl: './hrdinovia.component.html',
  styleUrls: ['./hrdinovia.component.css']
})
export class HrdinoviaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
