import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-egg-on-a-trip',
  templateUrl: './egg-on-a-trip.component.html',
  styleUrls: ['./egg-on-a-trip.component.css']
})
export class EggOnATripComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
