import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatVideoModule } from 'mat-video';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { InfoComponent } from './info/info.component';
import { VideoComponent } from './video/video.component';
import { TestComponent } from './test/test.component';
import { RouterModule } from '@angular/router';
import { ScoreboardComponent } from './scoreboard/scoreboard.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { DotsPipe } from './dots.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { LogoutModalComponent } from './logout-modal/logout-modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { ExpirationModalComponent } from './expiration-modal/expiration-modal.component';
import { SortPipe } from './sort.pipe';
import { VocabularyModalComponent } from './vocabulary-modal/vocabulary-modal.component';
import { ProgressComponent } from './progress/progress.component';
import { ClassFilterPipe } from './classFilter.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddClassComponent } from './add-class-modal/add-class-modal.component';
import { MatSelect, MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [
    AddClassComponent,
    InfoComponent,
    TestComponent,
    VideoComponent,
    ScoreboardComponent,
    DotsPipe,
    SafeHtmlPipe,
    LogoutModalComponent,
    LoginModalComponent,
    ExpirationModalComponent,
    SortPipe,
    VocabularyModalComponent,
    ProgressComponent,
    ClassFilterPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatVideoModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    ClassFilterPipe,
    CommonModule,
    DotsPipe,
    ExpirationModalComponent,
    FormsModule,
    InfoComponent,
    LoginModalComponent,
    LogoutModalComponent,
    MatButtonModule,
    MatCardModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatVideoModule,
    ProgressComponent,
    ReactiveFormsModule,
    RouterModule,
    ScoreboardComponent,
    SortPipe,
    TestComponent,
    TranslateModule,
    VideoComponent,
    VocabularyModalComponent,
  ]
})
export class SharedModule { }
