import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {
  public cakeScrId = '';
  public info = null;
  public video = null;
  public video2 = null;
  public video3 = null;
  public state = null;
  public showNextTab = null;
  public allowToStartAgain = null;
  public timestampKey = null;
  constructor(
      public storyService: StoryService,
      public translate: TranslateService,
  ) {
    this.state = {
      tabIndex: 0,
      isVocabularyDone: false,
      isDone: false,
    };
    this.video = {src: '/assets/video/10_cvicenie_16_V1.mp4', id: '462659607'};
    this.info = [
      {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_8_1', audio: ''},
    ];
    switch (this.storyService.state.lang) {
      case 'sk':
        this.video3 = {src: '/assets/video/Egg On Trip_V5_8cast_outro.mp4', id: '462661061'};
        break;
      case 'cz':
        this.video3 = {src: '/assets/video/Egg On Trip_V5_8cast_outro_CZ.mp4', id: '463001363'};
        break;
      case 'pl':
        this.video3 = {src: '/assets/video/Egg On Trip_V5_8cast_outro_PL.mp4', id: '463001631'};
    }
  }

  ngOnInit(): void {
    this.cakeScrId = '462660154';
    if (this.storyService.state.active_points > 100) {
      this.cakeScrId = '462660276';
    } else if (this.storyService.state.active_points > 70){
      this.cakeScrId = '462660012';
    }
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  public onLastVideoPlayed($event){
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.allowToStartAgain = true;
  }

  public startAgain(){
    this.storyService.setDefaultState();
  }
}
