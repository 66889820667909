import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AudioService {
    protected src: string = null;
    constructor(){}

    setSrc(audio){
        this.src = audio;
        return this;
    }

    play(){
        const audio = new Audio();
        audio.src = this.src;
        audio.load();
        audio.muted = true;
        audio.play();
    }

    playAudio(src){
        this.setSrc(src).play();
    }

    playRandomAudio(sounds: string[]) {
        sounds = this.shufflingArray(sounds);
        this.playAudio('/assets/audio/' + sounds[0] + '.mp3');
    }

    shufflingArray(value: any[]) {

        let items: any[] = [];

        for (let i = 0; i < value.length; i++) {
            items.push(value[i]);
        }

        // reversing items array
        for (let i = items.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [items[i], items[j]] = [items[j], items[i]];
        }
        return items;
    }
}
