import { Component } from '@angular/core';
import { StoryService } from './services/story.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutModalComponent } from './shared/logout-modal/logout-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app-vajce';
  constructor(
      public storyService: StoryService,
      public translate: TranslateService,
      public dialog: MatDialog,
  ){
    translate.setDefaultLang('sk');
    translate.use('sk');
  }

  public allowedToTeacher() {
    return this.storyService.state.type === 'teacher';
  }

  public isLogged() {
    return this.storyService.state.token !== '';
  }

  public openLogOutDialog(){
    this.dialog.open(LogoutModalComponent);
  }

  public download(){
    const manualPath = '/assets/manual/manual_' + this.storyService.state.active_story + '_' + this.storyService.state.lang + '.pdf';
    window.open(manualPath, '_blank');
  }
}
