<mat-dialog-content>
  <table class="vocabulary">
    <tbody>
    <tr *ngFor="let item of data">
      <td class="with-play" (click)="audioService.playAudio(item.audio)">
        <img class="icon-play" src="/assets/img/american-dream/icon-play.png">
        <strong translate>{{ item.english}}</strong>
      </td>
      <td><span translate>{{ item.translation}}</span></td>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CLOSE</button>
</mat-dialog-actions>
