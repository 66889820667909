import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../services/story.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../../services/state.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.css']
})
export class LogoutModalComponent implements OnInit {
  public token: string = null;

  constructor(
    private storyService: StoryService,
    public translate: TranslateService,
    private stateService: StateService,
    private dialogRef: MatDialogRef<LogoutModalComponent>
  ) { }

  ngOnInit(): void {
    this.token = this.stateService.getState()['token'];
  }

  public logOut(){
    this.storyService.clearStates();
    window.location.reload();
  }

  public onClose(){
    this.dialogRef.close();
  }
}
