import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { Test } from '../../../models/test.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  public video;
  public videoStudent;
  public videoTeacher;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;

  constructor(
      private storyService: StoryService,
      private translate: TranslateService,
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    switch (storyService.state.lang){
      case 'sk':
        this.videoStudent = {src: 'DC_TUTOR_EGG_ziak_SK_V1.mp4', id: '511100747'};
        this.videoTeacher = {src: 'DC_TUTOR_EGG_ucitel_SK_V3.mp4', id: '511098868'};
        break;
      case 'cz':
        this.videoStudent = {src: 'DC_TUTOR_EGG_ziak_CZ_V3.mp4', id: '511097685'};
        this.videoTeacher = {src: 'DC_TUTOR_EGG_ucitel_CZ_V5.mp4', id: '511096659'};
        break;
      case 'pl':
        this.videoStudent = {src: 'DC_TUTOR_EGG_ziak_PL_V4.mp4', id: '511094703'};
        this.videoTeacher = {src: 'DC_TUTOR_EGG_ucitel_PL_V5.mp4', id: '511095154'};
        break;
    }

    if (storyService.state.type === 'teacher'){
      this.video = this.videoTeacher;
    }else{
      this.video = this.videoStudent;
    }

    this.state = {
      tabIndex: 0,
      isVocabularyDone: false,
      isDone: false,
    };
  }

  ngOnInit(): void {
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onInfoStateChange($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onVocabularyStateChange($event){
    this.state.isDone = $event;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next() {
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }
}
