import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../services/story.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../../services/state.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-class-modal',
  templateUrl: './add-class-modal.component.html',
  styleUrls: ['./add-class-modal.component.scss']
})
export class AddClassComponent implements OnInit {
  public token: string = null;
  public order: any = null;
  public addClassForm: FormGroup;
  public classList: any[] = [];

  constructor(
    public translate: TranslateService,
    private stateService: StateService,
    private storyService: StoryService,
    private http: HttpService,
    private router: Router,
    private dialogRef: MatDialogRef<AddClassComponent>
  ) { }

  ngOnInit(): void {
    this.addClassForm = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.pattern('[0-9]{1}([A-Z]{1})')]),
    });
    this.getClassList();
    this.http.get(environment.apiUrl + '/api/online/order-detail/' + this.stateService.getState().token).subscribe((result: any) => {
      this.order = result.data;
    });
  }

  public onClose(){
    this.dialogRef.close();
  }

  getClassList(){
    this.http.get(environment.apiUrl + '/api/online/order-class/' + this.stateService.getState().token).subscribe((result) => {
      this.classList = result['data'];
    });
  }

  onSubmitAddClassForm(){
    this.http.post(environment.apiUrl + '/api/online/order-class/' + this.stateService.getState().token, this.addClassForm.value).subscribe((result) => {
      this.getClassList();
    });
  }

  selectClassForPresentation(id){
    const data = {
      id,
      action : 'update_plays_count'
    };
    const state = this.stateService.getState();
    this.http.put(environment.apiUrl + '/api/online/order-class/' + this.stateService.getState().token, data).subscribe((result) => {
      this.stateService.setStateByKey(new Date().toISOString().slice(0, 19).replace('T', ' '), 'presentation_expiration');
      this.dialogRef.close();
      const firstPart = this.storyService.getStoryConfig(state.active_story).parts[0].key;
      this.router.navigate(['/stories', state.active_story, firstPart]);
    });
  }
}
