import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss']
})
export class ExtraComponent implements OnInit {
  public downloadList;
  public vocabulary;
  public downloadGlossaryList;
  constructor(
    public storyService: StoryService,
    public audioService: AudioService,
  ){
    this.downloadList = [
      {src: '/assets/downloads/EGG CZ.pdf', lang: 'cz', translation: 'INTERFACE.DOWNLOAD_MATERIALS'},
      {src: '/assets/downloads/EGG SK.pdf', lang: 'sk', translation: 'INTERFACE.DOWNLOAD_MATERIALS'},
      {src: '/assets/downloads/EGG PL.pdf', lang: 'pl', translation: 'INTERFACE.DOWNLOAD_MATERIALS'},
    ];

    this.downloadGlossaryList = [
      {src: '/assets/downloads/EGG Glossary CZ.pdf', lang: 'cz', translation: 'INTERFACE.DOWNLOAD_GLOSSARY'},
      {src: '/assets/downloads/EGG Glossary SK.pdf', lang: 'sk', translation: 'INTERFACE.DOWNLOAD_GLOSSARY'},
      {src: '/assets/downloads/EGG Glossary PL.pdf', lang: 'pl', translation: 'INTERFACE.DOWNLOAD_GLOSSARY'},
    ];

    this.vocabulary = [
      {english: 'EGG_ON_TRIP.A_BUCKET_OF_GRAIN', translation: 'EGG_ON_TRIP.TRANSLATED.A_BUCKET_OF_GRAIN', audio: '/assets/audio/a bucket of grain.mp3'},
      {english: 'EGG_ON_TRIP.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', translation: 'EGG_ON_TRIP.TRANSLATED.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', audio: '/assets/audio/as fast as their legs could carry them.mp3'},
      {english: 'EGG_ON_TRIP.ASH_IN_THE_FIREPLACE', translation: 'EGG_ON_TRIP.TRANSLATED.ASH_IN_THE_FIREPLACE', audio: '/assets/audio/ash in the fireplace.mp3'},
      {english: 'EGG_ON_TRIP.BE_CAREFUL', translation: 'EGG_ON_TRIP.TRANSLATED.BE_CAREFUL', audio: '/assets/audio/be carefull.mp3'},
      {english: 'EGG_ON_TRIP.DANGEROUS_THIEVES', translation: 'EGG_ON_TRIP.TRANSLATED.DANGEROUS_THIEVES', audio: '/assets/audio/dangerous thieves.mp3'},
      {english: 'EGG_ON_TRIP.EVERYBODY_IS_IN_HIS_POSITION', translation: 'EGG_ON_TRIP.TRANSLATED.EVERYBODY_IS_IN_HIS_POSITION', audio: '/assets/audio/everybody is in his position.mp3'},
      {english: 'EGG_ON_TRIP.FIND_THE_HANDLE', translation: 'EGG_ON_TRIP.TRANSLATED.FIND_THE_HANDLE', audio: '/assets/audio/find the handle.mp3'},
      {english: 'EGG_ON_TRIP.HOLD_ME_TIGHT', translation: 'EGG_ON_TRIP.TRANSLATED.HOLD_ME_TIGHT', audio: '/assets/audio/hold me tight.mp3'},
      {english: 'EGG_ON_TRIP.I_AM_LOST', translation: 'EGG_ON_TRIP.TRANSLATED.I_AM_LOST', audio: '/assets/audio/I am lost.mp3'},
      {english: 'EGG_ON_TRIP.LISTEN_CAREFULLY', translation: 'EGG_ON_TRIP.TRANSLATED.LISTEN_CAREFULLY', audio: '/assets/audio/listen carefully.mp3'},
      {english: 'EGG_ON_TRIP.MOTHER_NATURE', translation: 'EGG_ON_TRIP.TRANSLATED.MOTHER_NATURE', audio: '/assets/audio/mother nature.mp3'},
      {english: 'EGG_ON_TRIP.PUNCH_IN_THE_STOMACH', translation: 'EGG_ON_TRIP.TRANSLATED.PUNCH_IN_THE_STOMACH', audio: '/assets/audio/punch in the stomach.mp3'},
      {english: 'EGG_ON_TRIP.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', translation: 'EGG_ON_TRIP.TRANSLATED.THE_CHEF_LOOKS_IN_THE_COOKERY_BOOK', audio: '/assets/audio/the chef looks in the cookery book.mp3'},
      {english: 'EGG_ON_TRIP.THE_WRONG_DIRECTION', translation: 'EGG_ON_TRIP.TRANSLATED.THE_WRONG_DIRECTION', audio: '/assets/audio/the wrong direction.mp3'},
      {english: 'EGG_ON_TRIP.TO_CATCH', translation: 'EGG_ON_TRIP.TRANSLATED.TO_CATCH', audio: '/assets/audio/to catch.mp3'},
      {english: 'EGG_ON_TRIP.TO_DRIVE_THE_THIEVES_OUT', translation: 'EGG_ON_TRIP.TRANSLATED.TO_DRIVE_THE_THIEVES_OUT', audio: '/assets/audio/to drive the thieves out.mp3'},
      {english: 'EGG_ON_TRIP.TO_ELIMINATE_THE_ENEMY', translation: 'EGG_ON_TRIP.TRANSLATED.TO_ELIMINATE_THE_ENEMY', audio: '/assets/audio/to eliminate the enemy.mp3'},
      {english: 'EGG_ON_TRIP.TO_FIND_A_LIGHT', translation: 'EGG_ON_TRIP.TRANSLATED.TO_FIND_A_LIGHT', audio: '/assets/audio/to find a light.mp3'},
      {english: 'EGG_ON_TRIP.TO_GO_FOR_A_TRIP', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_TRIP', audio: '/assets/audio/to go for a trip.mp3'},
      {english: 'EGG_ON_TRIP.TO_GO_FOR_A_WALK', translation: 'EGG_ON_TRIP.TRANSLATED.TO_GO_FOR_A_WALK', audio: '/assets/audio/to go for a walk.mp3'},
      {english: 'EGG_ON_TRIP.TO_HAVE_A_REST', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HAVE_A_REST', audio: '/assets/audio/to have a rest.mp3'},
      {english: 'EGG_ON_TRIP.TO_HURRY_UP', translation: 'EGG_ON_TRIP.TRANSLATED.TO_HURRY_UP', audio: '/assets/audio/to hurry up.mp3'},
      {english: 'EGG_ON_TRIP.TO_JUMP_ON_SOMEONES_BACK', translation: 'EGG_ON_TRIP.TRANSLATED.TO_JUMP_ON_SOMEONES_BACK', audio: '/assets/audio/to jump on someones back.mp3'},
      {english: 'EGG_ON_TRIP.TO_KNOCK_ON_THE_DOOR', translation: 'EGG_ON_TRIP.TRANSLATED.TO_KNOCK_ON_THE_DOOR', audio: '/assets/audio/to knock on the door.mp3'},
      {english: 'EGG_ON_TRIP.TO_LOSE_YOUR_WAY', translation: 'EGG_ON_TRIP.TRANSLATED.TO_LOSE_YOUR_WAY', audio: '/assets/audio/to lose your way.mp3'},
      {english: 'EGG_ON_TRIP.TO_SCREAM_FOR_HELP', translation: 'EGG_ON_TRIP.TRANSLATED.TO_SCREAM_FOR_HELP', audio: '/assets/audio/to scream for help.mp3'},
      {english: 'EGG_ON_TRIP.TO_STIR_THE_MILK', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STIR_THE_MILK', audio: '/assets/audio/to stir the milk.mp3'},
      {english: 'EGG_ON_TRIP.TO_STOP_IN_A_MEADOW', translation: 'EGG_ON_TRIP.TRANSLATED.TO_STOP_IN_A_MEADOW', audio: '/assets/audio/to stop in a meadow.mp3'},
      {english: 'EGG_ON_TRIP.UNDER_THE_STAIRS', translation: 'EGG_ON_TRIP.TRANSLATED.UNDER_THE_STAIRS', audio: '/assets/audio/under the stairs.mp3'},
      {english: 'EGG_ON_TRIP.WE_ARE_SAVED', translation: 'EGG_ON_TRIP.TRANSLATED.WE_ARE_SAVED', audio: '/assets/audio/we are saved.mp3'},
      {english: 'EGG_ON_TRIP.WILD_ANIMAL', translation: 'EGG_ON_TRIP.TRANSLATED.WILD_ANIMAL', audio: '/assets/audio/wild animal.mp3'},
    ];
  }

  ngOnInit(): void {
  }

  backToContent(){
    this.storyService.goBackToTest();
  }
}
