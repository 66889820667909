<div>
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="VIDEO" [disabled]="false">
      <app-video [id]="video.id" (onDone)="onVideoPlayed()" *ngIf="state.tabIndex === 0" [allowPlayAgain]="true"></app-video>
    </mat-tab>
    <mat-tab label="QUESTION" [disabled]="false">
      <div class="with-background" style="background-image: url('/assets/img/mr-wolf/backgrounds/otazka_5.jpg')">
        <div class="text-center">
          <h1>What does Mr Wolf want to do in Cambridge?</h1>
          <br>
          <ul>
            <li><a (click)="onWrongAnswer();">a) study at the university</a></li>
            <li><a (click)="onWrongAnswer();">b) find the pigs and invite them to a party</a></li>
            <li><a (click)="onCorrectAnswer();">c) find the pigs and eat them all up</a></li>
          </ul>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button mat-raised-button color="accent" (click)="nextTab()" >NEXT</button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state && state.isDone}">
  <button mat-raised-button color="accent" (click)="next()" >NEXT</button>
</div>
