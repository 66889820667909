<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button *ngIf="isLogged()">
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <h1>theatrecentre.online</h1>
    <span class="spacer">
      <button mat-stroked-button color="primary" *ngIf="storyService.hasStoryVocabulary()" (click)="storyService.openVocabularyModal()">VOCABULARY</button>
    </span>
    <app-scoreboard *ngIf="!allowedToTeacher() && isLogged()"></app-scoreboard>
    <div *ngIf="allowedToTeacher()"><span translate>INTERFACE.TEACHER_ACCESS</span> (<span>{{ storyService.state.lang}}</span>)</div>

  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <ng-container *ngIf="(!allowedToTeacher() && isLogged()) || !storyService.isPresentationExpired()">
        <a mat-list-item (click)="storyService.setDefaultState()"  mat-list-item ><span translate>INTERFACE.START_AGAIN</span></a>
        <mat-divider></mat-divider>
      </ng-container>
      <ng-container *ngIf="!storyService.isPresentationExpired()">
        <ng-container *ngFor="let item of storyService.config[storyService.state.active_story]?.parts">
          <a mat-list-item *ngIf="allowedToTeacher()" [routerLink]="'stories/'+storyService.config[storyService.state.active_story].url+'/'+item.key" routerLinkActive="active"><span translate>{{ item.translation}}</span></a>
        </ng-container>
      </ng-container>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let item of storyService.config[storyService.state.active_story]?.extraContent">
        <a *ngIf="!item.callback" mat-list-item [routerLink]="'stories/'+storyService.config[storyService.state.active_story].url+'/'+item.key" routerLinkActive="active"><span translate>{{ item.translation}}</span></a>
        <a *ngIf="item.callback" mat-list-item (click)="item.callback()"><span translate>{{ item.translation}}</span></a>
      </ng-container>
      <a mat-list-item (click)="download()" mat-list-item *ngIf="allowedToTeacher()"><span translate>INTERFACE.MANUAL</span></a>
      <a mat-list-item [routerLink]="'/profile'" routerLinkActive="active" *ngIf="isLogged()"><span translate>INTERFACE.ACCOUNT</span></a>
      <a mat-list-item (click)="openLogOutDialog()" mat-list-item *ngIf="isLogged()"><span translate>INTERFACE.LOGOUT</span></a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>



