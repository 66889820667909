<div class="modal-header">
  <button class="close" mat-button (click)="onClose()">X</button>
</div>
<form class="example-form" [formGroup]="registrationForm" (ngSubmit)="onSubmitRegistrationForm()">
  <div *ngIf="showForm" style="max-width: 400px;">
    <h1 mat-dialog-title translate class="text-align-center">
      <span translate>INTERFACE.WELCOME</span>
    </h1>
    <h3 class="text-align-center">
      <span translate>INTERFACE.FILL_YOUR_BIO</span>
    </h3>
    <div mat-dialog-content class="text-align-center">
      <mat-form-field appearance="fill">
        <mat-label translate>INTERFACE.NAME</mat-label>
        <input matInput formControlName="name" placeholder="Otto">
        <mat-error *ngIf="registrationForm.get('name').hasError('required')">
          <strong translate>INTERFACE.NAME_IS_REQUIRED</strong>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill">
        <mat-label translate>INTERFACE.SURNAME</mat-label>
        <input matInput formControlName="surname" placeholder="Writer">
        <mat-error *ngIf="registrationForm.get('surname').hasError('required')">
          <strong translate>INTERFACE.SURNAME_IS_REQUIRED</strong>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill">
        <mat-label translate>INTERFACE.CLASS</mat-label>
<!--        <mat-select formControlName="class_id" name="class_id" [disabled]="ticket.class_id">-->
        <mat-select formControlName="class_id" name="class_id">
          <mat-option *ngFor="let class of classList" [value]="class.id">{{ class.title}}</mat-option>
        </mat-select>
        <mat-error *ngIf="registrationForm.get('class_id').hasError('required')">
          <strong translate>INTERFACE.CLASS_IS_REQUIRED</strong>
        </mat-error>
      </mat-form-field>
      <br>
      <div style="margin: 0 auto;">
        <mat-checkbox type="checkbox" formControlName="approve_gdpr"><span translate>INTERFACE.APPROVE_GDPR</span></mat-checkbox>
        <p translate>INTERFACE.APPROVE_GDPR_TEXT</p>
        <a [href]="gdrpLink" target="_blank" translate>INTERFACE.GDPR_LINK</a>
        <mat-error *ngIf="registrationForm.get('approve_gdpr').value === false">
          <strong translate>INTERFACE.GDPR_IS_REQUIRED</strong>
        </mat-error>
      </div>
      <br>
    </div>
    <div mat-dialog-actions class="justify-center">
      <button mat-raised-button type="submit" color="primary" [disabled]="!registrationForm.valid"><span translate>INTERFACE.CONFIRM</span></button>
    </div>
  </div>
  <div *ngIf="!showForm" style="max-width: 400px;">
    <h1 mat-dialog-title class="text-align-center" translate>INTERFACE.WELCOME_IN_THEATRE</h1>
    <div mat-dialog-content class="text-align-center">
      <h3 translate>INTERFACE.YOUR_PERSONAL_CODE_IS</h3>
    </div>
    <div mat-dialog-content class="text-align-center">
      <h2>{{ token}}</h2>
    </div>
    <div mat-dialog-content class="text-align-center">
      <h3 translate>INTERFACE.IF_YOU_LOSE_YOUR_PERSONAL_CODE</h3>
    </div>
    <div mat-dialog-actions class="justify-center">
      <button mat-raised-button type="submit" color="primary" (click)="onAccept()"><span translate>INTERFACE.ENTER</span></button>
    </div>
  </div>
</form>
