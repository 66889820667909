import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HrdinoviaComponent } from './hrdinovia.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { Part1Component } from './part1/part1.component';
import { Part2Component } from './part2/part2.component';
import { Part3Component } from './part3/part3.component';
import { Part4Component } from './part4/part4.component';
import { Part5Component } from './part5/part5.component';
import { Part6Component } from './part6/part6.component';
import { ExercisesComponent } from './exercises/exercises.component';
import { FinishComponent } from './finish/finish.component';
import { ExtraComponent } from './extra/extra.component';
import { Part7Component } from './part7/part7.component';


const routes: Routes = [
  {path: '', component: HrdinoviaComponent, children: [
      {path: '', redirectTo: 'tutorial', pathMatch: 'full'},
      {path: 'tutorial', component : TutorialComponent},
      {path: '1', component : Part1Component},
      {path: '2', component : Part2Component},
      {path: '3', component : Part3Component},
      {path: '4', component : Part4Component},
      {path: '5', component : Part5Component},
      {path: '6', component : Part6Component},
      {path: '7', component : Part7Component},
      {path: 'exercises', component: ExercisesComponent},
      {path: 'finish', component : FinishComponent},
      {path: 'extra', component : ExtraComponent},
    ] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class HrdinoviaRoutingModule { }
