import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { StoryService } from '../../../services/story.service';
import { AudioService } from '../../../services/audio.service';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.css']
})
export class ExercisesComponent implements OnInit {
  public state = null;
  public showNextTab = null;
  public timestampKey = null;
  public test1: Test;
  public test2: Test;
  public test3: Test;
  public test4: Test;
  public test5: Test;
  public test6: Test;
  public test7: Test;
  public test8: Test;
  public test9: Test;

  constructor(
    private storyService: StoryService,
    private audioService: AudioService,
  ) {
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.onPlayAttention(0);
    // 1.PEXESO_Adjectives - pairs (6) - TODO check
    this.test1 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'angry', translation: 'MR_WOLF.ANGRY', audio: '/assets/audio/mr-wolf/exercises/angry.mp3', img: '/assets/img/mr-wolf/angry.jpg'},
          {key: 'weak', translation: 'MR_WOLF.WEAK', audio: '/assets/audio/mr-wolf/exercises/weak.mp3', img: '/assets/img/mr-wolf/weak.jpg'},
          {key: 'hungry', translation: 'MR_WOLF.HUNGRY', audio: '/assets/audio/mr-wolf/exercises/hungry.mp3', img: '/assets/img/mr-wolf/hungry.jpg'},
          {key: 'hot', translation: 'MR_WOLF.HOT', audio: '/assets/audio/mr-wolf/exercises/hot.mp3', img: '/assets/img/mr-wolf/hot.jpg'},
          {key: 'brave', translation: 'MR_WOLF.BRAVE', audio: '/assets/audio/mr-wolf/exercises/brave.mp3', img: '/assets/img/mr-wolf/brave.jpg'},
          {key: 'hurt', translation: 'MR_WOLF.HURT', audio: '/assets/audio/mr-wolf/exercises/hurt.mp3', img: '/assets/img/mr-wolf/hurt.jpg'}
        ],
        answers: [
          {key: 'angry', translation: 'MR_WOLF.ANGRY', audio: '/assets/audio/mr-wolf/exercises/angry.mp3', img: '/assets/img/mr-wolf/angry.jpg'},
          {key: 'weak', translation: 'MR_WOLF.WEAK', audio: '/assets/audio/mr-wolf/exercises/weak.mp3', img: '/assets/img/mr-wolf/weak.jpg'},
          {key: 'hungry', translation: 'MR_WOLF.HUNGRY', audio: '/assets/audio/mr-wolf/exercises/hungry.mp3', img: '/assets/img/mr-wolf/hungry.jpg'},
          {key: 'hot', translation: 'MR_WOLF.HOT', audio: '/assets/audio/mr-wolf/exercises/hot.mp3', img: '/assets/img/mr-wolf/hot.jpg'},
          {key: 'brave', translation: 'MR_WOLF.BRAVE', audio: '/assets/audio/mr-wolf/exercises/brave.mp3', img: '/assets/img/mr-wolf/brave.jpg'},
          {key: 'hurt', translation: 'MR_WOLF.HURT', audio: '/assets/audio/mr-wolf/exercises/hurt.mp3', img: '/assets/img/mr-wolf/hurt.jpg'}
        ]
      }
    };
    // 2.WEATHER - questions (5)
    this.test2 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Today, the weather in England is .... .',
          image: '/assets/img/mr-wolf/EN_foggy.jpg',
          options: [
            {key: 'sunny', translation: 'sunny', audio: '', img: ''},
            {key: 'cloudy', translation: 'cloudy', audio: '', img: ''},
            {key: 'rainy', translation: 'rainy', audio: '', img: ''},
            {key: 'foggy', translation: 'foggy', audio: '/assets/audio/mr-wolf/exercises/en_foggy.mp3', img: ''},
            {key: 'cold', translation: 'cold', audio: '', img: ''},
          ],
          answers: ['foggy'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/en_foggy.mp3',
        }, {
          text: 'Today, the weather in Germany is .... .',
          image: '/assets/img/mr-wolf/DE_cloudy.jpg',
          options: [
            {key: 'sunny', translation: 'sunny', audio: '', img: ''},
            {key: 'cloudy', translation: 'cloudy', audio: '/assets/audio/mr-wolf/exercises/de_cloudy.mp3', img: ''},
            {key: 'rainy', translation: 'rainy', audio: '', img: ''},
            {key: 'foggy', translation: 'foggy', audio: '', img: ''},
            {key: 'cold', translation: 'cold', audio: '', img: ''},
          ],
          answers: ['cloudy'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/de_cloudy.mp3',
        }, {
          text: 'Today, the weather in France is .... .',
          image: '/assets/img/mr-wolf/FR_sunny.jpg',
          options: [
            {key: 'sunny', translation: 'sunny', audio: '/assets/audio/mr-wolf/exercises/fr_sunny.mp3', img: ''},
            {key: 'cloudy', translation: 'cloudy', audio: '', img: ''},
            {key: 'rainy', translation: 'rainy', audio: '', img: ''},
            {key: 'foggy', translation: 'foggy', audio: '', img: ''},
            {key: 'cold', translation: 'cold', audio: '', img: ''},
          ],
          answers: ['sunny'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/fr_sunny.mp3',
        }, {
          text: 'Today, the weather in Belgium is .... .',
          image: '/assets/img/mr-wolf/BE_rainy.jpg',
          options: [
            {key: 'sunny', translation: 'sunny', audio: '', img: ''},
            {key: 'cloudy', translation: 'cloudy', audio: '', img: ''},
            {key: 'rainy', translation: 'rainy', audio: '/assets/audio/mr-wolf/exercises/be_rainy.mp3', img: ''},
            {key: 'foggy', translation: 'foggy', audio: '', img: ''},
            {key: 'cold', translation: 'cold', audio: '', img: ''},
          ],
          answers: ['rainy'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/be_rainy.mp3',
        }, {
          text: 'Today, the weather in Czech Republic is .... .',
          image: '/assets/img/mr-wolf/CZ_cold.jpg',
          options: [
            {key: 'sunny', translation: 'sunny', audio: '', img: ''},
            {key: 'cloudy', translation: 'cloudy', audio: '', img: ''},
            {key: 'rainy', translation: 'rainy', audio: '', img: ''},
            {key: 'foggy', translation: 'foggy', audio: '', img: ''},
            {key: 'cold', translation: 'cold', audio: '/assets/audio/mr-wolf/exercises/cz_cold.mp3', img: ''},
          ],
          answers: ['cold'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/cz_cold.mp3',
        }
      ]
    };
    // 3.PEXESO_Jobs - pairs (6) TODO check
    this.test3 = {
      name: '',
      description: '',
      type: 'pairs',
      pairs: {
        options: [
          {key: 'hunter', translation: 'MR_WOLF.HUNTER', audio: '/assets/audio/mr-wolf/exercises/hunter.mp3', img: '/assets/img/mr-wolf/hunter.jpg'},
          {key: 'policeman', translation: 'MR_WOLF.POLICEMAN', audio: '/assets/audio/mr-wolf/exercises/policeman.mp3', img: '/assets/img/mr-wolf/policeman.jpg'},
          {key: 'postman', translation: 'MR_WOLF.POSTMAN', audio: '/assets/audio/mr-wolf/exercises/postman.mp3', img: '/assets/img/mr-wolf/postman.jpg'},
          {key: 'architect', translation: 'MR_WOLF.ARCHITECT', audio: '/assets/audio/mr-wolf/exercises/architect.mp3', img: '/assets/img/mr-wolf/architect.jpg'},
          {key: 'builder', translation: 'MR_WOLF.BUILDER', audio: '/assets/audio/mr-wolf/exercises/builder.mp3', img: '/assets/img/mr-wolf/builder.jpg'},
          {key: 'cook', translation: 'MR_WOLF.COOK', audio: '/assets/audio/mr-wolf/exercises/cook.mp3', img: '/assets/img/mr-wolf/cook.jpg'}
        ],
        answers: [
          {key: 'hunter', translation: 'MR_WOLF.HUNTER', audio: '/assets/audio/mr-wolf/exercises/hunter.mp3', img: '/assets/img/mr-wolf/hunter.jpg'},
          {key: 'policeman', translation: 'MR_WOLF.POLICEMAN', audio: '/assets/audio/mr-wolf/exercises/policeman.mp3', img: '/assets/img/mr-wolf/policeman.jpg'},
          {key: 'postman', translation: 'MR_WOLF.POSTMAN', audio: '/assets/audio/mr-wolf/exercises/postman.mp3', img: '/assets/img/mr-wolf/postman.jpg'},
          {key: 'architect', translation: 'MR_WOLF.ARCHITECT', audio: '/assets/audio/mr-wolf/exercises/architect.mp3', img: '/assets/img/mr-wolf/architect.jpg'},
          {key: 'builder', translation: 'MR_WOLF.BUILDER', audio: '/assets/audio/mr-wolf/exercises/builder.mp3', img: '/assets/img/mr-wolf/builder.jpg'},
          {key: 'cook', translation: 'MR_WOLF.COOK', audio: '/assets/audio/mr-wolf/exercises/cook.mp3', img: '/assets/img/mr-wolf/cook.jpg'}
        ]
      }
    };
    // 4.HOUSE - questions (6)
    this.test4 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Mr Wolf is trying to open the .... .',
          image: '/assets/img/mr-wolf/wolf_door.jpg',
          options: [
            {key: 'chimney', translation: 'chimney', audio: '', img: ''},
            {key: 'roof', translation: 'roof', audio: '', img: ''},
            {key: 'door', translation: 'door', audio: '/assets/audio/mr-wolf/exercises/wolf_door.mp3', img: ''},
            {key: 'wall', translation: 'wall', audio: '', img: ''},
            {key: 'window', translation: 'window', audio: '', img: ''},
            {key: 'fireplace', translation: 'fireplace', audio: '', img: ''},
          ],
          answers: ['door'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/wolf_door.mp3',
        }, {
          text: 'Mr Wolf is trying to break the .... .',
          image: '/assets/img/mr-wolf/wolf_window.jpg',
          options: [
            {key: 'chimney', translation: 'chimney', audio: '', img: ''},
            {key: 'roof', translation: 'roof', audio: '', img: ''},
            {key: 'door', translation: 'door', audio: '', img: ''},
            {key: 'wall', translation: 'wall', audio: '', img: ''},
            {key: 'window', translation: 'window', audio: '/assets/audio/mr-wolf/exercises/wolf_window.mp3', img: ''},
            {key: 'fireplace', translation: 'fireplace', audio: '', img: ''},
          ],
          answers: ['window'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/wolf_window.mp3',
        }, {
          text: 'Mr Wolf  is trying to kick down the .... .',
          image: '/assets/img/mr-wolf/wolf_wall.jpg',
          options: [
            {key: 'chimney', translation: 'chimney', audio: '', img: ''},
            {key: 'roof', translation: 'roof', audio: '', img: ''},
            {key: 'door', translation: 'door', audio: '', img: ''},
            {key: 'wall', translation: 'wall', audio: '/assets/audio/mr-wolf/exercises/wolf_wall.mp3', img: ''},
            {key: 'window', translation: 'window', audio: '', img: ''},
            {key: 'fireplace', translation: 'fireplace', audio: '', img: ''},
          ],
          answers: ['wall'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/wolf_wall.mp3',
        }, {
          text: 'Mr Wolf is standing on the .... .',
          image: '/assets/img/mr-wolf/wolf_roof.jpg',
          options: [
            {key: 'chimney', translation: 'chimney', audio: '', img: ''},
            {key: 'roof', translation: 'roof', audio: '/assets/audio/mr-wolf/exercises/wolf_roof.mp3', img: ''},
            {key: 'door', translation: 'door', audio: '', img: ''},
            {key: 'wall', translation: 'wall', audio: '', img: ''},
            {key: 'window', translation: 'window', audio: '', img: ''},
            {key: 'fireplace', translation: 'fireplace', audio: '', img: ''},
          ],
          answers: ['roof'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/wolf_roof.mp3',
        }, {
          text: 'Mr Wolf  is climbing down the .... .',
          image: '/assets/img/mr-wolf/wolf_chimney.jpg',
          options: [
            {key: 'chimney', translation: 'chimney', audio: '/assets/audio/mr-wolf/exercises/wolf_chimney.mp3', img: ''},
            {key: 'roof', translation: 'roof', audio: '', img: ''},
            {key: 'door', translation: 'door', audio: '', img: ''},
            {key: 'wall', translation: 'wall', audio: '', img: ''},
            {key: 'window', translation: 'window', audio: '', img: ''},
            {key: 'fireplace', translation: 'fireplace', audio: '', img: ''},
          ],
          answers: ['chimney'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/wolf_chimney.mp3',
        }, {
          text: 'Oh no, Mr Wolf’s in the .... . Poor Mr Wolf.',
          image: '/assets/img/mr-wolf/wolf_fireplace.jpg',
          options: [
            {key: 'chimney', translation: 'chimney', audio: '', img: ''},
            {key: 'roof', translation: 'roof', audio: '', img: ''},
            {key: 'door', translation: 'door', audio: '', img: ''},
            {key: 'wall', translation: 'wall', audio: '', img: ''},
            {key: 'window', translation: 'window', audio: '', img: ''},
            {key: 'fireplace', translation: 'fireplace', audio: '/assets/audio/mr-wolf/exercises/wolf_fireplace.mp3', img: ''},
          ],
          answers: ['fireplace'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/wolf_fireplace.mp3',
        }
      ]
    };
    // 5.TRANSPORT - questions (1) + audio
    this.test5 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'What transport didn’t Wolfie use on his travels? Choose which answer is wrong.',
          image: '',
          options: [
            {key: 'taxi', translation: 'taxi', audio: '', img: '/assets/img/mr-wolf/taxi.jpg'},
            {key: 'bus', translation: 'bus', audio: '', img: '/assets/img/mr-wolf/bus.jpg'},
            {key: 'train', translation: 'train', audio: '', img: '/assets/img/mr-wolf/train.jpg'},
            {key: 'UFO', translation: 'UFO', audio: '', img: '/assets/img/mr-wolf/ufo.jpg'},
          ],
          answers: ['UFO'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/transport.mp3',
        }
      ]
    };
    // 6.HELLO - questions (1) + audio
    this.test6 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'How don’t people say “hello” in England? Choose which answer is wrong.',
          image: '',
          options: [
            {key: 'Good morning', translation: 'Good morning', audio: '', img: ''},
            {key: 'Good afternoon', translation: 'Good afternoon', audio: '', img: ''},
            {key: 'Good evening', translation: 'Good evening', audio: '', img: ''},
            {key: 'Good day', translation: 'Good day', audio: '', img: ''},
          ],
          answers: ['Good day'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/hello.mp3',
        }
      ]
    };
    // 7.HEALTH - questions (5)
    this.test7 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Doctor, doctor. I cannot eat anything because my mouth hurts. If I bite something with my teeth, ow ow ow.',
          image: '',
          options: [
            {key: 'broken_leg', translation: 'broken leg', audio: '', img: '/assets/img/mr-wolf/broken_leg.jpg'},
            {key: 'sore_throat', translation: 'sore throat', audio: '', img: '/assets/img/mr-wolf/sore_throat.jpg'},
            {key: 'headache', translation: 'headache', audio: '', img: '/assets/img/mr-wolf/headache.jpg'},
            {key: 'stomach_ache', translation: 'stomach ache', audio: '', img: '/assets/img/mr-wolf/stomach_ache.jpg'},
            {key: 'toothache', translation: 'toothache', audio: '/assets/audio/mr-wolf/exercises/toothache.mp3', img: '/assets/img/mr-wolf/toothache.jpg'},
          ],
          answers: ['toothache'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/7_health_doctor,_doctor._I_cannot_eat_anything_becouse_my_mouth_hurts._If_I_bite_something_with_my_teeth,_ow_ow_ow..mp3',
        }, {
          text: 'Doctor, doctor. Yesterday I ate a cheeseburger, roast lamb, fried fish and a big birthday cake. Now my belly is like a balloon.',
          image: '',
          options: [
            {key: 'broken_leg', translation: 'broken leg', audio: '', img: '/assets/img/mr-wolf/broken_leg.jpg'},
            {key: 'sore_throat', translation: 'sore throat', audio: '', img: '/assets/img/mr-wolf/sore_throat.jpg'},
            {key: 'headache', translation: 'headache', audio: '', img: '/assets/img/mr-wolf/headache.jpg'},
            {key: 'stomach_ache', translation: 'stomach ache', audio: '/assets/audio/mr-wolf/exercises/stomach_ache.mp3', img: '/assets/img/mr-wolf/stomach_ache.jpg'},
            {key: 'toothache', translation: 'toothache', audio: '', img: '/assets/img/mr-wolf/toothache.jpg'},
          ],
          answers: ['stomach_ache'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/7_health_doctor,_doctor._yesterday_i_ate_a_cheesburger,_roast_lamb,_fried_fish_and_a_big_birthday_cake._now_my_belly_is_like_a_balloon.mp3',
        }, {
          text: 'Doctor, doctor. Last night I was singing in a club in London. I sang the songs “We will rock you…” and then I sang “We are the champions”. I sang so loud that now I can’t talk any more.',
          image: '',
          options: [
            {key: 'broken_leg', translation: 'broken leg', audio: '', img: '/assets/img/mr-wolf/broken_leg.jpg'},
            {key: 'sore_throat', translation: 'sore throat', audio: '/assets/audio/mr-wolf/exercises/sore_throat.mp3', img: '/assets/img/mr-wolf/sore_throat.jpg'},
            {key: 'headache', translation: 'headache', audio: '', img: '/assets/img/mr-wolf/headache.jpg'},
            {key: 'stomach_ache', translation: 'stomach ache', audio: '', img: '/assets/img/mr-wolf/stomach_ache.jpg'},
            {key: 'toothache', translation: 'toothache', audio: '', img: '/assets/img/mr-wolf/toothache.jpg'},
          ],
          answers: ['sore_throat'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/7_health-doctor,_doctor._last_night_i_was_singing_in_a_club_in_london._i_sang_the_songs_we_will_rock_you....,_and_than_i_sang_we_are_the_champions._i_sang_so_loud_that_now_i_cant_talk_any_more.mp3',
        }, {
          text: 'Doctor, doctor. Yesterday I got into trouble, I had a big problem. I wanted to eat a little lamb from the farm, but the hunter saw me. He took a big stick and smashed my head: bang-bang-bang',
          image: '',
          options: [
            {key: 'broken_leg', translation: 'broken leg', audio: '', img: '/assets/img/mr-wolf/broken_leg.jpg'},
            {key: 'sore_throat', translation: 'sore throat', audio: '', img: '/assets/img/mr-wolf/sore_throat.jpg'},
            {key: 'headache', translation: 'headache', audio: '/assets/audio/mr-wolf/exercises/headache.mp3', img: '/assets/img/mr-wolf/headache.jpg'},
            {key: 'stomach_ache', translation: 'stomach ache', audio: '', img: '/assets/img/mr-wolf/stomach_ache.jpg'},
            {key: 'toothache', translation: 'toothache', audio: '', img: '/assets/img/mr-wolf/toothache.jpg'},
          ],
          answers: ['headache'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/7_health-doctor_doctor._yesterday_i_got_into_trouble,_i_had_a_big_problem._i_wanted_to_eat_a_little_lamb_from_the_farm,_but_the_hunter_saw_me._he_took_a_big_stick_and_smashed_my_head_bang-bang-bang.mp3',
        }, {
          text: 'Doctor, doctor. We went skiing and I was going so fast….I jumped...and I crashed…. Now I can’t walk.',
          image: '',
          options: [
            {key: 'broken_leg', translation: 'broken leg', audio: '/assets/audio/mr-wolf/exercises/broken_leg.mp3', img: '/assets/img/mr-wolf/broken_leg.jpg'},
            {key: 'sore_throat', translation: 'sore throat', audio: '', img: '/assets/img/mr-wolf/sore_throat.jpg'},
            {key: 'headache', translation: 'headache', audio: '', img: '/assets/img/mr-wolf/headache.jpg'},
            {key: 'stomach_ache', translation: 'stomach ache', audio: '', img: '/assets/img/mr-wolf/stomach_ache.jpg'},
            {key: 'toothache', translation: 'toothache', audio: '', img: '/assets/img/mr-wolf/toothache.jpg'},
          ],
          answers: ['broken_leg'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/7_health-doctor_doctor_we_went_skiing_and_i_was_going_so_fast_i_jumped_and_i_crashed_now_i_cant_walk.mp3',
        }
      ]
    };
    // 8.PHRASES - questions (6)
    this.test8 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'Wolfie would love to eat .... the little piggies.',
          image: '',
          options: [
            {key: 'up', translation: 'up', audio: '/assets/audio/mr-wolf/exercises/to_eat_up.mp3', img: ''},
            {key: 'down', translation: 'down', audio: '', img: ''},
            {key: 'away', translation: 'away', audio: '', img: ''},
            {key: 'for', translation: 'for', audio: '', img: ''},
            {key: 'after', translation: 'after', audio: '', img: ''},
            {key: 'on', translation: 'on', audio: '', img: ''},
          ],
          answers: ['up'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Wolfie tries hard to blow .... the house.',
          image: '',
          options: [
            {key: 'up', translation: 'up', audio: '', img: ''},
            {key: 'down', translation: 'down', audio: '/assets/audio/mr-wolf/exercises/to_blow_down.mp3', img: ''},
            {key: 'away', translation: 'away', audio: '', img: ''},
            {key: 'for', translation: 'for', audio: '', img: ''},
            {key: 'after', translation: 'after', audio: '', img: ''},
            {key: 'on', translation: 'on', audio: '', img: ''},
          ],
          answers: ['down'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'The piggies want Wolfie to go .... .',
          image: '',
          options: [
            {key: 'up', translation: 'up', audio: '', img: ''},
            {key: 'down', translation: 'down', audio: '', img: ''},
            {key: 'away', translation: 'away', audio: '/assets/audio/mr-wolf/exercises/to_go_away.mp3', img: ''},
            {key: 'for', translation: 'for', audio: '', img: ''},
            {key: 'after', translation: 'after', audio: '', img: ''},
            {key: 'on', translation: 'on', audio: '', img: ''},
          ],
          answers: ['away'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Wolfie looks .... the pigs in Cambridge.',
          image: '',
          options: [
            {key: 'up', translation: 'up', audio: '', img: ''},
            {key: 'down', translation: 'down', audio: '', img: ''},
            {key: 'away', translation: 'away', audio: '', img: ''},
            {key: 'for', translation: 'for', audio: '/assets/audio/mr-wolf/exercises/looks_for.mp3', img: ''},
            {key: 'after', translation: 'after', audio: '', img: ''},
            {key: 'on', translation: 'on', audio: '', img: ''},
          ],
          answers: ['for'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Wolfie runs .... the pigs.',
          image: '',
          options: [
            {key: 'up', translation: 'up', audio: '', img: ''},
            {key: 'down', translation: 'down', audio: '', img: ''},
            {key: 'away', translation: 'away', audio: '', img: ''},
            {key: 'for', translation: 'for', audio: '', img: ''},
            {key: 'after', translation: 'after', audio: '/assets/audio/mr-wolf/exercises/runs_after.mp3', img: ''},
            {key: 'on', translation: 'on', audio: '', img: ''},
          ],
          answers: ['after'],
          correctAnswer: '',
          audio: '',
        }, {
          text: 'Wolfie tries .... some costumes.',
          image: '',
          options: [
            {key: 'up', translation: 'up', audio: '', img: ''},
            {key: 'down', translation: 'down', audio: '', img: ''},
            {key: 'away', translation: 'away', audio: '', img: ''},
            {key: 'for', translation: 'for', audio: '', img: ''},
            {key: 'after', translation: 'after', audio: '', img: ''},
            {key: 'on', translation: 'on', audio: '/assets/audio/mr-wolf/exercises/tries_on.mp3', img: ''},
          ],
          answers: ['on'],
          correctAnswer: '',
          audio: '',
        }
      ]
    };
    // 9.PAST-PRESENT-FUTURE (6)
    this.test9 = {
      name: '',
      description: '',
      type: 'questions',
      questions: [
        {
          text: 'I’m not a liar.',
          image: '',
          options: [
            {key: 'present', translation: 'present', audio: '', img: ''},
            {key: 'past', translation: 'past', audio: '', img: ''},
            {key: 'future', translation: 'future', audio: '', img: ''},
          ],
          answers: ['present'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/I_am_not_a_liar.mp3',
        },{
          text: 'Everybody was afraid of me.',
          image: '',
          options: [
            {key: 'present', translation: 'present', audio: '', img: ''},
            {key: 'past', translation: 'past', audio: '', img: ''},
            {key: 'future', translation: 'future', audio: '', img: ''},
          ],
          answers: ['past'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/everybody_was_afraid_of_me.mp3',
        }, {
          text: 'The three little pigs are getting ready for their exam.',
          image: '',
          options: [
            {key: 'present', translation: 'present', audio: '', img: ''},
            {key: 'past', translation: 'past', audio: '', img: ''},
            {key: 'future', translation: 'future', audio: '', img: ''},
          ],
          answers: ['present'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/the_three_little_pigs_are_getting_ready_for_their_exam.mp3',
        }, {
          text: 'I’ll find a new job.',
          image: '',
          options: [
            {key: 'present', translation: 'present', audio: '', img: ''},
            {key: 'past', translation: 'past', audio: '', img: ''},
            {key: 'future', translation: 'future', audio: '', img: ''},
          ],
          answers: ['future'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/I_ll_find_a_new_job.mp3',
        }, {
          text: 'I missed you a lot.',
          image: '',
          options: [
            {key: 'present', translation: 'present', audio: '', img: ''},
            {key: 'past', translation: 'past', audio: '', img: ''},
            {key: 'future', translation: 'future', audio: '', img: ''},
          ],
          answers: ['past'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/I_missed_you_a_lot.mp3',
        }, {
          text: 'I will build my house with straw.',
          image: '',
          options: [
            {key: 'present', translation: 'present', audio: '', img: ''},
            {key: 'past', translation: 'past', audio: '', img: ''},
            {key: 'future', translation: 'future', audio: '', img: ''},
          ],
          answers: ['future'],
          correctAnswer: '',
          audio: '/assets/audio/mr-wolf/exercises/I_will_build_my_house_with_straw.mp3',
        }
      ]
    };
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }


  public onTestStateChange($event){
    this.state.isDone = $event;
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  public onTimestampChange($event){
    this.timestampKey = $event;
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  onPlayAttention(tabKey: number): void{
    const attentionList = {
      0 : '/assets/audio/mr-wolf/exercises/adjectives.mp3',
      1 : '/assets/audio/mr-wolf/exercises/weather.mp3',
      3 : '/assets/audio/mr-wolf/exercises/jobs.mp3',
      4 : '/assets/audio/mr-wolf/exercises/house.mp3',
      8 : '/assets/audio/mr-wolf/exercises/health.mp3',
      10 : '/assets/audio/mr-wolf/exercises/phrases.mp3',
      12 : '/assets/audio/mr-wolf/exercises/past_present_future.mp3',
    };
    this.audioService.playAudio(attentionList[tabKey]);
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [0, 1, 3, 4, 8, 10, 12];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.onPlayAttention($event.index); // toto bude citat zadanie
    }
  }

}
