import { Component, OnInit } from '@angular/core';
import { StoryService } from '../../../services/story.service';
import { HrdinoviaService } from '../hrdinovia.service';
import { AudioService } from '../../../services/audio.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-part4',
  templateUrl: './part4.component.html',
  styleUrls: ['./part4.component.css']
})
export class Part4Component implements OnInit {
  public video;
  public state = null;
  public showNextTab = null;
  public timestampKey = null;
  public answer = '';

  constructor(
    private storyService: StoryService,
    private hrdinoviaService: HrdinoviaService,
    private audioService: AudioService,
  ){
    this.state = {
      tabIndex: 0,
      isDone: false
    };
    this.video = {src: 'HRDINOVIA_4.mp4', id: '611052046'};
  }

  ngOnInit(): void{
    this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
  }

  public onVideoPlayed($event){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
  }

  onAnswer(){
    this.storyService.saveAnswer('hrdina', this.answer);
    this.hrdinoviaService.onCorrectAnswer();
    this.next();
  }

  nextTab(){
    this.state.tabIndex++;
    this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    this.showNextTab = null;
  }

  public next(){
    this.storyService.setPartAsDone();
  }

  public onLastVideoPlayed($event){
    this.state.isDone = $event;
    this.next();
  }

  public onTabChange($event: MatTabChangeEvent){
    const whereToPlayPayAttention = [1];
    if (whereToPlayPayAttention.includes($event.index)) {
      this.audioService.playAudio('/assets/audio/hrdinovia/otazka_4.mp3');
    }
  }
}
