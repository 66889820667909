<div *ngIf="true">
  <app-progress [step]="7" [length]="8"></app-progress>
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="state.tabIndex">
    <mat-tab label="TASK">
      <div class="with-background with-background--shelves">
        <div class="text-center">
          <h1>Listen carefully.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="TEXT">
      <div class="text-center">
        <div class="player-wrapper">
          <ngx-circular-player source="/assets/audio/american-dream/AD_audio_ZS/7_text_myths.mp3"></ngx-circular-player>
        </div>
        <h1>ALL AMERICANS ARE FAT AND OTHER MYTHS</h1>
        <p>Not all Americans are fat. Yes, some Americans are morbidly obese, adore fast food, love American football, own guns, and use words like “y’all” and “dude”. But these traits are not true of every American. But many people might think is true of the average American might just be a myth.</p>
        <p>For example, one might assume by watching movies that all Americans live in big houses with well manicured lawns, but, in reality, only the rich own such big houses. Many Americans live in modest homes or apartments, and some, sadly, have no home at all.</p>
        <p>Some people believe all Americans are workaholics, obsessed with their jobs and making money. While a strong work ethic is something in which many Americans take pride, not all Americans see their jobs as the most important part of their lives. For them, work is just something they have to do to pay the bills.</p>
        <p>Americans are sometimes pictured as suffering from “Hero syndrome,” wanting to be the world’s policeman, swooping in during international conflicts to rescue the underdog. But many Americans would rather the US solve its own problems before sticking its nose in other nations’ affairs.</p>
        <p>Americans are often seen as optimists, always reaching for the American Dream. However, many Americans do not see the Dream as a reality. They recognize that most people live with unjust disadvantages—economic, emotional, social—that prevent them from truly reaching their dreams.</p>
        <p>Among all these myths, though, perhaps one is true: the melting pot. The United States is a diverse place, made of people from nearly every culture in the world. Because of this, no two Americans share the exact same experience and tastes. Even things like Christmas traditions might differ from family to family. This diversity gives America its unique character—a melting pot of cultures from around the world simmering into one nation.</p>
        <button class="with-image" (click)="onClickAfterLongText()" ><img src="/assets/img/american-dream/next.png"></button>
      </div>
    </mat-tab>
    <mat-tab label="TASK">
      <div class="with-background with-background--shelves">
        <div class="text-center">
          <h1>Now decide whether the statement is a myth or a fact.</h1>
          <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="QUIZ">
      <app-test [test]="test" [readBefore]="true" [showOptionAs]="'text'" [isDone]="state.isDone" (onDone)="onTestStateChange($event)" *ngIf="state.tabIndex === 3" [playSoundAfterDone]="false"[makeItCount]="true" ></app-test>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="floating-footer" [ngClass]="{'visible': showNextTab}">
  <button class="with-image" (click)="nextTab()" ><img src="/assets/img/american-dream/next.png"></button>
</div>
<div class="floating-footer" [ngClass]="{'visible': state.isDone}">
  <button class="with-image" (click)="next()" ><img src="/assets/img/american-dream/next.png"></button>
</div>


