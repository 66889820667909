import { Component, OnInit } from '@angular/core';
import { Test } from '../../../models/test.model';
import { StoryService } from '../../../services/story.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-part-seven',
    templateUrl: './part-seven.component.html',
    styleUrls: ['./part-seven.component.css']
})
export class PartSevenComponent implements OnInit{
    public state = null;
    public showNextTab = null;
    public test: Test;
    public vocabulary: Test;
    public video;
    public video2;
    public video3;
    public video4;
    public info;
    public info3;
    public info4;
    public timestampKey = null;

    constructor(
        private storyService: StoryService,
        private router: Router
    ){
        this.state = {
            tabIndex: 0,
            isDone: false
        };
        this.info = [
            {translation: 'EGG_ON_TRIP.TRANSLATED.INFO_7_1', audio: ''},
        ];
        this.video = {src: '/assets/video/nove slovicka3.mp4', id: '462662533'};
        this.video2 = {src: '/assets/video/Egg On Trip_V5_7cast.mp4', id: '462795839'};
        this.video3 = {src: '/assets/video/7_cvicenie_13_V1.mp4', id: '462658923'};
        this.video4 = {src: '/assets/video/8_cvicenie_14_V1.mp4', id: '462659049'};
        this.info3 = [
            {
                translation: 'EGG_ON_TRIP.TRANSLATED.INFO_7_3',
                audio: ''
            }
        ];
        this.info4 = [
            {
              translation: 'EGG_ON_TRIP.TRANSLATED.INFO_7_4', audio: ''
            },
        ];
        this.test = {
            name: 'ANAGRAMS',
            description: 'Here’s the first exercise to check you’ve been listening carefully. Don’t worry, I’m sure it’ll be easy for you. Just match the colours to the right words. You’ll need to answer correctly to carry on watching our video.As we play together, you can collect eggs for every correct answer. If you collect enough eggs, at the end of our adventure, you can bake a lovely cake for all my animal friends!',
            type: 'anagram',
            anagrams: [
                {
                    options: [
                        {key: '4', translation: 'P', audio: '', img: ''},
                        {key: '3', translation: 'L', audio: '', img: ''},
                        {key: '1', translation: 'H', audio: '', img: ''},
                        {key: '2', translation: 'E', audio: '', img: ''},
                    ],
                    order: ['1', '2', '3', '4'],
                    audio: '/assets/audio/help.mp3'
                },
                {
                    options: [
                        {key: '3', translation: 'N', audio: '', img: ''},
                        {key: '2', translation: 'I', audio: '', img: ''},
                        {key: '4', translation: 'D', audio: '', img: ''},
                        {key: '1', translation: 'F', audio: '', img: ''},
                    ],
                    order: ['1', '2', '3', '4'],
                    audio: '/assets/audio/find.mp3'
                },
                {
                    options: [
                        {key: '2', translation: 'A', audio: '', img: ''},
                        {key: '4', translation: 'T', audio: '', img: ''},
                        {key: '1', translation: 'W', audio: '', img: ''},
                        {key: '3', translation: 'I', audio: '', img: ''},
                    ],
                    order: ['1', '2', '3', '4'],
                    audio: '/assets/audio/wait.mp3'
                },
                {
                    options: [
                        {key: '3', translation: 'M', audio: '', img: ''},
                        {key: '2', translation: 'U', audio: '', img: ''},
                        {key: '1', translation: 'J', audio: '', img: ''},
                        {key: '4', translation: 'P', audio: '', img: ''},
                    ],
                    order: ['1', '2', '3', '4'],
                    audio: '/assets/audio/jump.mp3'
                },
                {
                    options: [
                        {key: '3', translation: 'D', audio: '', img: ''},
                        {key: '2', translation: 'I', audio: '', img: ''},
                        {key: '4', translation: 'E', audio: '', img: ''},
                        {key: '1', translation: 'H', audio: '', img: ''},
                    ],
                    order: ['1', '2', '3', '4'],
                    audio: '/assets/audio/hide.mp3'
                }
            ]
        };
        this.vocabulary = {
            name: '',
            description: '',
            type: 'questions',
            questions: [
                {
                    text: 'Find the handle',
                    image: '',
                    options: [
                        {key: 'find the handle', translation: 'EGG_ON_TRIP.TRANSLATED.FIND_THE_HANDLE', audio: '/assets/audio/find the handle.mp3', img: ''},
                        {key: 'punch in the stomach', translation: 'EGG_ON_TRIP.TRANSLATED.PUNCH_IN_THE_STOMACH', audio: '/assets/audio/punch in the stomach.mp3', img: ''},
                        {key: 'to eliminate the enemy', translation: 'EGG_ON_TRIP.TRANSLATED.TO_ELIMINATE_THE_ENEMY', audio: '/assets/audio/to eliminate the enemy.mp3', img: ''},
                        {key: 'to drive the thieves out', translation: 'EGG_ON_TRIP.TRANSLATED.TO_DRIVE_THE_THIEVES_OUT', audio: '/assets/audio/to drive the thieves out.mp3', img: ''},
                        {key: 'as fast as their legs could carry them', translation: 'EGG_ON_TRIP.TRANSLATED.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', audio: '/assets/audio/as fast as their legs could carry them.mp3', img: ''},
                    ],
                    answers: ['find the handle'],
                    correctAnswer: '',
                    audio: '',
                }, {
                    text: 'Punch in the stomach',
                    image: '',
                    options: [
                      {key: 'find the handle', translation: 'EGG_ON_TRIP.TRANSLATED.FIND_THE_HANDLE', audio: '/assets/audio/find the handle.mp3', img: ''},
                      {key: 'punch in the stomach', translation: 'EGG_ON_TRIP.TRANSLATED.PUNCH_IN_THE_STOMACH', audio: '/assets/audio/punch in the stomach.mp3', img: ''},
                      {key: 'to eliminate the enemy', translation: 'EGG_ON_TRIP.TRANSLATED.TO_ELIMINATE_THE_ENEMY', audio: '/assets/audio/to eliminate the enemy.mp3', img: ''},
                      {key: 'to drive the thieves out', translation: 'EGG_ON_TRIP.TRANSLATED.TO_DRIVE_THE_THIEVES_OUT', audio: '/assets/audio/to drive the thieves out.mp3', img: ''},
                      {key: 'as fast as their legs could carry them', translation: 'EGG_ON_TRIP.TRANSLATED.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', audio: '/assets/audio/as fast as their legs could carry them.mp3', img: ''},
                    ],
                    answers: ['punch in the stomach'],
                    correctAnswer: '',
                    audio: '',
                }, {
                    text: 'To eliminate the enemy',
                    image: '',
                    options: [
                      {key: 'find the handle', translation: 'EGG_ON_TRIP.TRANSLATED.FIND_THE_HANDLE', audio: '/assets/audio/find the handle.mp3', img: ''},
                      {key: 'punch in the stomach', translation: 'EGG_ON_TRIP.TRANSLATED.PUNCH_IN_THE_STOMACH', audio: '/assets/audio/punch in the stomach.mp3', img: ''},
                      {key: 'to eliminate the enemy', translation: 'EGG_ON_TRIP.TRANSLATED.TO_ELIMINATE_THE_ENEMY', audio: '/assets/audio/to eliminate the enemy.mp3', img: ''},
                      {key: 'to drive the thieves out', translation: 'EGG_ON_TRIP.TRANSLATED.TO_DRIVE_THE_THIEVES_OUT', audio: '/assets/audio/to drive the thieves out.mp3', img: ''},
                      {key: 'as fast as their legs could carry them', translation: 'EGG_ON_TRIP.TRANSLATED.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', audio: '/assets/audio/as fast as their legs could carry them.mp3', img: ''},
                    ],
                    answers: ['to eliminate the enemy'],
                    correctAnswer: '',
                    audio: '',
                }, {
                    text: 'To drive the thieves out',
                    image: '',
                    options: [
                      {key: 'find the handle', translation: 'EGG_ON_TRIP.TRANSLATED.FIND_THE_HANDLE', audio: '/assets/audio/find the handle.mp3', img: ''},
                      {key: 'punch in the stomach', translation: 'EGG_ON_TRIP.TRANSLATED.PUNCH_IN_THE_STOMACH', audio: '/assets/audio/punch in the stomach.mp3', img: ''},
                      {key: 'to eliminate the enemy', translation: 'EGG_ON_TRIP.TRANSLATED.TO_ELIMINATE_THE_ENEMY', audio: '/assets/audio/to eliminate the enemy.mp3', img: ''},
                      {key: 'to drive the thieves out', translation: 'EGG_ON_TRIP.TRANSLATED.TO_DRIVE_THE_THIEVES_OUT', audio: '/assets/audio/to drive the thieves out.mp3', img: ''},
                      {key: 'as fast as their legs could carry them', translation: 'EGG_ON_TRIP.TRANSLATED.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', audio: '/assets/audio/as fast as their legs could carry them.mp3', img: ''},
                    ],
                    answers: ['to drive the thieves out'],
                    correctAnswer: '',
                    audio: '',
                }, {
                    text: 'As fast as their legs could carry them',
                    image: '',
                    options: [
                      {key: 'find the handle', translation: 'EGG_ON_TRIP.TRANSLATED.FIND_THE_HANDLE', audio: '/assets/audio/find the handle.mp3', img: ''},
                      {key: 'punch in the stomach', translation: 'EGG_ON_TRIP.TRANSLATED.PUNCH_IN_THE_STOMACH', audio: '/assets/audio/punch in the stomach.mp3', img: ''},
                      {key: 'to eliminate the enemy', translation: 'EGG_ON_TRIP.TRANSLATED.TO_ELIMINATE_THE_ENEMY', audio: '/assets/audio/to eliminate the enemy.mp3', img: ''},
                      {key: 'to drive the thieves out', translation: 'EGG_ON_TRIP.TRANSLATED.TO_DRIVE_THE_THIEVES_OUT', audio: '/assets/audio/to drive the thieves out.mp3', img: ''},
                      {key: 'as fast as their legs could carry them', translation: 'EGG_ON_TRIP.TRANSLATED.AS_FAST_AS_THEIR_LEGS_COULD_CARRY_THEM', audio: '/assets/audio/as fast as their legs could carry them.mp3', img: ''},
                    ],
                    answers: ['as fast as their legs could carry them'],
                    correctAnswer: '',
                    audio: '',
                },
            ]
        };
    }

    ngOnInit(): void{
        this.storyService.setDefaultPartStateIfNotExists(this.state);
    this.state = this.storyService.getPartState();
    }

    public onTestStateChange($event){
        this.state.isDone = $event;
    }

    public onVideoPlayed($event){
        this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
    }

    public onVocabularyStateChange($event){
        this.showNextTab = true;
    }

    public onTimestampChange($event){
      this.timestampKey = $event;
    }

    nextTab(){
        this.state.tabIndex++;
        this.storyService.setPartState(this.state.tabIndex, 'tabIndex');
        this.showNextTab = null;
    }

    public next(){
        this.storyService.setPartAsDone();
    }

}
